@charset "UTF-8";
@import url("//hello.myfonts.net/count/36a61d");
@font-face {
  font-family: Averta-Bold;
  src: url(../webfonts/36A61D_0_0.eot);
  src: url(../webfonts/36A61D_0_0.eot?#iefix) format("embedded-opentype"), url(../webfonts/36A61D_0_0.woff2) format("woff2"), url(../webfonts/36A61D_0_0.woff) format("woff"), url(../webfonts/36A61D_0_0.ttf) format("truetype"); }

@font-face {
  font-family: Averta-Regular;
  src: url(../webfonts/36A61D_1_0.eot);
  src: url(../webfonts/36A61D_1_0.eot?#iefix) format("embedded-opentype"), url(../webfonts/36A61D_1_0.woff2) format("woff2"), url(../webfonts/36A61D_1_0.woff) format("woff"), url(../webfonts/36A61D_1_0.ttf) format("truetype"); }

html {
  font-size: 106.25%;
  line-height: 1.41176em;
  font-family: Averta-Regular;
  min-height: 100%; }
  html.js input.form-autocomplete {
    background-image: url(../sass/components/autocomplete/throbber.gif?1539002482);
    background-position: 100% 2px;
    background-repeat: no-repeat; }
  html.js input.throbbing {
    background-position: 100% -18px; }
  html.js input.is-throbbing.form-autocomplete {
    background-position: 100% -18px; }
  html.js .js-hidden {
    display: none; }
  html.js .element-hidden {
    display: none; }
  html.js .js-hide {
    display: none; }
  html.js [dir="rtl"] input.form-autocomplete {
    background-position: 0 2px; }
  html.js [dir="rtl"] input.throbbing {
    background-position: 0 -18px; }
  html.js [dir="rtl"] input.is-throbbing.form-autocomplete {
    background-position: 0 -18px; }

@media print {
  html {
    font-size: 12pt; }
  :link {
    text-decoration: underline; }
  :visited {
    text-decoration: underline; }
  .main-navigation {
    display: none; }
  .region-sidebar-first {
    display: none; }
  .region-sidebar-second {
    display: none; }
  .footer {
    display: none; }
  .breadcrumb {
    display: none; }
  .pager {
    display: none; }
  .print-none {
    display: none; }
  .toolbar {
    display: none; }
  .action-links {
    display: none; }
  .links {
    display: none; }
  .book-navigation {
    display: none; }
  .forum-topic-navigation {
    display: none; }
  .feed-icons {
    display: none; }
  .skip-link__wrapper {
    display: none; }
  .tabs {
    display: none; }
  .tabs--secondary {
    display: none; }
  a[href]:after {
    content: "(" attr(href) ")";
    font-weight: normal;
    font-size: 17px;
    text-decoration: none; }
  a[href^='javascript:']:after {
    content: ''; }
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: "(" attr(title) ")"; }
  .sidebar-first .main-content {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0; }
  .sidebar-second .main-content {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0; }
  .two-sidebars .main-content {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0; } }

@media (min-width: 481px) and (max-width: 880px) {
  html {
    font-size: 18px;
    line-height: 32px; }
  .top-banner {
    min-height: 600px;
    background-attachment: scroll; }
  .top-banner__image {
    padding-top: 60px; }
  .top-banner--signature::after {
    font-size: 18px;
    line-height: 26px;
    bottom: 50px;
    width: 500px;
    margin-left: -250px; }
  .top-banner__title {
    font-size: 58px;
    line-height: 48px;
    font-size: 3.41176rem;
    line-height: 2.82353rem; }
  .front-deprecated .top-banner__logo {
    width: 180px;
    height: 196px;
    background-size: contain;
    margin-top: 80px; }
  .front-deprecated .top-banner__title {
    font-size: 21px;
    line-height: 24px;
    font-size: 1.23529rem;
    line-height: 1.41176rem; }
  .front-deprecated .top-banner__subtitle {
    font-size: 21px;
    line-height: 24px;
    font-size: 1.23529rem;
    line-height: 1.41176rem; }
  .top-banner--short {
    min-height: 400px; }
  div.panel-pane div.top-banner {
    padding-top: 70px; }
  .top-banner--front .top-banner__title {
    font-size: 40px;
    line-height: 36px;
    font-size: 2.35294rem;
    line-height: 2.11765rem; }
    .top-banner--front .top-banner__title br {
      display: none; } }

@media (min-width: 0) and (max-width: 480px) {
  html {
    font-size: 17px;
    line-height: 30px; }
  #google-map {
    height: 300px; }
  .top-banner {
    min-height: 450px;
    background-attachment: scroll; }
  .top-banner--signature::after {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    left: 0;
    margin-left: 0;
    padding: 0 40px;
    bottom: 30px; }
  .top-banner__logo {
    width: 95px;
    height: 104px;
    background-size: contain; }
  .top-banner__title {
    padding-top: 70px;
    font-size: 37px;
    line-height: 36px;
    font-size: 2.17647rem;
    line-height: 2.11765rem; }
  .top-banner__subtitle {
    font-size: 21px;
    line-height: 24px;
    font-size: 1.23529rem;
    line-height: 1.41176rem; }
  .top-banner--front .top-banner__title {
    padding-top: 70px;
    font-size: 24px;
    line-height: 34px; }
    .top-banner--front .top-banner__title br {
      display: none; }
  .top-banner--front .top-banner__subtitle {
    padding-top: 8px;
    font-size: 16px;
    line-height: 20px; }
  .front-deprecated .top-banner__logo {
    width: 115px;
    height: 126px;
    background-size: contain;
    margin-top: 35px; }
  .front-deprecated .top-banner__title {
    font-size: 21px;
    line-height: 24px;
    font-size: 1.23529rem;
    line-height: 1.41176rem;
    padding-top: 15px; }
  .front-deprecated .top-banner__subtitle {
    font-size: 21px;
    line-height: 24px;
    font-size: 1.23529rem;
    line-height: 1.41176rem; }
  .front-deprecated div.panel-pane div.top-banner {
    padding-top: 70px; }
  .top-banner--short {
    min-height: 350px; }
  .page-venue .footer-negative-margin {
    margin-top: 34px; }
  div.panel-pane div.top-banner {
    padding-top: 70px; } }

body {
  margin: 0;
  padding: 0;
  background-repeat: repeat;
  color: #1a1a1a; }
  body.drag {
    cursor: move; }

* {
  box-sizing: border-box; }

article {
  display: block; }

aside {
  display: block; }

details {
  display: block; }

figcaption {
  display: block; }

figure {
  display: block;
  margin: 0 0 24px;
  margin: 0 0 1.41176rem; }

footer {
  display: block; }

header {
  display: block; }

hgroup {
  display: block; }

main {
  display: block; }

menu {
  display: block;
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 0 0 0 34px; }

nav {
  display: block; }

section {
  display: block; }

summary {
  display: block; }

audio {
  display: inline-block;
  vertical-align: baseline; }
  audio:not([controls]) {
    display: none;
    height: 0; }

canvas {
  display: inline-block;
  vertical-align: baseline; }

progress {
  display: inline-block;
  vertical-align: baseline; }

video {
  display: inline-block;
  vertical-align: baseline; }

[hidden] {
  display: none; }

template {
  display: none; }

img {
  border: 0;
  max-width: 100%;
  height: auto;
  width: auto; }

svg {
  max-width: 100%;
  height: auto;
  width: auto; }
  svg:not(:root) {
    overflow: hidden; }

:link {
  color: #f15b55;
  text-decoration: none; }

:visited {
  color: #f15b55; }

a:active {
  color: #f15b55;
  background-color: transparent;
  outline: 0; }

a:hover {
  outline: 0; }

.button {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #1a1a1a; }
  .button:hover {
    text-decoration: none;
    color: #1a1a1a; }
  .button:focus {
    text-decoration: none;
    color: #1a1a1a; }
  .button:active {
    text-decoration: none;
    color: #1a1a1a; }

button {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #1a1a1a;
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none; }
  button:hover {
    text-decoration: none;
    color: #1a1a1a; }
  button:focus {
    text-decoration: none;
    color: #1a1a1a; }
  button:active {
    text-decoration: none;
    color: #1a1a1a; }

[type="button"] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #1a1a1a; }
  [type="button"]:hover {
    text-decoration: none;
    color: #1a1a1a; }
  [type="button"]:focus {
    text-decoration: none;
    color: #1a1a1a; }
  [type="button"]:active {
    text-decoration: none;
    color: #1a1a1a; }

[type="reset"] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #1a1a1a; }
  [type="reset"]:hover {
    text-decoration: none;
    color: #1a1a1a; }
  [type="reset"]:focus {
    text-decoration: none;
    color: #1a1a1a; }
  [type="reset"]:active {
    text-decoration: none;
    color: #1a1a1a; }

[type="submit"] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #1a1a1a; }
  [type="submit"]:hover {
    text-decoration: none;
    color: #1a1a1a; }
  [type="submit"]:focus {
    text-decoration: none;
    color: #1a1a1a; }
  [type="submit"]:active {
    text-decoration: none;
    color: #1a1a1a; }

.button[disabled] {
  cursor: default;
  color: #999; }
  .button[disabled]:hover {
    color: #999; }
  .button[disabled]:focus {
    color: #999; }
  .button[disabled]:active {
    color: #999; }

button[disabled] {
  cursor: default;
  color: #999; }
  button[disabled]:hover {
    color: #999; }
  button[disabled]:focus {
    color: #999; }
  button[disabled]:active {
    color: #999; }

[disabled][type="button"] {
  cursor: default;
  color: #999; }
  [disabled][type="button"]:hover {
    color: #999; }
  [disabled][type="button"]:focus {
    color: #999; }
  [disabled][type="button"]:active {
    color: #999; }

[disabled][type="reset"] {
  cursor: default;
  color: #999; }
  [disabled][type="reset"]:hover {
    color: #999; }
  [disabled][type="reset"]:focus {
    color: #999; }
  [disabled][type="reset"]:active {
    color: #999; }

[disabled][type="submit"] {
  cursor: default;
  color: #999; }
  [disabled][type="submit"]:hover {
    color: #999; }
  [disabled][type="submit"]:focus {
    color: #999; }
  [disabled][type="submit"]:active {
    color: #999; }

input {
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0; }

optgroup {
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
  font-weight: bold; }

select {
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
  text-transform: none; }

textarea {
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
  line-height: inherit;
  overflow: auto; }

button::-moz-focus-inner {
  border: 0;
  padding: 0; }

input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button {
  height: auto; }

input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  line-height: inherit; }

input[type="text"] {
  line-height: inherit; }

input[type="tel"] {
  line-height: inherit; }

input[type="url"] {
  line-height: inherit; }

input[type="email"] {
  line-height: inherit; }

input[type="password"] {
  line-height: inherit; }

input[type="date"] {
  line-height: inherit; }

input[type="time"] {
  line-height: inherit; }

input[type="number"] {
  line-height: inherit; }

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

fieldset {
  padding: 8.4px 15px 15.6px;
  padding: 0.49412rem 0.88235rem 0.91765rem;
  border: 1px solid #ccc;
  margin: 0 2px; }

legend {
  border: 0;
  margin-left: -5px;
  padding: 0 5px; }

label {
  display: block;
  font-weight: bold; }
  label.option {
    display: inline;
    font-weight: normal; }

.divider {
  margin: 24px 0;
  margin: 1.41176rem 0;
  border: 0;
  border-top: 1px solid #ccc; }
  .divider > :first-child {
    margin-top: 24px;
    margin-top: 1.41176rem; }

hr {
  margin: 24px 0;
  margin: 1.41176rem 0;
  border: 0;
  border-top: 1px solid #ccc;
  box-sizing: content-box;
  height: 0; }
  hr > :first-child {
    margin-top: 24px;
    margin-top: 1.41176rem; }

blockquote {
  margin: 0 34px 24px;
  margin: 0 2rem 1.41176rem; }

p {
  margin: 24px 0;
  margin: 1.41176rem 0; }

pre {
  margin: 24px 0;
  margin: 1.41176rem 0;
  font-family: Menlo, DejaVu Sans Mono, Ubuntu Mono, Courier, Courier New, monospace, sans-serif;
  font-size: 1em;
  overflow: auto; }

code {
  font-family: Menlo, DejaVu Sans Mono, Ubuntu Mono, Courier, Courier New, monospace, sans-serif;
  font-size: 1em; }

kbd {
  font-family: Menlo, DejaVu Sans Mono, Ubuntu Mono, Courier, Courier New, monospace, sans-serif;
  font-size: 1em; }

samp {
  font-family: Menlo, DejaVu Sans Mono, Ubuntu Mono, Courier, Courier New, monospace, sans-serif;
  font-size: 1em; }

var {
  font-family: Menlo, DejaVu Sans Mono, Ubuntu Mono, Courier, Courier New, monospace, sans-serif;
  font-size: 1em; }

dl {
  margin: 24px 0;
  margin: 1.41176rem 0; }

ol {
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 0 0 0 34px; }
  ol ol {
    margin: 0; }
  ol ul {
    margin: 0; }

ul {
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 0 0 0 34px; }
  ul ol {
    margin: 0; }
  ul ul {
    margin: 0; }

dd {
  margin: 0 0 0 34px; }

[dir="rtl"] dd {
  margin: 0 34px 0 0; }

[dir="rtl"] menu {
  padding: 0 34px 0 0; }

[dir="rtl"] ol {
  padding: 0 34px 0 0; }

[dir="rtl"] ul {
  padding: 0 34px 0 0; }

[dir="rtl"] .autocomplete {
  background-position: 0 2px; }
  [dir="rtl"] .autocomplete.is-throbbing {
    background-position: 0 -18px; }

[dir="rtl"] .autocomplete-is-throbbing {
  background-position: 0 -18px; }

[dir="rtl"] .collapsible-fieldset__legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: 98% 75%; }

[dir="rtl"] .collapsible .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: 98% 75%; }

[dir="rtl"] .collapsible.collapsed .fieldset-legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
  background-position: 98% 50%; }

[dir="rtl"] .is-collapsed .collapsible-fieldset__legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
  background-position: 98% 50%; }

[dir="rtl"] .is-collapsed.menu__item {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII="); }

[dir="rtl"] .is-collapsed .collapsible .fieldset-legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
  background-position: 98% 50%; }

[dir="rtl"] .collapsible-fieldset-is-collapsed__legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
  background-position: 98% 50%; }

[dir="rtl"] .comment--nested {
  margin-left: 0;
  margin-right: 34px; }

[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 34px; }

[dir="rtl"] .form-table__header {
  text-align: right;
  padding-left: 1em;
  padding-right: 0; }

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px; }

[dir="rtl"] .header__secondary-menu {
  float: left; }

[dir="rtl"] .inline-links__item {
  display: inline-block;
  padding: 0 0 0 1em; }

[dir="rtl"] .inline-sibling {
  margin-right: 0;
  margin-left: 10px; }

[dir="rtl"] .field__label-inline {
  margin-right: 0;
  margin-left: 10px; }

[dir="rtl"] .messages {
  padding: 10px 50px 10px 10px;
  background-position: 99% 8px;
  margin-bottom: 20px; }
  [dir="rtl"] .messages.warning {
    padding: 10px 50px 10px 10px;
    background-position: 99% 8px; }
  [dir="rtl"] .messages.error {
    padding: 10px 50px 10px 10px;
    background-position: 99% 8px; }

[dir="rtl"] .messages--warning {
  padding: 10px 50px 10px 10px;
  background-position: 99% 8px; }

[dir="rtl"] .messages--status {
  padding: 10px 50px 10px 10px;
  background-position: 99% 8px; }

[dir="rtl"] .messages--error {
  padding: 10px 50px 10px 10px;
  background-position: 99% 8px; }

[dir="rtl"] .more-link {
  text-align: left; }

[dir="rtl"] .more-help-link {
  text-align: left; }
  [dir="rtl"] .more-help-link a {
    padding: 1px 20px 1px 0;
    background-position: 100% 50%; }

[dir="rtl"] .more-link__help-icon {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%; }

[dir="rtl"] .nav-menu__item-is-collapsed {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII="); }

[dir="rtl"] .progress-bar__percentage {
  float: left; }

[dir="rtl"] .progress-bar--inline {
  float: right; }

[dir="rtl"] .ajax-progress-bar {
  float: right; }

[dir="rtl"] .progress-throbber {
  float: right; }

[dir="rtl"] .ajax-progress {
  float: right; }
  [dir="rtl"] .ajax-progress .throbber {
    float: right; }

[dir="rtl"] .progress-throbber__widget {
  float: right; }

[dir="rtl"] .table-drag__handle {
  float: right;
  margin-right: -1em;
  margin-left: 0; }

[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0; }

[dir="rtl"] .table-drag__toggle-weight-wrapper {
  text-align: left; }

[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left; }

[dir="rtl"] .table-drag__indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0; }

[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0; }

[dir="rtl"] .table-drag__tree-child {
  background-position: -65px center; }

[dir="rtl"] .table-drag__tree-child-last {
  background-position: -65px center; }

[dir="rtl"] .tree-child-last {
  background-position: -65px center; }

[dir="rtl"] .table-drag__tree-child-horizontal {
  background-position: -65px center; }

[dir="rtl"] .tree-child-horizontal {
  background-position: -65px center; }

[dir="rtl"] .tree-child {
  background-position: -65px center; }

[dir="rtl"] .tabs__tab {
  float: right; }

[dir="rtl"] .tabs--secondary__tab {
  float: right; }

[dir="rtl"] html.js input.form-autocomplete {
  background-position: 0 2px; }

[dir="rtl"] html.js input.throbbing {
  background-position: 0 -18px; }

[dir="rtl"] html.js input.is-throbbing.form-autocomplete {
  background-position: 0 -18px; }

[dir="rtl"] .form-table th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0; }

[dir="rtl"] form table th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0; }

[dir="rtl"] .inline.links li {
  display: inline-block;
  padding: 0 0 0 1em; }

[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px; }

[dir="rtl"] .nav-menu__item.is-collapsed {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII="); }

[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII="); }

[dir="rtl"] .progress .percentage {
  float: left; }

h1 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal; }

.header__site-name {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal;
  margin: 0; }

h2 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal; }
  h2.sidr-class-visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px); }

h3 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal; }

h4 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal; }

h5 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal; }

h6 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal; }

table {
  margin: 24px 0;
  margin: 1.41176rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; }
  table .form-item {
    margin: 0; }

td {
  padding: 0; }
  td .checkbox {
    text-align: center; }
  td.active {
    background-color: #ddd; }
  td.menu-disabled {
    background: #ccc; }

th {
  padding: 0; }
  th .checkbox {
    text-align: center; }

abbr[title] {
  border-bottom: 1px dotted; }

b {
  font-weight: bold; }

strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #1a1a1a; }

small {
  font-size: 16px;
  font-size: 0.94118rem; }

sub {
  font-size: 13px;
  font-size: 0.76471rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  bottom: -.25em; }

sup {
  font-size: 13px;
  font-size: 0.76471rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -.5em; }

.node-download-item {
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 24px;
  margin-top: 1.41176rem;
  margin-bottom: 0;
  margin-bottom: 0; }
  .node-download-item .field-name-field-file a {
    transition: all 0.4s ease-out;
    min-width: 135px;
    min-height: 45px;
    display: inline-block;
    margin-top: 23px;
    margin-right: 16px;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    font-family: Averta-Regular;
    letter-spacing: 0.25px;
    font-size: 18px;
    line-height: 23px;
    color: #1a1a1a;
    margin-right: 0;
    background-color: #4d4d4d;
    color: #fff;
    background-image: url(../images/dl.png);
    background-position: 15px 14px;
    background-repeat: no-repeat;
    padding-left: 40px; }
    .node-download-item .field-name-field-file a:hover {
      color: #f15b55;
      border-color: #f15b55;
      color: #fff;
      background-color: #f15b55; }
    .node-download-item .field-name-field-file a:focus {
      color: #fff;
      background-color: #f15b55; }
  .node-download-item .field-name-field-file img {
    display: none; }

.messages--status {
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 10px 10px 10px 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid #be7;
  background-color: #f8fff0;
  color: #234600;
  background-color: #fef5f1;
  color: #8c2e0b;
  border-color: #ed541d;
  display: block;
  max-width: 920px;
  margin: 0 auto;
  background: #fef5f1;
  color: #234600;
  text-align: left;
  border-color: #234600; }

.contained {
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  padding-left: 15px;
  padding-right: 15px; }

.contained--no-gutter {
  padding-left: 0;
  padding-right: 0; }

.page {
  margin-left: auto;
  margin-right: auto; }

.region-bottom {
  margin-left: auto;
  margin-right: auto; }

@media all and (min-width: 0px) {
  .main {
    position: relative; }
  .main-navigation {
    position: absolute;
    top: 0;
    height: 34px;
    width: 100%;
    z-index: 10; } }

@media all and (min-width: 880px) {
  .main-navigation {
    position: absolute;
    top: 0;
    height: 194px;
    width: 100%;
    z-index: 10; } }

@media all and (min-width: 480px) and (max-width: 959px) {
  .sidebar-first .main-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%; }
  .sidebar-first .region-sidebar-first {
    float: left;
    width: 33.33333%;
    margin-left: 0;
    margin-right: -100%; }
  .sidebar-second .main-content {
    float: left;
    width: 66.66667%;
    margin-left: 0;
    margin-right: -100%; }
  .sidebar-second .region-sidebar-second {
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%; }
  .two-sidebars .main-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%; }
  .two-sidebars .region-sidebar-first {
    float: left;
    width: 33.33333%;
    margin-left: 0;
    margin-right: -100%; }
  .two-sidebars .region-sidebar-second {
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%;
    padding-left: 0;
    padding-right: 0;
    clear: left; }
    .two-sidebars .region-sidebar-second:before {
      content: "";
      display: table; }
    .two-sidebars .region-sidebar-second:after {
      content: "";
      display: table;
      clear: both; }
    .two-sidebars .region-sidebar-second .block {
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box; }
      .two-sidebars .region-sidebar-second .block:nth-child(3n + 1) {
        float: left;
        width: 33.33333%;
        margin-left: 0;
        margin-right: -100%;
        clear: left; }
      .two-sidebars .region-sidebar-second .block:nth-child(3n + 2) {
        float: left;
        width: 33.33333%;
        margin-left: 33.33333%;
        margin-right: -100%; }
      .two-sidebars .region-sidebar-second .block:nth-child(3n) {
        float: left;
        width: 33.33333%;
        margin-left: 66.66667%;
        margin-right: -100%; } }

@media all and (min-width: 960px) {
  .sidebar-first .main-content {
    float: left;
    width: 80%;
    margin-left: 20%;
    margin-right: -100%; }
  .sidebar-first .region-sidebar-first {
    float: left;
    width: 20%;
    margin-left: 0;
    margin-right: -100%; }
  .sidebar-second .main-content {
    float: left;
    width: 80%;
    margin-left: 0;
    margin-right: -100%; }
  .sidebar-second .region-sidebar-second {
    float: left;
    width: 20%;
    margin-left: 80%;
    margin-right: -100%; }
  .two-sidebars .main-content {
    float: left;
    width: 60%;
    margin-left: 20%;
    margin-right: -100%; }
  .two-sidebars .region-sidebar-first {
    float: left;
    width: 20%;
    margin-left: 0;
    margin-right: -100%; }
  .two-sidebars .region-sidebar-second {
    float: left;
    width: 20%;
    margin-left: 80%;
    margin-right: -100%; } }

.l-two-column-thirds {
  display: flex; }

.l-two-column-thirds__left {
  display: flex;
  width: 41.66667%; }

@media (min-width: 0px) and (max-width: 780px) {
  .l-two-column-thirds__left {
    float: none;
    margin: 0;
    width: 100%; }
  .l-two-column-thirds__right {
    float: none;
    margin: 0;
    width: 100%; }
  .panel-2col-stacked .panel-col-last {
    float: none;
    width: 100%; }
    .panel-2col-stacked .panel-col-last .inside {
      margin: 0; }
    .panel-2col-stacked .panel-col-last .link-tile {
      margin-top: 20px; }
  .panel-2col-stacked .panel-col-first {
    float: none;
    width: 100%; }
    .panel-2col-stacked .panel-col-first .inside {
      margin: 0; }
    .panel-2col-stacked .panel-col-first .link-tile {
      margin-top: 20px; } }

.l-two-column-thirds__right {
  width: 58.33333%; }

.l-four-column-asymetric:before {
  content: "";
  display: table; }

.l-four-column-asymetric:after {
  content: "";
  display: table;
  clear: both; }

.l-four-column-asymetric__column {
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box; }

.l-four-column-asymetric__column--first {
  float: left;
  width: 100%;
  text-align: center;
  margin-left: 0;
  margin-right: -100%; }

.l-four-column-asymetric__column--second {
  float: left;
  width: 25%;
  margin-left: 25%;
  margin-right: -100%; }

.l-four-column-asymetric__column--third {
  float: left;
  width: 33.33333%;
  margin-left: 50%;
  margin-right: -100%; }

.l-four-column-asymetric__column--fourth {
  float: left;
  width: 16.66667%;
  margin-left: 83.33333%;
  margin-right: -100%; }

@media (min-width: 501px) and (max-width: 840px) {
  .l-four-column-asymetric__column--first {
    width: 100%;
    float: none;
    margin: 0; }
  .l-four-column-asymetric__column--second {
    float: left;
    width: 50%;
    margin-left: 0;
    margin-right: -100%; }
  .l-four-column-asymetric__column--third {
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%; }
  .l-four-column-asymetric__column--fourth {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%;
    margin-top: -18px; } }

@media (min-width: 0) and (max-width: 500px) {
  .l-four-column-asymetric__column--fourth {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%; }
  .l-four-column-asymetric__column--third {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%; }
  .l-four-column-asymetric__column--second {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%; }
  .l-four-column-asymetric__column--first {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%; }
  .l-four-column-asymetric--bottom {
    padding-bottom: 20px;
    padding-top: 20px; }
    .l-four-column-asymetric--bottom .l-four-column-asymetric__column--first .l-four-column-asymetric__column--fourth {
      display: none; }
  .callout {
    top: auto;
    bottom: 20px;
    width: 100px;
    height: auto; }
  .footer-item {
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 0; }
  .footer-item_small {
    margin-bottom: 0;
    text-align: center;
    line-height: 15px; } }

@media (min-width: 500px) and (max-width: 780px) {
  .l-four-column-asymetric--bottom .l-four-column-asymetric__column--third {
    clear: none; }
  .l-four-column-asymetric--bottom .l-four-column-asymetric__column--first .l-four-column-asymetric__column--fourth {
    display: none; }
  .l-three-column--column {
    width: 50%; }
  .l-three-column__column {
    width: 50%; } }

.l-three-column--column {
  padding: 17px 15px;
  float: left;
  width: 33.3%; }

.l-three-column__column {
  padding: 17px 15px;
  float: left;
  width: 33.3%; }

@media (min-width: 0px) and (max-width: 500px) {
  .l-three-column--column {
    width: 100%; }
  .l-three-column__column {
    width: 100%; } }

.l-four-column--column {
  padding: 17px 15px;
  float: left;
  width: 25%; }

.l-four-column__column {
  padding: 17px 15px;
  float: left;
  width: 25%; }

@media (min-width: 0) and (max-width: 600px) {
  .l-four-column--column {
    width: 50%; }
  .l-four-column__column {
    width: 50%; } }

.l-four-column--no-padding .l-four-column--column {
  padding: 0; }

.l-four-column--no-padding .l-four-column__column {
  padding: 0; }

.l-two-column--column {
  padding: 17px 15px;
  float: left;
  width: 50%; }

.l-two-column__column {
  padding: 17px 15px;
  float: left;
  width: 50%; }

@media (min-width: 0) and (max-width: 580px) {
  .l-two-column--column {
    width: 100%; }
  .l-two-column__column {
    width: 100%; } }

.l-four-column-centered {
  text-align: center; }

.l-four-column-centered--column {
  margin: 5px 8px;
  display: inline-block; }

.l-four-column-centered__column {
  margin: 5px 8px;
  display: inline-block; }

.l-four-column-centered--no-padding .l-four-column-centered--column {
  padding: 0; }

.l-four-column-centered--no-padding .l-four-column-centered__column {
  padding: 0; }

div#admin-toolbar.horizontal div.admin-tabs {
  height: 35px; }

div#admin-toolbar div.admin-tab {
  height: 30px; }

div.krumo-expand {
  text-align: left; }

div.panel-pane div.footer-padding {
  padding-bottom: 144px;
  padding-bottom: 8.47059rem; }

div.panel-pane div.top-banner {
  padding-top: 194px; }

div.pane-node div.node-photo-gallery {
  margin-top: 96px;
  margin-top: 5.64706rem;
  margin-bottom: 96px;
  margin-bottom: 5.64706rem; }

.agenda {
  padding-top: 48px;
  padding-top: 2.82353rem;
  padding-bottom: 24px;
  padding-bottom: 1.41176rem; }
  .agenda .view-header {
    padding-bottom: 24px;
    padding-bottom: 1.41176rem; }

.agenda__entry {
  border-top: 1px solid #ccc;
  transition: all 0.4s ease-out;
  line-height: 25px;
  display: flex;
  margin-top: 10px; }
  .agenda__entry:not(.agenda__entry.agenda__entry--no-time) {
    background-color: #e6e6e6;
    font-family: Averta-Regular;
    font-size: 17px;
    line-height: 24px;
    color: #fff; }
  .agenda__entry:not(.agenda__entry.is-expandable) .agenda__title {
    background-color: #83c994; }
  .agenda__entry.agenda__entry--no-time {
    float: right;
    width: 80%; }
    .agenda__entry.agenda__entry--no-time .agenda__time {
      display: none; }

@media (min-width: 550px) {
  .agenda__entry {
    margin-top: 0; } }

.agenda__header {
  display: flex; }

@media (min-width: 0) and (max-width: 550px) {
  .agenda__header {
    display: inline-block;
    width: 100%; }
  .agenda__time {
    float: none;
    width: 100%;
    min-height: 0;
    paddng: 10px; }
  .agenda__text {
    float: none;
    width: 100%;
    min-height: 0;
    margin-left: 0;
    padding-left: 15px; }
  .agenda__venue {
    float: none;
    width: 100%;
    padding-left: 30px;
    background-position: 15px 50%;
    padding-right: 15px; }
  .agenda__title {
    float: none;
    width: 100%;
    padding: 15px;
    margin-top: 0; }
  .agenda__topic {
    float: none;
    width: 100%;
    padding-left: 15px;
    padding-top: 15px; }
  .agenda__col-right {
    float: none;
    width: 100%; }
  .agenda__toggle {
    top: 0; }
  .agenda__entry.agenda__entry--no-time {
    width: 100%; }
  .agenda__entry:not(.agenda__entry--no-time) .agenda__time + .agenda__venue {
    padding-top: 0; } }

.agenda__day {
  padding-top: 36px;
  padding-top: 2.11765rem; }
  .agenda__day.agenda__day--tiny .agenda__entry {
    width: 100%;
    border: 0; }
  .agenda__day.agenda__day--tiny .agenda__venue {
    display: none; }

.field-name-field-start-time {
  margin: auto auto; }
  .field-name-field-start-time .field-items .field-item {
    margin: 0; }

.agenda__time {
  float: left;
  width: 15%;
  min-height: 86px;
  font-family: Averta-Regular;
  font-size: 17px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  padding: 10px;
  background-color: #526479;
  display: flex; }

@media (min-width: 0) and (max-width: 850px) {
  .agenda__time {
    paddng: 15px; }
  .sponsors__item {
    height: 180px;
    width: 180px; }
    .sponsors__item img {
      top: 90px; } }

@media (min-width: 0) and (max-width: 650px) {
  .agenda__time {
    paddng: 10px; }
  .form .form-item {
    width: 100%;
    float: none; } }

.agenda__text {
  padding-left: 27px;
  clear: both;
  color: #1a1a1a;
  padding-right: 15px;
  font-size: 17px;
  background-color: colro(blue-gray-light_text_back); }
  .agenda__text p {
    margin-top: 0; }

.agenda__venue {
  padding-left: 27px;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 16px;
  line-height: 34px;
  color: #f15b55;
  margin-bottom: 10px;
  background-image: url(../images/venue-marker.png);
  background-position: 0 50%;
  background-repeat: no-repeat;
  padding-left: 15px; }

.agenda__title {
  padding-left: 27px;
  position: relative;
  font-family: Averta-Regular;
  font-size: 17px;
  line-height: 24px;
  background-color: #f15b55;
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 15px; }

.agenda__topic {
  padding-left: 27px;
  padding-top: 6px;
  color: #808080;
  font-size: 14px;
  line-height: 34px;
  min-height: 34px;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-weight: bold;
  text-transform: uppercase; }

.agenda__col-right {
  padding-left: 27px; }

@media (min-width: 0) and (max-width: 950px) {
  .agenda__title {
    padding: 15px; }
  .agenda__topic.agenda__venue--empty {
    display: none; }
  .agenda__entry.agenda__entry--no-time .agenda__text {
    margin-left: 27px; }
  .agenda__entry.agenda__entry--no-time .agenda__title {
    margin-left: 27px; } }

.agenda__title-inner {
  margin: auto 0;
  margin-left: 26px;
  flex-grow: 2; }

.agenda__toggle {
  height: 28px;
  width: 28px;
  cursor: pointer;
  font-size: 36px;
  line-height: 28px;
  border: 1px solid #fff;
  text-align: center;
  line-height: 18px;
  margin: auto 0;
  margin-right: 20px;
  min-width: 28px; }
  .agenda__toggle:hover {
    border: 1px solid #fff; }

.agenda__toggle-inner {
  width: 28px;
  height: 28px; }

.agenda__text-inner {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Averta-Regular;
  font-size: 17px;
  line-height: 24px; }

@media (min-width: 951px) {
  .agenda__entry.agenda__entry--no-time .agenda__venue {
    width: 17.24138%; }
  .agenda__entry.agenda__entry--no-time .agenda__title {
    width: 82.75862%; }
  .agenda__entry.agenda__entry--no-time .agenda__text {
    margin-left: 17.24138%;
    width: 82.75862%; } }

.field-collection-container {
  margin-top: 22px;
  border-bottom: 1px solid #ccc; }

.field-collection-view {
  padding: 0;
  margin: 0;
  border-bottom: 0; }

.image-overlayed {
  margin-left: auto;
  margin-right: auto; }

.image-overlayed__image {
  line-height: 0; }
  .image-overlayed__image img {
    border: 7px solid #f2f2f2;
    position: relative;
    top: 0;
    transform: translateY(-70%);
    border-radius: 100%; }

.article__title {
  font-family: Averta-Bold;
  font-size: 24px;
  line-height: 51px;
  color: #526479;
  margin-top: -150px;
  z-index: 1;
  position: relative;
  max-width: 308px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 72px;
  margin-bottom: 4.23529rem;
  margin-top: 0;
  font-family: Averta-Bold; }
  .article__title:before {
    bottom: -40px; }

.image-overlayed__title {
  font-family: Averta-Bold;
  font-size: 24px;
  line-height: 51px;
  color: #526479;
  margin-top: -150px;
  z-index: 1;
  position: relative;
  max-width: 308px;
  margin-left: auto;
  margin-right: auto; }

.image-overlayed__subtitle {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 15px;
  line-height: 19px;
  color: #58595b;
  margin-top: 12px;
  color: #f15b55;
  font-family: Averta-Regular;
  max-width: 308px;
  margin-left: auto;
  margin-right: auto; }

.image-overlayed__bottom_line {
  position: relative; }

.image-overlayed__text {
  margin-top: 52px;
  text-align: left;
  font-size: 17px;
  line-height: 24px;
  font-family: Averta-Regular; }
  .image-overlayed__text p:last-child {
    margin-bottom: 0; }

.section-register .link-button {
  transition: all 0.4s ease-out;
  min-width: 135px;
  min-height: 45px;
  display: inline-block;
  margin-top: 23px;
  margin-right: 16px;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  font-family: Averta-Regular;
  letter-spacing: 0.25px;
  font-size: 18px;
  line-height: 23px;
  color: #1a1a1a; }
  .section-register .link-button:hover {
    color: #f15b55;
    border-color: #f15b55; }

.section-register .link-button--square {
  border-radius: 0;
  background-color: #db3b30;
  font-size: 21px;
  line-height: 26px;
  border: 0;
  min-width: 280px; }
  .section-register .link-button--square:hover {
    background-color: #f3c969;
    color: #1a1a1a; }

.article__dl {
  text-align: left; }
  .article__dl a {
    transition: all 0.4s ease-out;
    min-width: 135px;
    min-height: 45px;
    display: inline-block;
    margin-top: 23px;
    margin-right: 16px;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    font-family: Averta-Regular;
    letter-spacing: 0.25px;
    font-size: 18px;
    line-height: 23px;
    color: #1a1a1a;
    margin-right: 0;
    background-color: #4d4d4d;
    color: #fff;
    background-image: url(../images/dl.png);
    background-position: 15px 14px;
    background-repeat: no-repeat;
    padding-left: 40px; }
    .article__dl a:hover {
      color: #f15b55;
      border-color: #f15b55;
      color: #fff;
      background-color: #f15b55; }
    .article__dl a:focus {
      color: #fff;
      background-color: #f15b55; }
  .article__dl img {
    display: none; }

.link-button {
  transition: all 0.4s ease-out;
  min-width: 135px;
  min-height: 45px;
  display: inline-block;
  margin-top: 23px;
  margin-right: 16px;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  font-family: Averta-Regular;
  letter-spacing: 0.25px;
  font-size: 18px;
  line-height: 23px;
  color: #1a1a1a; }
  .link-button:hover {
    color: #f15b55;
    border-color: #f15b55; }

.link-button__extra-text {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 15px;
  line-height: 24px;
  display: block; }

.link-button--right {
  float: right;
  margin-right: 0; }

.link-button--dl {
  margin-right: 0;
  background-color: #4d4d4d;
  color: #fff; }
  .link-button--dl:focus {
    color: #fff;
    background-color: #f15b55; }
  .link-button--dl:hover {
    color: #fff;
    background-color: #f15b55; }

.link-button--dl-icon {
  background-image: url(../images/dl.png);
  background-position: 15px 14px;
  background-repeat: no-repeat;
  padding-left: 40px; }

.link-button--square {
  border-radius: 0;
  background-color: #db3b30;
  font-size: 21px;
  line-height: 26px;
  border: 0;
  min-width: 280px; }
  .link-button--square:hover {
    background-color: #f3c969;
    color: #1a1a1a; }

.article {
  max-width: 727px;
  text-align: center;
  margin-left: auto;
  margin-right: auto; }
  .article .entity_pager_prev {
    float: left;
    min-width: 140px; }
  .article .entity_pager_next {
    float: right;
    min-width: 140px;
    text-align: right; }
  .article .entity_pager {
    padding-top: 24px;
    padding-top: 1.41176rem;
    border-top: 1px solid #b3b3b3; }
    .article .entity_pager li {
      margin: 0;
      font-size: 16px;
      line-height: 24px; }
    .article .entity_pager a {
      font-family: Averta-Regular;
      font-size: 16px;
      line-height: 24px;
      margin-top: 25px;
      margin-bottom: 25px;
      color: #58595b;
      letter-spacing: .25px; }
      .article .entity_pager a:hover {
        color: #f15b55; }
    .article .entity_pager .inactive {
      visibility: hidden; }
  .article.node.node-press-article {
    margin-left: auto;
    margin-right: auto; }

.article__date {
  margin-top: 72px;
  margin-top: 4.23529rem;
  margin-bottom: 24px;
  margin-bottom: 1.41176rem;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 17px;
  line-height: 25px;
  color: #58595b; }

.article__body {
  text-align: left;
  font-family: Helvetica, Arial, Nimbus Sans L, sans-serif !important; }

#block-views-entity-pager-article-block {
  padding-top: 48px;
  padding-top: 2.82353rem;
  padding-bottom: 144px;
  padding-bottom: 8.47059rem;
  background-color: #f2f2f2; }

.view-articles {
  padding-bottom: 144px;
  padding-bottom: 8.47059rem; }

.article-teaser {
  margin-top: 48px;
  margin-top: 2.82353rem;
  margin-bottom: 48px;
  margin-bottom: 2.82353rem;
  padding-bottom: 24px;
  padding-bottom: 1.41176rem;
  position: relative;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto; }
  .article-teaser.node.node-press-article {
    margin-top: 48px;
    margin-top: 2.82353rem;
    margin-bottom: 48px;
    margin-bottom: 2.82353rem;
    padding-bottom: 24px;
    padding-bottom: 1.41176rem;
    margin-left: auto;
    margin-right: auto; }
  .article-teaser:hover .article-teaser__image:before {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8; }
  .article-teaser:hover .article-teaser__image:after {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    font-size: 18px; }

.article-teaser__date {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 17px;
  line-height: 25px;
  color: #58595b;
  width: 95px;
  background: #fff;
  margin-top: -55px;
  padding: 15px 0;
  position: relative;
  z-index: 1; }

.article-teaser__title {
  margin-top: 18px;
  margin-top: 1.05882rem;
  font-family: Helvetica, Arial, Nimbus Sans L, sans-serif !important;
  font-size: 24px;
  line-height: 24px;
  font-size: 1.41176rem;
  line-height: 1.41176rem;
  font-family: Averta-Bold;
  color: #526479;
  margin-top: 24px; }

.article-teaser__body {
  font-family: Helvetica, Arial, Nimbus Sans L, sans-serif !important;
  font-size: 15px;
  color: #f15b55;
  font-family: Averta-Regular;
  margin-top: 11px; }
  .article-teaser__body p {
    margin-top: 1em; }

.article-teaser__read-more {
  font-family: Helvetica, Arial, Nimbus Sans L, sans-serif !important;
  font-size: 17px;
  line-height: 25px;
  color: #231f20;
  position: absolute;
  bottom: 0; }

.autocomplete {
  background-image: url(../sass/components/autocomplete/throbber.gif?1539002482);
  background-position: 100% 2px;
  background-repeat: no-repeat; }
  .autocomplete.is-throbbing {
    background-position: 100% -18px; }

.autocomplete-is-throbbing {
  background-position: 100% -18px; }

.autocomplete__list-wrapper {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100; }

#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100; }
  #autocomplete ul {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0; }
  #autocomplete li {
    background: #fff;
    color: #1a1a1a;
    cursor: default;
    white-space: pre; }
  #autocomplete .selected {
    background: #0072b9;
    color: #fff; }

.autocomplete__list {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

.autocomplete__list-item {
  background: #fff;
  color: #1a1a1a;
  cursor: default;
  white-space: pre; }

.autocomplete__list-item-is-selected {
  background: #0072b9;
  color: #fff; }

.box {
  margin-bottom: 24px;
  margin-bottom: 1.41176rem;
  border: 5px solid #ccc;
  padding: 1em; }
  .box:focus {
    border-color: #1a1a1a; }
  .box:hover {
    border-color: #1a1a1a; }
  .box.is-focus {
    border-color: #1a1a1a; }

.box-is-focus {
  border-color: #1a1a1a; }

.box__title {
  margin: 0; }

.box--highlight {
  border-color: #f15b55; }

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.visually-hidden--focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }
  .visually-hidden--focusable:active {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto; }
  .visually-hidden--focusable:focus {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto; }

.element-focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }
  .element-focusable:active {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto; }
  .element-focusable:focus {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto; }

.element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.breadcrumb__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.main-navigation .links {
  padding: 85px 0;
  text-align: right;
  margin-top: 0;
  margin-bottom: 0; }
  .main-navigation .links li {
    display: inline-block;
    padding: 0 15px 0 0;
    list-style-type: none;
    list-style-image: none;
    font-family: Averta-Bold;
    font-size: 14px;
    line-height: 24px;
    font-size: 0.82353rem;
    line-height: 1.41176rem; }
    .main-navigation .links li:last-child {
      padding-right: 0; }
    .main-navigation .links li a {
      color: #fff;
      padding-bottom: 3px;
      border-bottom: 1px solid transparent;
      transition: all 0.4s ease-out; }
      .main-navigation .links li a.active-trail {
        border-bottom: 1px solid #fff; }
      .main-navigation .links li a.active {
        border-bottom: 1px solid #fff; }
      .main-navigation .links li a:focus {
        border-bottom: 1px solid #fff; }
      .main-navigation .links li a:hover {
        border-bottom: 1px solid #fff; }

.main-navigation .menu {
  padding: 85px 0;
  padding: 100px 0;
  padding-left: 285px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0; }
  .main-navigation .menu li {
    display: inline-block;
    padding: 0 25px 0 0;
    list-style-type: none;
    list-style-image: none;
    font-family: Averta-Bold;
    font-size: 14px;
    line-height: 24px;
    font-size: 0.82353rem;
    line-height: 1.41176rem; }
    .main-navigation .menu li:last-child {
      padding-right: 0; }
    .main-navigation .menu li a {
      color: #fff;
      padding-bottom: 3px;
      border-bottom: 1px solid transparent;
      transition: all 0.4s ease-out; }
      .main-navigation .menu li a.active-trail {
        border-bottom: 1px solid #fff; }
      .main-navigation .menu li a.active {
        border-bottom: 1px solid #fff; }
      .main-navigation .menu li a:focus {
        border-bottom: 1px solid #fff; }
      .main-navigation .menu li a:hover {
        border-bottom: 1px solid #fff; }

.main-navigation .block-menu .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.visually-hidden--off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto; }

.breadcrumb__list {
  margin: 0;
  padding: 0; }

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0; }

.callout {
  position: absolute;
  top: 20px;
  right: 50px; }

@media (min-width: 501px) and (max-width: 800px) {
  .callout {
    top: auto;
    bottom: 20px; } }

.callout__wrapper {
  position: relative;
  padding: 0; }

.centered-text {
  margin-top: 72px;
  margin-top: 4.23529rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px; }

.clearfix:before {
  content: '';
  display: table; }

.clearfix:after {
  content: '';
  display: table;
  clear: both; }

.header:before {
  content: '';
  display: table; }

.header:after {
  content: '';
  display: table;
  clear: both; }

.header .contained {
  height: 0; }

.tabs {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjY2NjY2NjIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: linear-gradient(to top, #ccc 1px, rgba(0, 0, 0, 0) 1px);
  margin: 24px 0;
  margin: 1.41176rem 0;
  border-bottom: 1px solid #ccc \0/ie;
  padding: 0 2px;
  list-style: none;
  white-space: nowrap; }
  .tabs:before {
    content: '';
    display: table; }
  .tabs:after {
    content: '';
    display: table;
    clear: both; }
  .tabs + .tabs--secondary {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjY2NjY2NjIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
    background-size: 100%;
    background-image: linear-gradient(to top, #ccc 1px, rgba(0, 0, 0, 0) 1px);
    margin-top: -24px;
    margin-top: -1.41176rem; }

.tabs--secondary {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjY2NjY2NjIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: linear-gradient(to top, #ccc 1px, rgba(0, 0, 0, 0) 1px);
  margin: 24px 0;
  margin: 1.41176rem 0;
  border-bottom: 1px solid #ccc \0/ie;
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
  font-size: .9em;
  background-image: none; }
  .tabs--secondary:before {
    content: '';
    display: table; }
  .tabs--secondary:after {
    content: '';
    display: table;
    clear: both; }
  .tabs--secondary + .tabs--secondary {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjY2NjY2NjIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
    background-size: 100%;
    background-image: linear-gradient(to top, #ccc 1px, rgba(0, 0, 0, 0) 1px);
    margin-top: -24px;
    margin-top: -1.41176rem; }

.collapsible-fieldset {
  position: relative; }
  .collapsible-fieldset.is-collapsed {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    height: 1em; }

.collapsible {
  position: relative; }
  .collapsible.collapsed {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    height: 1em; }
    .collapsible.collapsed .fieldset-wrapper {
      display: none; }
    .collapsible.collapsed .fieldset-legend {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
      background-position: 5px 50%; }
    .collapsible.collapsed [dir="rtl"] .fieldset-legend {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
      background-position: 98% 50%; }
  .collapsible .fieldset-legend {
    display: block;
    padding-left: 15px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
    background-position: 5px 65%;
    background-repeat: no-repeat; }
    .collapsible .fieldset-legend .summary {
      color: #999;
      font-size: .9em;
      margin-left: .5em; }
  .collapsible [dir="rtl"] .fieldset-legend {
    padding-left: 0;
    padding-right: 15px;
    background-position: 98% 75%; }
  .collapsible [dir="rtl"] .is-collapsed .fieldset-legend {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
    background-position: 98% 50%; }
  .collapsible .is-collapsed .fieldset-legend {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
    background-position: 5px 50%; }

.collapsible-fieldset-is-collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em; }

.is-collapsed.collapsible {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em; }

.is-collapsed .collapsible-fieldset__wrapper {
  display: none; }

.is-collapsed .collapsible-fieldset__legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  background-position: 5px 50%; }

.is-collapsed.menu__item {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  list-style-type: disc; }

.is-collapsed .collapsible .fieldset-legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  background-position: 5px 50%; }

.collapsible-fieldset-is-collapsed__wrapper {
  display: none; }

.collapsible-fieldset__legend {
  display: block;
  padding-left: 15px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  background-position: 5px 65%;
  background-repeat: no-repeat; }

.collapsible-fieldset-is-collapsed__legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  background-position: 5px 50%; }

.collapsible-fieldset__summary {
  color: #999;
  font-size: .9em;
  margin-left: .5em; }

.colored-box {
  background-color: #f15b55;
  color: #fff;
  padding-top: 48px;
  padding-top: 2.82353rem;
  padding-bottom: 48px;
  padding-bottom: 2.82353rem;
  padding-left: 17%;
  padding-right: 17%;
  text-align: center; }

.colored-box__title {
  font-family: Averta-Regular;
  font-size: 40px;
  line-height: 36px;
  font-size: 2.35294rem;
  line-height: 2.11765rem;
  margin-bottom: 12px;
  margin-bottom: 0.70588rem; }

.colored-box__link {
  font-size: 18px;
  line-height: 36px;
  font-size: 1.05882rem;
  line-height: 2.11765rem;
  transition: background-color 0.4s ease-out;
  color: #fff;
  font-family: Averta-Regular;
  width: 166px;
  border: 1px solid #fff;
  display: inline-block;
  margin-top: 33px;
  margin-bottom: 0; }
  .colored-box__link:hover {
    transition: background-color 0.4s ease-out;
    background-color: #fff;
    color: #f15b55; }

.footer-padding {
  padding-bottom: 144px;
  padding-bottom: 8.47059rem; }

.footer-negative-margin {
  margin-top: -144px;
  margin-top: -8.47059rem;
  position: relative;
  z-index: 12; }

.margin-bottom-3 {
  margin-bottom: 72px;
  margin-bottom: 4.23529rem; }

.main-bg {
  background-color: #f2f2f2; }

.section-contact .footer-contained {
  max-width: none;
  padding-left: 0;
  padding-right: 0; }
  .section-contact .footer-contained .colored-box__text {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto; }

.section-contact .colored-box.contained {
  max-width: none;
  padding-left: 0;
  padding-right: 0; }
  .section-contact .colored-box.contained .colored-box__text {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto; }

.footer__wrapper {
  background-image: url(../images/imop_footer.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.comment__section {
  margin: 24px 0;
  margin: 1.41176rem 0; }

.comments {
  margin: 24px 0;
  margin: 1.41176rem 0; }

.comment__title {
  margin: 0; }

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%; }

.comment--preview {
  background-color: #ffd; }

.comment-preview {
  background-color: #ffd; }

.comment--nested {
  margin-left: 34px; }

.indented {
  margin-left: 34px; }

.speakers {
  padding-top: 72px;
  padding-top: 4.23529rem;
  background-color: #f2f2f2; }
  .speakers .pane-title {
    font-size: 40px;
    line-height: 36px;
    font-size: 2.35294rem;
    line-height: 2.11765rem;
    margin-bottom: 0;
    margin-bottom: 0;
    padding-top: 48px;
    color: #fff;
    font-family: Averta-Bold;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 65px;
    max-width: 350px;
    position: relative;
    text-align: center;
    max-width: none; }
    .speakers .pane-title:before {
      left: 50%;
      margin-left: -85px; }
  .speakers:before {
    content: '';
    display: table; }
  .speakers:after {
    content: '';
    display: table;
    clear: both; }
  .speakers:first-child {
    padding-top: 96px;
    padding-top: 5.64706rem; }

.sponsors {
  padding-bottom: 0;
  padding-bottom: 0; }
  .sponsors h3 {
    font-size: 40px;
    line-height: 36px;
    font-size: 2.35294rem;
    line-height: 2.11765rem;
    margin-bottom: 0;
    margin-bottom: 0;
    padding-top: 48px;
    color: #fff;
    font-family: Averta-Bold;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 65px;
    max-width: 350px;
    position: relative;
    text-align: center;
    max-width: none;
    font-size: 28px;
    line-height: 36px;
    font-size: 1.64706rem;
    line-height: 2.11765rem;
    max-width: none;
    padding-top: 34px;
    font-family: Averta-Bold;
    font-size: 21.78px;
    color: #f15b55;
    margin-top: 24px;
    margin-top: 1.41176rem;
    width: 100%;
    float: left; }
    .sponsors h3:before {
      left: 50%;
      margin-left: -85px; }
  .sponsors:before {
    content: '';
    display: table; }
  .sponsors:after {
    content: '';
    display: table;
    clear: both; }

.title {
  font-size: 40px;
  line-height: 36px;
  font-size: 2.35294rem;
  line-height: 2.11765rem;
  margin-bottom: 0;
  margin-bottom: 0;
  padding-top: 48px;
  color: #fff;
  font-family: Averta-Bold;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 65px;
  max-width: 350px;
  position: relative; }

@media (min-width: 410px) {
  .title {
    font-size: 54px; }
  .speakers .pane-title {
    font-size: 54px; }
  .sponsors h3 {
    font-size: 54px; } }

@media (min-width: 0) and (max-width: 780px) {
  .title {
    margin-bottom: 24px;
    margin-bottom: 1.41176rem;
    max-width: none; }
  .speakers__inside {
    padding-top: 0;
    padding-top: 0; }
  .view-facilities-hotels {
    padding-top: 0;
    padding-top: 0; }
  .view-speakers {
    padding-top: 0;
    padding-top: 0; }
  .padding-4 {
    padding-top: 48px;
    padding-top: 2.82353rem; }
  .speakers .pane-title {
    margin-bottom: 24px;
    margin-bottom: 1.41176rem;
    max-width: none; }
  .sponsors h3 {
    margin-bottom: 24px;
    margin-bottom: 1.41176rem;
    max-width: none; } }

.title__wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%; }

.title--red {
  color: #f15b55; }

.title--panel {
  margin: auto; }

.title--panel-location {
  padding-right: 10%;
  padding-bottom: 12%; }

.title--centered {
  text-align: center;
  max-width: none; }
  .title--centered:before {
    left: 50%;
    margin-left: -85px; }

.title--medium {
  font-size: 28px;
  line-height: 36px;
  font-size: 1.64706rem;
  line-height: 2.11765rem;
  max-width: none;
  padding-top: 34px;
  font-family: Averta-Bold;
  font-size: 21.78px;
  color: #f15b55; }

.node--bg {
  background-color: #f15b55;
  color: #fff; }
  .node--bg .title {
    color: #fff; }
    .node--bg .title:before {
      background-color: #fff; }

.title--bg {
  color: #fff; }
  .title--bg:before {
    background-color: #fff; }

.editable-page {
  margin-top: 24px;
  margin-top: 1.41176rem; }
  .editable-page img {
    margin: auto;
    width: 75%; }
  .editable-page p:first-child {
    margin-top: 0; }
  .editable-page p:last-child {
    margin-bottom: 0; }
  .editable-page a.image-left {
    font-family: Averta-Regular;
    letter-spacing: 0.25px;
    font-size: 16px;
    border-bottom: 1px solid transparent;
    transition: all 0.4s ease-out;
    padding-bottom: 3px; }
    .editable-page a.image-left:hover {
      border-color: #f15b55; }

.editable-page__supertitle {
  font-family: Averta-Bold;
  font-size: 21.78px;
  line-height: 26.14px;
  color: #f15b55;
  margin-top: 64px;
  margin-bottom: 40px; }

.editable-page__image-wrapper {
  display: none;
  background-color: #a7b4c3; }

@media (min-width: 880px) {
  .editable-page__image-wrapper {
    display: flex; }
  .header__logo {
    transform: scale(1);
    margin: 0 auto;
    padding-top: 53px; } }

.editable-page__text-wrapper {
  width: 100%; }

.editable-page__title {
  background-color: #526479;
  color: #fff;
  padding: 15px 32px;
  font-family: Averta-Bold;
  font-size: 28px;
  line-height: 36px; }

.editable-page__text {
  background-color: #e6e6e6;
  font-family: Averta-Regular;
  padding: 29px 32px;
  font-size: 17px;
  line-height: 24px; }

.editable-page--red .editable-page__image-wrapper {
  background-color: #fbc9bc; }

.editable-page--red .editable-page__title {
  background-color: #f15b55; }

.editable-page--red .editable-page__text {
  background-color: #fee7dc; }

.editable-page--green .editable-page__image-wrapper {
  background-color: #b6dcae; }

.editable-page--green .editable-page__title {
  background-color: #83c994; }

.editable-page--green .editable-page__text {
  background-color: #e3f2e7; }

.editable-page--no-top-padding {
  padding-top: 0; }

.editable-page--distance-top {
  margin-top: 95px; }

.editable-page--distance-bottom {
  margin-bottom: 70px; }

.footer-item {
  padding-bottom: 36px;
  padding-bottom: 2.11765rem;
  padding-top: 44px;
  max-width: 290px; }
  .footer-item .form-item {
    margin: 0 0 17px; }

@media (min-width: 501px) and (max-width: 780px) {
  .footer-item {
    padding-top: 34px;
    padding-bottom: 0;
    max-width: 100%; }
  .footer-item__textfield {
    width: 100%; }
  .footer-item__submit {
    width: 100px;
    margin-top: 6px; } }

.footer-item__fixed-width {
  width: 196px; }

.footer-item__title {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  font-family: Averta-Regular;
  color: #f15b55;
  margin-bottom: 6px; }

.footer-item__text {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 16px;
  line-height: 16.8px;
  font-size: 0.94118rem;
  line-height: 0.98824rem;
  color: #fff;
  margin-bottom: 6px;
  margin-bottom: 0.35294rem;
  line-height: 30px; }

.footer-item__link {
  color: #fff; }
  .footer-item__link:focus {
    color: #f15b55; }
  .footer-item__link:hover {
    color: #f15b55; }

.footer-item__link-social {
  color: #fff;
  display: inline-block;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-bottom: 0.70588rem;
  line-height: 17px; }
  .footer-item__link-social * {
    padding: 10px; }

.footer-item__textfield {
  width: 71.5%;
  float: left;
  padding: 10px 12px;
  height: 35px;
  border: 1px solid #ccc;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 16px;
  line-height: 16px; }

.footer-item__submit {
  transition: all 0.4s ease-out;
  width: 27.1%;
  float: right;
  padding: 10px 12px;
  height: 35px;
  border: 1px solid #ccc;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  background-color: #4d4d4d; }
  .footer-item__submit:focus {
    background-color: #f15b55;
    color: #fff; }
  .footer-item__submit:hover {
    background-color: #f15b55;
    color: #fff; }

.footer-item_small {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 13px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 24px;
  margin-bottom: 1.41176rem; }

.footer-item_small-link {
  color: #f15b55; }
  .footer-item_small-link:hover {
    color: #f15b55; }

.form-item {
  margin: 24px 0;
  margin: 1.41176rem 0; }
  .form-item .description {
    font-size: 16px;
    font-size: 0.94118rem; }
  .form-item.is-error .form-item__widget {
    border: 1px solid #c00; }
  .form-item.is-error input {
    border: 1px solid #c00; }
  .form-item.is-error textarea {
    border: 1px solid #c00; }
  .form-item.is-error select {
    border: 1px solid #c00; }
  .form-item .form-item--radio .description {
    margin-left: 1.4em; }
  .form-item .form-type-radio .description {
    margin-left: 1.4em; }
  .form-item .form-type-checkbox .description {
    margin-left: 1.4em; }
  .form-item input.error {
    border: 1px solid #c00; }
  .form-item textarea.error {
    border: 1px solid #c00; }
  .form-item select.error {
    border: 1px solid #c00; }

.form-item__required {
  color: #c00; }

.form-required {
  color: #c00; }

.form-item__description {
  font-size: 16px;
  font-size: 0.94118rem; }

.form-item--inline div {
  display: inline; }

.form-item--inline label {
  display: inline; }

.container-inline .form-item div {
  display: inline; }

.container-inline .form-item label {
  display: inline; }

.container-inline .fieldset-wrapper {
  display: block; }

.form-item--inline__exception {
  display: block; }

.form-item--tight {
  margin: 0; }

.form-item--radio {
  margin: 0; }
  .form-item--radio .form-item__label {
    display: inline;
    font-weight: normal; }
  .form-item--radio .form-item__description {
    margin-left: 1.4em; }
  .form-item--radio .form-item .description {
    margin-left: 1.4em; }

.form-type-radio {
  margin: 0; }
  .form-type-radio .form-item__label {
    display: inline;
    font-weight: normal; }
  .form-type-radio .description {
    margin-left: 1.4em; }
  .form-type-radio .form-item__description {
    margin-left: 1.4em; }
  .form-type-radio .form-item .description {
    margin-left: 1.4em; }

.form-type-checkbox {
  margin: 0; }
  .form-type-checkbox .form-item__label {
    display: inline;
    font-weight: normal; }
  .form-type-checkbox .description {
    margin-left: 1.4em; }
  .form-type-checkbox .form-item__description {
    margin-left: 1.4em; }
  .form-type-checkbox .form-item .description {
    margin-left: 1.4em; }

.password-parent {
  margin: 0; }

.confirm-parent {
  margin: 0; }

.form-item--radio__label {
  display: inline;
  font-weight: normal; }

.form-item--radio__description {
  margin-left: 1.4em; }

.form-item-is-error__widget {
  border: 1px solid #c00; }

.form-item-is-error input {
  border: 1px solid #c00; }

.form-item-is-error textarea {
  border: 1px solid #c00; }

.form-item-is-error select {
  border: 1px solid #c00; }

.form-table__sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff; }
  .form-table__sticky-header.is-sticky {
    visibility: visible; }

.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff; }

.form-table__sticky-header-is-sticky {
  visibility: visible; }

.form-table__header {
  border-bottom: 3px solid #ccc;
  padding-right: 1em;
  text-align: left; }

.form-table th {
  border-bottom: 3px solid #ccc;
  padding-right: 1em;
  text-align: left; }
  .form-table th.form-table__narrow-column {
    text-align: center; }

.form-table tbody {
  border-top: 1px solid #ccc; }
  .form-table tbody tr {
    padding: .1em .6em;
    border-bottom: 1px solid #ccc;
    background-color: #eee; }
    .form-table tbody tr:nth-child(even) {
      background-color: #fff; }

.form-table ul {
  margin: 0; }

.form-table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0; }

.form-table__body {
  border-top: 1px solid #ccc; }

.form-table__row {
  padding: .1em .6em;
  border-bottom: 1px solid #ccc;
  background-color: #eee; }

.form-table__row-even {
  background-color: #fff; }

.form-table__list {
  margin: 0; }

.form-table__narrow-column {
  text-align: center; }

tr.drag {
  background-color: #ffd; }

tr.drag-previous {
  background-color: #ffffc4; }

tr.selected td {
  background: #ffd; }

tr .ajax-progress .throbber {
  margin: 0 2px; }

.form form {
  margin: 0 auto;
  max-width: 950px;
  color: #fff; }

.form .form-item {
  width: 50%;
  float: left;
  padding: 10px 15px;
  margin: 0; }

.form .webform-component-managed_file {
  clear: both;
  padding-top: 0;
  color: #cfe2e5;
  font-size: 17px; }

.form .webform-component-textarea {
  clear: both;
  width: 100%; }

.form input[type="email"] {
  border: 1px solid #b6d3d7;
  background-color: transparent;
  height: 45px;
  padding: 8px 10px;
  color: #cfe2e5;
  font-size: 17px; }
  .form input[type="email"].error {
    border-color: #c00; }

.form input[type="text"] {
  border: 1px solid #b6d3d7;
  background-color: transparent;
  height: 45px;
  padding: 8px 10px;
  color: #cfe2e5;
  font-size: 17px; }
  .form input[type="text"].error {
    border-color: #c00; }

.form textarea {
  border: 1px solid #b6d3d7;
  background-color: transparent;
  height: 45px;
  padding: 8px 10px;
  color: #cfe2e5;
  font-size: 17px;
  width: 100%;
  height: 170px; }
  .form textarea.error {
    border-color: #c00; }

.form .description {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 15px;
  line-height: 21px;
  margin-top: 15px;
  color: #cfe2e5; }

.form select {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  width: 100%;
  padding: 8px 35px 8px 8px;
  height: 45px;
  appearance: none;
  background: url(../sass/components/form/select-arrow.png?1539002441) 96% 50% no-repeat transparent; }

.form .form-required {
  font-family: Averta-Regular;
  font-size: 18px;
  line-height: 36px;
  font-size: 1.05882rem;
  line-height: 2.11765rem;
  letter-spacing: 0.25px;
  color: #fff;
  font-weight: normal; }

.form label {
  font-family: Averta-Regular;
  font-size: 18px;
  line-height: 36px;
  font-size: 1.05882rem;
  line-height: 2.11765rem;
  letter-spacing: 0.25px;
  color: #fff;
  font-weight: normal; }

.form #edit-submitted-proof-of-payment-upload-button {
  display: none; }

.form #edit-submitted-proof-of-payment-upload-button--2 {
  display: none; }

.form .form-actions {
  clear: both;
  padding: 17px 15px 72px;
  text-align: right; }

.form .form-submit {
  padding: 15px 38px;
  border: 0 solid #58595b;
  border-radius: 5px;
  background-color: #db3b30;
  font-family: Averta-Regular;
  font-size: 18px;
  line-height: 36px;
  font-size: 1.05882rem;
  line-height: 2.11765rem;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #58595b;
  text-transform: uppercase;
  transition: all 0.4s ease-out; }
  .form .form-submit:hover {
    color: #f15b55;
    background-color: #fff;
    transition: all 0.4s ease-out; }

.form__text {
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-top: 1.41176rem;
  color: #fff; }
  .form__text h3 {
    margin-top: 24px;
    margin-top: 1.41176rem;
    color: #1a1a1a;
    font-family: Averta-Regular;
    font-size: 21px;
    line-height: 24px;
    font-size: 1.23529rem;
    line-height: 1.41176rem;
    letter-spacing: 0.5px; }
  .form__text p {
    margin: 0; }
  .form__text a {
    color: #fff;
    text-decoration: underline; }
    .form__text a:hover {
      text-decoration: none; }

.form__title {
  margin-top: 72px;
  margin-top: 4.23529rem;
  color: #1a1a1a;
  font-family: Averta-Regular;
  font-size: 21px;
  line-height: 24px;
  font-size: 1.23529rem;
  line-height: 1.41176rem;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase; }

.gallery {
  padding-top: 96px;
  padding-top: 5.64706rem;
  padding-bottom: 240px;
  padding-bottom: 14.11765rem; }

#google-map {
  height: 750px;
  background-color: #58595b; }

@media (min-width: 480px) and (max-width: 780px) {
  #google-map {
    height: 550px; }
  .article-teaser__body {
    font-size: 16px;
    line-height: 21px; }
  .speakers__subtitle {
    font-size: 16px;
    line-height: 21px; } }

.header__logo {
  padding: 0 10px 0 0;
  display: block;
  position: relative;
  top: 0;
  left: 1px;
  z-index: 101;
  transform: scale(0.7);
  width: 206px;
  height: 141px;
  margin: 0 auto;
  margin-right: -28px;
  margin-top: -10px; }
  .header__logo img {
    z-index: -1; }

@media (min-width: 576px) {
  .header__logo {
    transform: scale(0.8);
    margin: 0 auto; } }

@media (min-width: 1090px) {
  .header__logo {
    margin: 0;
    transform: scale(1);
    padding-top: 53px;
    z-index: 96; } }

.header__logo-image {
  vertical-align: bottom; }

.header__name-and-slogan {
  float: left; }

.header__site-link:link {
  color: #1a1a1a;
  text-decoration: none; }

.header__site-link:visited {
  color: #1a1a1a;
  text-decoration: none; }

.header__site-link:hover {
  text-decoration: underline; }

.header__site-link:focus {
  text-decoration: underline; }

.header__site-slogan {
  margin: 0; }

.header__secondary-menu {
  float: right; }

.header__region {
  clear: both; }

.hidden {
  display: none; }

.hidden-for-anonymous {
  display: none; }

.highlight-mark {
  color: #c00;
  background-color: transparent; }

.new {
  color: #c00;
  background-color: transparent; }

.update {
  color: #c00;
  background-color: transparent; }

.image-link {
  line-height: 0; }

.image-link--overlay-hover {
  position: relative; }
  .image-link--overlay-hover a:hover:before {
    opacity: 0.8; }
  .image-link--overlay-hover a:hover:after {
    font-size: 108px;
    opacity: 1; }
  .image-link--overlay-hover a:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f15b55;
    opacity: 0;
    z-index: 10;
    transition: all 0.4s ease-out; }
  .image-link--overlay-hover a:after {
    content: ' +';
    font-size: 78px;
    line-height: 78px;
    color: #fff;
    text-align: center;
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    z-index: 11;
    transition: all 0.4s ease-out; }

.inline-links {
  padding: 0; }

.inline-links__item {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0; }

.inline-sibling {
  display: inline;
  margin-right: 10px; }
  .inline-sibling * {
    display: inline; }
  .inline-sibling + * {
    display: inline; }
    .inline-sibling + * > *:first-child {
      display: inline; }

.field__label-inline {
  display: inline;
  margin-right: 10px; }
  .field__label-inline * {
    display: inline; }
  .field__label-inline + * {
    display: inline; }
    .field__label-inline + * > *:first-child {
      display: inline; }

.inline-sibling__child {
  display: inline; }

.inline-sibling__adjacent {
  display: inline; }

.field__items-inline {
  display: inline; }

.link-tile {
  background-size: cover;
  margin-top: -36px;
  margin-top: -2.11765rem;
  margin-bottom: 24px;
  margin-bottom: 1.41176rem; }
  .link-tile:hover .link-tile__link {
    opacity: 1;
    top: 0; }

.link-title__link-to-content {
  height: 400px;
  padding-top: 320px;
  display: block;
  color: #fff; }

.link-tile__title {
  font-size: 36px;
  line-height: 36px;
  font-size: 2.11765rem;
  line-height: 2.11765rem;
  text-align: center;
  font-family: Averta-Regular;
  letter-spacing: 0.25px;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px; }

.link-tile__link {
  margin-top: -5px;
  text-align: center;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  opacity: 0;
  transition: all 0.4s ease-out;
  position: relative;
  top: 20px; }

.panel-2col-stacked .panel-col-top .inside {
  margin: 0; }

.panel-2col-stacked .panel-col-last .inside {
  margin: 0 0 0 15px; }

.panel-2col-stacked .panel-col-first .inside {
  margin: 0 15px 0 0; }

.messages {
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 10px 10px 10px 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid #be7;
  background-color: #f8fff0;
  color: #234600; }
  .messages.warning {
    margin: 24px 0;
    margin: 1.41176rem 0;
    padding: 10px 10px 10px 50px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
    background-position: 8px 8px;
    background-repeat: no-repeat;
    border: 1px solid #be7;
    background-color: #f8fff0;
    color: #234600;
    background-color: #ffd;
    color: #8c2e0b;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACuElEQVRIiWP4//8/Ay0xSYqntTpnT252zqeJBf0Njhsykrz/pyd6/e9vcNpGVQv6q2wlm0qc/r0+IPD/3UG+/61l9v9mdrjIUc2C7hqHUzc3S///eZwBjO9tF/vfWe1wjioWTKixVm8otPn38wQT3IKfxxn/t5Va/utpsNSg2ILWcttrNzdJgQ3+dpQRjEHs+9tE/zeXWt+gyILOamuTqlxrsOtPLub+7+emBsSq/88v5wL7oqHQ9H9nmbkF2RbUF1rev7lJEuziuU3i/90ddcB4UZsoJC62ifyvK7R4QJYFrcUGrmUZ5v9hYb9hosh/bzcDMN42VRgeF9W5hv8bi/XdSbagKtfs2c1NEvCIPbaQ/7+/pwkYn17Ki0hR24T/l2eZPCfJgsZ83dCiNOP/yCnn7iau/8G+5mD8aBsHSoqqyNL9X5erHUm0BcVpRm9ubhZHMoTh/4eDzP/DA23+RwTZ/P96hAlF7t5Wof8FyfpvibKgNk8noyDZ4D9quofg1Bjr/1kJlhjiIF+Upmn/r83RzCJoQXaC3qcbm8SwGMLwvybP/H8jMGlik7u7VeB/Zqz2J7wWVGdr1uTG62J1PQgfWST1/+hiCaxyIF8UJqv9r8hQrcVpQVqkzrcbG0WwGvB2H/P/lnx5MAaxsam5vYn3f2KY+jesFpSlqfZnxWjidP2OGWL/g/0swBjExu4Lhv958Ur/i5KU+lEsCA1lYI4JUv95bZ0gTo2Pt3P+z0myBmMQG5e6mxu4/kf4Kf8EmQm3oCRNebKrvSawIGPBqRG9sMOp5hjjfwdrlf/58bKT4RaUpWvtcLZV/39iscD/H0AFP46jYwYiMeP/44u4/9tbKQODSXUH3II9G7v18hI0n8YGKv+IDVT6joxj/BVx4mgcOCde/SnITPRUJAHEGlTCEkQV19TAAN8FC67hZdFXAAAAAElFTkSuQmCC");
    border-color: #ed5; }
  .messages.error {
    margin: 24px 0;
    margin: 1.41176rem 0;
    padding: 10px 10px 10px 50px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
    background-position: 8px 8px;
    background-repeat: no-repeat;
    border: 1px solid #be7;
    background-color: #f8fff0;
    color: #234600;
    background-color: #fef5f1;
    color: #8c2e0b;
    border-color: #ed541d;
    display: block;
    max-width: 920px;
    margin: 0 auto;
    background: #fef5f1; }
    .messages.error .error {
      color: #1a1a1a; }
  .messages.clientside-error {
    margin-top: 30px;
    margin-bottom: 30px; }

.messages--warning {
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 10px 10px 10px 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid #be7;
  background-color: #f8fff0;
  color: #234600;
  background-color: #ffd;
  color: #8c2e0b;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACuElEQVRIiWP4//8/Ay0xSYqntTpnT252zqeJBf0Njhsykrz/pyd6/e9vcNpGVQv6q2wlm0qc/r0+IPD/3UG+/61l9v9mdrjIUc2C7hqHUzc3S///eZwBjO9tF/vfWe1wjioWTKixVm8otPn38wQT3IKfxxn/t5Va/utpsNSg2ILWcttrNzdJgQ3+dpQRjEHs+9tE/zeXWt+gyILOamuTqlxrsOtPLub+7+emBsSq/88v5wL7oqHQ9H9nmbkF2RbUF1rev7lJEuziuU3i/90ddcB4UZsoJC62ifyvK7R4QJYFrcUGrmUZ5v9hYb9hosh/bzcDMN42VRgeF9W5hv8bi/XdSbagKtfs2c1NEvCIPbaQ/7+/pwkYn17Ki0hR24T/l2eZPCfJgsZ83dCiNOP/yCnn7iau/8G+5mD8aBsHSoqqyNL9X5erHUm0BcVpRm9ubhZHMoTh/4eDzP/DA23+RwTZ/P96hAlF7t5Wof8FyfpvibKgNk8noyDZ4D9quofg1Bjr/1kJlhjiIF+Upmn/r83RzCJoQXaC3qcbm8SwGMLwvybP/H8jMGlik7u7VeB/Zqz2J7wWVGdr1uTG62J1PQgfWST1/+hiCaxyIF8UJqv9r8hQrcVpQVqkzrcbG0WwGvB2H/P/lnx5MAaxsam5vYn3f2KY+jesFpSlqfZnxWjidP2OGWL/g/0swBjExu4Lhv958Ur/i5KU+lEsCA1lYI4JUv95bZ0gTo2Pt3P+z0myBmMQG5e6mxu4/kf4Kf8EmQm3oCRNebKrvSawIGPBqRG9sMOp5hjjfwdrlf/58bKT4RaUpWvtcLZV/39iscD/H0AFP46jYwYiMeP/44u4/9tbKQODSXUH3II9G7v18hI0n8YGKv+IDVT6joxj/BVx4mgcOCde/SnITPRUJAHEGlTCEkQV19TAAN8FC67hZdFXAAAAAElFTkSuQmCC");
  border-color: #ed5; }

.messages--error {
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 10px 10px 10px 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid #be7;
  background-color: #f8fff0;
  color: #234600;
  background-color: #fef5f1;
  color: #8c2e0b;
  border-color: #ed541d;
  display: block;
  max-width: 920px;
  margin: 0 auto;
  background: #fef5f1; }
  .messages--error .error {
    color: #1a1a1a; }

.messages--ok-color {
  background-color: #f8fff0;
  color: #234600; }

.ok {
  background-color: #f8fff0;
  color: #234600; }

.revision-current {
  background-color: #f8fff0;
  color: #234600; }

.messages--warning-color {
  background-color: #ffd;
  color: #8c2e0b; }

.warning {
  background-color: #ffd;
  color: #8c2e0b; }

.messages--error-color {
  background-color: #fef5f1;
  color: #8c2e0b; }

.error {
  background-color: #fef5f1;
  color: #8c2e0b; }

.messages--error__highlighted-text {
  color: #1a1a1a; }

.messages__list {
  margin: 0; }

.messages__item {
  list-style-image: none; }

.pane-page-messages {
  background-color: #f15b55;
  padding-top: 24px;
  padding-top: 1.41176rem;
  padding-bottom: 24px;
  padding-bottom: 1.41176rem; }

.more-link {
  text-align: right; }

.more-help-link {
  text-align: right; }
  .more-help-link a {
    padding: 1px 0 1px 20px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7UlEQVR42qWTPQqDQBCFcwSPkCNITpAj5AjeIm1uYpkyR7Cy2Mot7OwsBAsRwUKwmOwLGRle3EIy8PyBfZ/z3J2TiPylz8VWWZZpUB40BonRKyizaxkA88MYYiqCEgv4MTvnZJom0VqWRbz3FlJZgLYtqmEY1Lg9r+sKsIXcLSC3AC019H0vqLquLeC5AfiHYSGkcdAJimKIBQiJ4+CO92OAtm0FNc8zOjkMwE5Q63FAtbeg6zpAYvG8BWR7i5qmQYwY4MIHqYhE2DOPQWcGJBQF2XU72ZzyUeZ5GCNt5/hybJgYdAXsq5sOEE/jG6dC5IOqCXTmAAAAAElFTkSuQmCC");
    background-position: 0 50%;
    background-repeat: no-repeat; }
  .more-help-link [dir="rtl"] a {
    padding: 1px 20px 1px 0;
    background-position: 100% 50%; }

.more-link__help-icon {
  padding: 1px 0 1px 20px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7UlEQVR42qWTPQqDQBCFcwSPkCNITpAj5AjeIm1uYpkyR7Cy2Mot7OwsBAsRwUKwmOwLGRle3EIy8PyBfZ/z3J2TiPylz8VWWZZpUB40BonRKyizaxkA88MYYiqCEgv4MTvnZJom0VqWRbz3FlJZgLYtqmEY1Lg9r+sKsIXcLSC3AC019H0vqLquLeC5AfiHYSGkcdAJimKIBQiJ4+CO92OAtm0FNc8zOjkMwE5Q63FAtbeg6zpAYvG8BWR7i5qmQYwY4MIHqYhE2DOPQWcGJBQF2XU72ZzyUeZ5GCNt5/hybJgYdAXsq5sOEE/jG6dC5IOqCXTmAAAAAElFTkSuQmCC");
  background-position: 0 50%;
  background-repeat: no-repeat; }

.nav-menu__item {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAD1BMVEX///+/v7+Li4sAAADAwMBFvsw8AAAAAXRSTlMAQObYZgAAAB1JREFUCFtjYAADYwMGBmYVZSDhKAwkFJWhYiAAAB2+Aa/9ugeaAAAAAElFTkSuQmCC");
  list-style-type: square; }
  .nav-menu__item.is-expanded {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
    list-style-type: circle; }
  .nav-menu__item.is-collapsed {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
    list-style-type: disc; }

.menu__item {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAD1BMVEX///+/v7+Li4sAAADAwMBFvsw8AAAAAXRSTlMAQObYZgAAAB1JREFUCFtjYAADYwMGBmYVZSDhKAwkFJWhYiAAAB2+Aa/9ugeaAAAAAElFTkSuQmCC");
  list-style-type: square; }
  .menu__item.is-expanded {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
    list-style-type: circle; }
  .menu__item.is-collapsed {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
    list-style-type: disc; }

.nav-menu__item-is-expanded {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  list-style-type: circle; }

.nav-menu__item-is-collapsed {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  list-style-type: disc; }

.nav-menu__link-is-active {
  color: #1a1a1a; }

.navbar {
  padding: 85px 0;
  text-align: right;
  margin-top: 0;
  margin-bottom: 0; }
  .navbar li {
    display: inline-block;
    padding: 0 25px 0 0;
    list-style-type: none;
    list-style-image: none;
    font-family: Averta-Bold;
    font-size: 14px;
    line-height: 24px;
    font-size: 0.82353rem;
    line-height: 1.41176rem; }
    .navbar li:last-child {
      padding-right: 0; }
    .navbar li a {
      color: #fff;
      padding-bottom: 3px;
      border-bottom: 1px solid transparent;
      transition: all 0.4s ease-out; }
      .navbar li a.active-trail {
        border-bottom: 1px solid #fff; }
      .navbar li a.active {
        border-bottom: 1px solid #fff; }
      .navbar li a:focus {
        border-bottom: 1px solid #fff; }
      .navbar li a:hover {
        border-bottom: 1px solid #fff; }

.navbar__item {
  display: inline-block;
  padding: 0 25px 0 0;
  list-style-type: none;
  list-style-image: none;
  font-family: Averta-Bold;
  font-size: 14px;
  line-height: 24px;
  font-size: 0.82353rem;
  line-height: 1.41176rem; }
  .navbar__item:last-child {
    padding-right: 0; }
  .navbar__item a {
    color: #fff;
    padding-bottom: 3px;
    border-bottom: 1px solid transparent;
    transition: all 0.4s ease-out; }
    .navbar__item a.active-trail {
      border-bottom: 1px solid #fff; }
    .navbar__item a.active {
      border-bottom: 1px solid #fff; }
    .navbar__item a:focus {
      border-bottom: 1px solid #fff; }
    .navbar__item a:hover {
      border-bottom: 1px solid #fff; }

.pager {
  clear: both;
  padding: 0;
  text-align: center; }

.pager__item {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none; }

.pager__current-item {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
  font-weight: bold; }

.pager-current {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
  font-weight: bold; }

.pager-item {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none; }

.pager-first {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none; }

.pager-previous {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none; }

.pager-next {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none; }

.pager-last {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none; }

.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none; }

.pswp__bg {
  background-color: #fff; }

.pswp__top-bar {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  background-color: transparent; }

.pswp__ui--fit .pswp__top-bar {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  background-color: transparent; }

.pswp__ui--fit .pswp__caption {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  background-color: #fff; }

.pswp__caption {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  background-color: #fff; }

.pswp__caption__center {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  text-align: center;
  max-width: 80%;
  background: transparent;
  color: #4d4d4d; }

.pswp__counter {
  color: #fff;
  background-color: #231f20; }

.progress-bar {
  font-weight: bold; }

.progress {
  font-weight: bold; }
  .progress .bar {
    border-radius: 3px;
    margin: 0 .2em;
    border: 1px solid #666;
    background-color: #ccc; }
  .progress .filled {
    height: 1.5em;
    width: 5px;
    background: #0072b9 url(../sass/components/progress-bar/progress-bar.gif?1539002460) repeat 0 0; }
  .progress .percentage {
    float: right; }
  .progress [dir="rtl"] .percentage {
    float: left; }

.progress-bar__bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666;
  background-color: #ccc; }

.progress-bar__fill {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/components/progress-bar/progress-bar.gif?1539002460) repeat 0 0; }

.progress-bar__percentage {
  float: right; }

.progress-bar--inline {
  width: 16em;
  display: inline-block; }

.ajax-progress-bar {
  width: 16em;
  display: inline-block; }

.progress-throbber {
  display: inline-block; }

.ajax-progress {
  display: inline-block; }
  .ajax-progress .throbber {
    background: url(../sass/components/progress-throbber/progress-throbber.gif?1539002454) no-repeat 0 -18px transparent;
    float: left;
    height: 15px;
    margin: 2px;
    width: 15px; }
  .ajax-progress .message {
    padding-left: 20px; }
  .ajax-progress [dir="rtl"] .throbber {
    float: right; }

.progress-throbber__widget {
  background: url(../sass/components/progress-throbber/progress-throbber.gif?1539002454) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px; }

.progress-throbber__widget-in-tr {
  margin: 0 2px; }

.progress-throbber__message {
  padding-left: 20px; }

.resizable-textarea {
  width: 100%;
  vertical-align: bottom; }
  .resizable-textarea .grippie {
    background: url(../sass/components/resizable-textarea/grippie.png?1539002453) no-repeat center 2px #eee;
    border: 1px solid #ccc;
    border-top-width: 0;
    cursor: s-resize;
    height: 9px;
    overflow: hidden; }

.resizable-textarea__grippie {
  background: url(../sass/components/resizable-textarea/grippie.png?1539002453) no-repeat center 2px #eee;
  border: 1px solid #ccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden; }

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }
  .responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.media-youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }
  .media-youtube-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }
  .media-vimeo-preview-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.responsive-video__embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.responsive-video--4-3 {
  padding-bottom: 75%; }

#sidr-0-button {
  font-size: 16px;
  line-height: 24px;
  font-size: 0.94118rem;
  line-height: 1.41176rem;
  font-family: Averta-Regular;
  color: #fff;
  position: relative;
  padding-left: 68px;
  padding-top: 38px;
  display: inline-block;
  text-transform: uppercase; }
  #sidr-0-button:before {
    content: '';
    width: 27px;
    height: 21px;
    position: absolute;
    left: 30px;
    bottom: 5px; }

.sidr {
  display: none;
  position: absolute;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  overflow-x: none;
  overflow-y: auto;
  font-family: Averta-Regular;
  font-size: 16px;
  line-height: 19px;
  background: #f15b55;
  color: #fff;
  box-shadow: none; }
  .sidr .sidr-inner {
    padding: 0 0 15px; }
    .sidr .sidr-inner > p {
      margin-left: 15px;
      margin-right: 15px; }
  .sidr.right {
    left: auto;
    right: -260px; }
  .sidr.left {
    left: -260px;
    right: auto; }
  .sidr h1 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: #fff;
    line-height: 24px;
    background-image: none;
    box-shadow: none; }
  .sidr h2 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: #fff;
    line-height: 24px;
    background-image: none;
    box-shadow: none; }
  .sidr h3 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: #fff;
    line-height: 24px;
    background-image: none;
    box-shadow: none; }
  .sidr h4 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: #fff;
    line-height: 24px;
    background-image: none;
    box-shadow: none; }
  .sidr h5 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: #fff;
    line-height: 24px;
    background-image: none;
    box-shadow: none; }
  .sidr h6 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: #fff;
    line-height: 24px;
    background-image: none;
    box-shadow: none; }
  .sidr p {
    font-size: 13px;
    margin: 0 0 12px; }
    .sidr p a {
      color: #fff; }
  .sidr > p {
    margin-left: 15px;
    margin-right: 15px; }
  .sidr ul {
    display: block;
    margin: 0 0 15px;
    padding: 0;
    padding-top: 30px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent; }
    .sidr ul li {
      display: block;
      margin: 0;
      line-height: 36px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent; }
      .sidr ul li:hover {
        border-top: 1px solid transparent;
        line-height: 36px; }
        .sidr ul li:hover > a {
          box-shadow: none; }
        .sidr ul li:hover > span {
          box-shadow: none; }
      .sidr ul li.active {
        border-top: 1px solid transparent;
        line-height: 36px; }
        .sidr ul li.active > a {
          box-shadow: none; }
        .sidr ul li.active > span {
          box-shadow: none; }
      .sidr ul li.sidr-class-active {
        border-top: 1px solid transparent;
        line-height: 36px; }
        .sidr ul li.sidr-class-active > a {
          box-shadow: none; }
        .sidr ul li.sidr-class-active > span {
          box-shadow: none; }
      .sidr ul li a {
        padding: 0 30px;
        padding-bottom: 3px;
        display: block;
        text-decoration: none;
        color: #fff; }
        .sidr ul li a.sidr-class-active-trail {
          color: #fff; }
        .sidr ul li a.sidr-class-active {
          color: #fff; }
        .sidr ul li a:hover {
          color: #fff; }
        .sidr ul li a:focus {
          color: #fff; }
      .sidr ul li span {
        padding: 0 30px;
        padding-bottom: 3px;
        display: block;
        text-decoration: none;
        color: #fff; }
      .sidr ul li ul {
        border-bottom: none;
        margin: 0; }
        .sidr ul li ul li {
          line-height: 40px;
          font-size: 13px; }
          .sidr ul li ul li:last-child {
            border-bottom: none; }
          .sidr ul li ul li:hover {
            border-top: none;
            line-height: 41px; }
            .sidr ul li ul li:hover > a {
              box-shadow: none; }
            .sidr ul li ul li:hover > span {
              box-shadow: none; }
          .sidr ul li ul li.active {
            border-top: none;
            line-height: 41px; }
            .sidr ul li ul li.active > a {
              box-shadow: none; }
            .sidr ul li ul li.active > span {
              box-shadow: none; }
          .sidr ul li ul li.sidr-class-active {
            border-top: none;
            line-height: 41px; }
            .sidr ul li ul li.sidr-class-active > a {
              box-shadow: none; }
            .sidr ul li ul li.sidr-class-active > span {
              box-shadow: none; }
          .sidr ul li ul li a {
            color: #fff;
            padding-left: 30px; }
          .sidr ul li ul li span {
            color: #fff;
            padding-left: 30px; }
  .sidr form {
    margin: 0 15px; }
  .sidr label {
    font-size: 13px; }
  .sidr input[type="text"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr input[type="password"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr input[type="date"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr input[type="datetime"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr input[type="email"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr input[type="number"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr input[type="search"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr input[type="tel"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr input[type="time"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr input[type="url"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr textarea {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr select {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin: 0 0 10px;
    border-radius: 2px;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.6);
    display: block;
    clear: both; }
  .sidr input[type=checkbox] {
    width: auto;
    display: inline;
    clear: none; }
  .sidr input[type=button] {
    color: #333;
    background: #fff; }
    .sidr input[type=button]:hover {
      background: rgba(255, 255, 255, 0.9); }
  .sidr input[type=submit] {
    color: #333;
    background: #fff; }
    .sidr input[type=submit]:hover {
      background: rgba(255, 255, 255, 0.9); }

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center; }
  .skip-link:link {
    background-color: #666;
    color: #fff; }
  .skip-link:visited {
    background-color: #666;
    color: #fff; }

.skip-link__wrapper {
  margin: 0; }

.speakers__inside {
  padding-top: 36px;
  padding-top: 2.11765rem; }

.view-facilities-hotels {
  padding-top: 36px;
  padding-top: 2.11765rem; }

.view-speakers {
  padding-top: 36px;
  padding-top: 2.11765rem; }

.padding-4 {
  padding-top: 72px;
  padding-top: 4.23529rem;
  padding-bottom: 72px;
  padding-bottom: 4.23529rem; }

.speakers__article.speakers__article--with-link:focus .speakers__image:before {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8; }

.speakers__article.speakers__article--with-link:focus .speakers__image:after {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  font-size: 18px; }

.speakers__article.speakers__article--with-link:hover .speakers__image:before {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8; }

.speakers__article.speakers__article--with-link:hover .speakers__image:after {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  font-size: 18px; }

.speakers__article.speakers__article.node {
  max-width: 308px;
  margin-left: auto;
  margin-right: auto; }

.article-teaser__image {
  line-height: 0;
  position: relative;
  overflow: hidden;
  border-radius: 100%; }
  .article-teaser__image:before {
    transition: all 0.4s ease-out;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f15b55;
    z-index: 10; }
  .article-teaser__image:after {
    transform: translateY(-50%);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transition: all 0.4s ease-out;
    font-family: Averta-Regular;
    font-size: 22px;
    line-height: 18px;
    color: #fff;
    text-align: center;
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    z-index: 11; }

.speakers__image {
  line-height: 0;
  position: relative;
  overflow: hidden;
  border-radius: 100%; }
  .speakers__image:before {
    transition: all 0.4s ease-out;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f15b55;
    z-index: 10; }
  .speakers__image:after {
    transform: translateY(-50%);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    transition: all 0.4s ease-out;
    font-family: Averta-Regular;
    font-size: 22px;
    line-height: 18px;
    color: #fff;
    text-align: center;
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    z-index: 11; }

.speakers__data {
  background-color: #f2f2f2;
  position: relative;
  z-index: 12;
  text-align: center; }
  .speakers__data a:focus {
    text-decoration: underline; }
  .speakers__data a:hover {
    text-decoration: underline; }

.speakers__title {
  font-size: 24px;
  line-height: 24px;
  font-size: 1.41176rem;
  line-height: 1.41176rem;
  font-family: Averta-Bold;
  color: #526479;
  margin-top: 24px; }

.speakers__subtitle {
  font-size: 15px;
  color: #f15b55;
  font-family: Averta-Regular;
  margin-top: 11px; }
  .speakers__subtitle p {
    margin-top: 1em; }

.sponsors__item {
  height: 255px;
  width: 255px;
  display: block;
  text-align: center;
  position: relative; }
  .sponsors__item img {
    transform: translateY(-50%);
    position: relative;
    top: 127px; }
  .sponsors__item .sponsors__logo-nocolor {
    transition: all 0.4s ease-out;
    line-height: 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0; }
  .sponsors__item .sponsors__logo-color {
    transition: all 0.4s ease-out;
    line-height: 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    z-index: 10; }
  .sponsors__item:hover .sponsors__logo-color {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1; }

.table-drag__wrapper {
  cursor: move; }

.table-drag__item {
  background-color: #ffd; }

.table-drag__item-previous {
  background-color: #ffffc4; }

.table-drag__handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none; }
  .table-drag__handle:focus {
    text-decoration: none; }
  .table-drag__handle:hover {
    text-decoration: none; }

.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none; }
  .tabledrag-handle:focus {
    text-decoration: none; }
  .tabledrag-handle:hover {
    text-decoration: none; }
  .tabledrag-handle .handle {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAA8CAMAAACO5hB7AAAALVBMVEUAAAD///////8AAAAQEBBycnJ7e3uFhYWLi4uTk5OZmZnCwsLe3t7l5eXw8PBW32uGAAAAA3RSTlMAQb9IyhSHAAAAi0lEQVR42sVOAQ4DIQibsIF3Kv9/7szgQlgfYBNC2trKyzFiP7SPQi/RIixZSbZjYns/j+easuceXiWBj4YQvgzMY79HPIv35Q1noZX2txaXuQqTZ81ydnRetn1brJ5NhJA+5rE/OrQKCvcfBf1Ro0qtCs1aEgpOQa219htymiDwIQ/9+D/e59kj+ALoHAYllVUliQAAAABJRU5ErkJggg==") no-repeat 6px 9px;
    height: 13px;
    margin: -.4em .5em;
    padding: .42em .5em;
    width: 13px; }

.table-drag__handle-icon {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAA8CAMAAACO5hB7AAAALVBMVEUAAAD///////8AAAAQEBBycnJ7e3uFhYWLi4uTk5OZmZnCwsLe3t7l5eXw8PBW32uGAAAAA3RSTlMAQb9IyhSHAAAAi0lEQVR42sVOAQ4DIQibsIF3Kv9/7szgQlgfYBNC2trKyzFiP7SPQi/RIixZSbZjYns/j+easuceXiWBj4YQvgzMY79HPIv35Q1noZX2txaXuQqTZ81ydnRetn1brJ5NhJA+5rE/OrQKCvcfBf1Ro0qtCs1aEgpOQa219htymiDwIQ/9+D/e59kj+ALoHAYllVUliQAAAABJRU5ErkJggg==") no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px; }

.table-drag__handle-icon-is-hover {
  background-position: 6px -11px; }

.table-drag__toggle-weight-wrapper {
  text-align: right; }

.tabledrag-toggle-weight-wrapper {
  text-align: right; }

.table-drag__toggle-weight {
  font-size: .9em; }

.tabledrag-toggle-weight {
  font-size: .9em; }

.table-drag__indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px; }

.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px; }

.table-drag__tree-child {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center; }

.table-drag__tree-child-last {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACZJREFUOMtjWIUFMDQwYACmUcFRwVFBnIJTQzFAGMMoGAWjgGIAALExJ/w2ePm7AAAAAElFTkSuQmCC"); }

.tree-child-last {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACZJREFUOMtjWIUFMDQwYACmUcFRwVFBnIJTQzFAGMMoGAWjgGIAALExJ/w2ePm7AAAAAElFTkSuQmCC"); }

.table-drag__tree-child-horizontal {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center;
  background-position: -11px center; }

.tree-child-horizontal {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center;
  background-position: -11px center; }

.tree-child {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center; }

.tabs__tab {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-shadow: 1px 1px 0 #fff;
  float: left;
  margin: 0 3px;
  border: 1px solid #ccc;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie; }
  .tabs__tab.is-active {
    border-bottom-color: #fff; }

.tabs__tab-link {
  border: 1px solid #eaeaea;
  border-right: 0;
  border-bottom: 0;
  display: block;
  text-decoration: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: background-color 0.3s;
  color: #333;
  background-color: #ddd;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center; }
  .tabs__tab-link:focus {
    background-color: #eaeaea;
    border-color: #f1f1f1; }
  .tabs__tab-link:hover {
    background-color: #eaeaea;
    border-color: #f1f1f1; }
  .tabs__tab-link.is-active {
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFEAEAEA', endColorstr='#00EAEAEA');
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIgc3RvcC1vcGFjaXR5PSIwLjAiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=") transparent;
    background: linear-gradient(#eaeaea, rgba(234, 234, 234, 0)) transparent;
    border-color: #f7f7f7; }
  .tabs__tab-link:active {
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFEAEAEA', endColorstr='#00EAEAEA');
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIgc3RvcC1vcGFjaXR5PSIwLjAiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=") transparent;
    background: linear-gradient(#eaeaea, rgba(234, 234, 234, 0)) transparent;
    border-color: #f7f7f7; }

.tabs--secondary__tab-link {
  border: 1px solid #eaeaea;
  border-right: 0;
  border-bottom: 0;
  display: block;
  text-decoration: none;
  border-radius: 0.75em;
  transition: background-color 0.3s;
  text-shadow: 1px 1px 0 #fff;
  color: #666;
  background-color: #f1f1f1;
  padding: 0 .5em; }
  .tabs--secondary__tab-link:focus {
    color: #333;
    background-color: #ddd;
    border-color: #999; }
  .tabs--secondary__tab-link:hover {
    color: #333;
    background-color: #ddd;
    border-color: #999; }
  .tabs--secondary__tab-link.is-active {
    text-shadow: 1px 1px 0 #333;
    color: #fff;
    background-color: #666;
    border-color: #231f20; }
  .tabs--secondary__tab-link:active {
    text-shadow: 1px 1px 0 #333;
    color: #fff;
    background-color: #666;
    border-color: #231f20; }

.tabs--secondary__tab {
  margin: 12px 3px;
  margin: 0.70588rem 0.17647rem;
  float: left; }

#backtotop {
  background-color: #b3b3b3;
  width: 45px;
  height: 42px;
  line-height: 42px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  z-index: 100;
  text-align: center;
  display: none;
  border-radius: 0;
  border: 0;
  transition: all 0.4s ease-out;
  text-indent: -666px;
  overflow: hidden; }
  #backtotop:hover {
    background-color: #999; }
  #backtotop:focus {
    background-color: #999; }
  #backtotop:after {
    position: absolute;
    content: '';
    left: -1px;
    top: -2px;
    display: block;
    width: 45px;
    height: 45px;
    background: url(../sass/components/to_top/arrow.png?1539002474) center center no-repeat;
    z-index: 101; }

.top-banner {
  min-height: 815px;
  background-size: cover;
  background-position: center center;
  text-align: center;
  background-attachment: fixed; }

.top-banner__image {
  padding: 20px 40px; }

.top-banner--signature {
  position: relative; }

.top-banner--signature::after {
  content: '';
  position: absolute;
  bottom: 80px;
  width: 550px;
  left: 50%;
  margin-left: -275px;
  box-sizing: border-box;
  text-align: center;
  font-family: Averta-Regular;
  font-size: 20px;
  line-height: 30px;
  color: #fff; }

@media (min-width: 481px) and (max-width: 560px) {
  .top-banner--signature::after {
    width: 100%;
    left: 0;
    margin-left: 0;
    padding: 0 40px; } }

.top-banner__logo {
  background-image: url(../sass/components/top-banner/plovdiv-logo.png?1539002452);
  background-position: center top;
  background-repeat: no-repeat;
  margin-top: -10px;
  width: 200px;
  height: 85px;
  margin-left: auto;
  margin-right: auto;
  text-indent: -6666px;
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 10; }

.top-banner__title {
  padding-top: 72px;
  padding-top: 4.23529rem;
  font-size: 74px;
  line-height: 60px;
  font-size: 4.35294rem;
  line-height: 3.52941rem;
  color: #fff;
  font-family: Averta-Regular; }

.top-banner__subtitle {
  font-size: 24px;
  line-height: 24px;
  font-size: 1.41176rem;
  line-height: 1.41176rem;
  padding-top: 12px;
  padding-top: 0.70588rem;
  font-style: italic;
  color: #fff; }

.front-deprecated .top-banner__logo {
  margin-top: 72px;
  margin-top: 4.23529rem;
  background-image: url(../sass/components/top-banner/plovdiv-logo.png?1539002452);
  width: 260px;
  height: 216px; }

.front-deprecated .top-banner__title {
  padding-top: 12px;
  padding-top: 0.70588rem;
  font-size: 25px;
  line-height: 24px;
  font-size: 1.47059rem;
  line-height: 1.41176rem; }

.front-deprecated .top-banner__subtitle {
  font-size: 25px;
  line-height: 24px;
  font-size: 1.47059rem;
  line-height: 1.41176rem;
  font-family: Averta-Regular;
  font-style: normal; }

.top-banner--short {
  min-height: 360px; }

.top-banner--no-title .top-banner__title {
  display: none; }

.top-banner--no-title .top-banner__subtitle {
  display: none; }

.watermark {
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eee;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, Arial Narrow, Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word; }

.front .center-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;
  padding-bottom: 0;
  padding-bottom: 144px;
  padding-bottom: 8.47059rem; }
  .front .center-wrapper:before {
    content: '';
    display: table; }
  .front .center-wrapper:after {
    content: '';
    display: table;
    clear: both; }

.page-node:not(.node-type-speaker):not(.node-type-press-article) .main-content {
  padding-top: 80px;
  background-color: #f2f2f2;
  padding-bottom: 80px; }
  .page-node:not(.node-type-speaker):not(.node-type-press-article) .main-content > article {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px; }
  .page-node:not(.node-type-speaker):not(.node-type-press-article) .main-content > form {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px; }
  .page-node:not(.node-type-speaker):not(.node-type-press-article) .main-content > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    padding-left: 15px;
    padding-right: 15px; }

.page-user .main-content {
  padding-top: 194px;
  padding-top: 11.41176rem;
  background-color: #f15b55;
  padding-bottom: 144px;
  padding-bottom: 8.47059rem; }
  .page-user .main-content > article {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px; }
  .page-user .main-content > form {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px; }
  .page-user .main-content > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px; }

.l-three-column .view-content {
  margin: 0 -15px; }

.l-four-column .view-content {
  margin: 0 -15px; }

.view-content h3 {
  display: none; }

.l-two-column .view-content {
  margin: 0 -15px; }

#admin-toolbar ul.menu li.leaf {
  height: 31px; }

#admin-toolbar ul.menu li a {
  height: 30px; }

#admin-toolbar ul.drilldown-active-menu li {
  height: 31px; }

.page-contact .footer-negative-margin {
  margin-top: -300px; }

.page-register div.panel-pane div.footer-padding {
  padding-bottom: 0; }

.page-register div.panel-pane div.form {
  margin-bottom: 40px; }

.editable-page--image-blue-gray .editable-page__image-wrapper {
  background-color: #a7b4c3; }

.page-home .editable-page {
  margin-top: 95px;
  margin-bottom: 70px; }

.is-sticky.sticky-header {
  visibility: visible; }

form table th {
  border-bottom: 3px solid #ccc;
  padding-right: 1em;
  text-align: left; }
  form table th.form-table__narrow-column {
    text-align: center; }

form table tbody {
  border-top: 1px solid #ccc; }
  form table tbody tr {
    padding: .1em .6em;
    border-bottom: 1px solid #ccc;
    background-color: #eee; }
    form table tbody tr:nth-child(even) {
      background-color: #fff; }

form table ul {
  margin: 0; }

form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0; }

.logged-in .hidden-for-anonymous {
  display: block; }

.inline.links {
  padding: 0; }
  .inline.links li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0; }
  .inline.links [dir="rtl"] li {
    display: inline-block;
    padding: 0 0 0 1em; }

span.field-label {
  display: inline;
  margin-right: 10px; }
  span.field-label * {
    display: inline; }
  span.field-label + * {
    display: inline; }
    span.field-label + * > *:first-child {
      display: inline; }

.is-expanded.menu__item {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  list-style-type: circle; }

.nav-menu__link.is-active {
  color: #1a1a1a; }

.menu a.active {
  color: #1a1a1a; }

.pswp__button:not(.pswp__button--arrow--right):not(.pswp__button--arrow--left) {
  background-color: #231f20; }

.pswp__button--arrow--right:after {
  background-color: #231f20; }

.pswp__button--arrow--left:after {
  background-color: #231f20; }

.sidr-open #sidr-0-button:before {
  background-image: url(../sass/components/sidr/menu-close.png?1539002462); }

.sidr-inner a:hover {
  text-decoration: underline; }

.tabledrag-handle-hover .handle {
  background-position: 6px -11px; }

.top-banner--front .top-banner__title {
  font-size: 55px;
  line-height: 66px; }

.panel-pane:first-child div.contextual-links-wrapper {
  top: 136px; }

/* links mixin set the display rules of all a tags in a block */
.issuu-btn {
  cursor: pointer; }

.issuu-container {
  margin: 40px 0; }

@keyframes date {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1); }
  100% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2); } }

.messages--status {
  padding: 10px 50px !important; }

select:hover {
  cursor: pointer; }

.header-top {
  background: rgba(26, 26, 26, 0.6);
  display: block;
  width: 100%;
  height: 30px;
  position: relative;
  z-index: 99; }
  .header-top .region-header-top {
    max-width: 1080px;
    margin: 0 auto;
    text-align: right;
    height: 100%; }
    .header-top .region-header-top:after {
      content: "";
      display: table;
      clear: both; }
  .header-top .block-locale {
    float: left;
    position: relative;
    z-index: 102; }
    .header-top .block-locale li a {
      transition: 0.3s;
      color: #fff; }
      .header-top .block-locale li a:visited {
        color: #fff; }
      .header-top .block-locale li a:hover {
        color: #1b8e5d;
        cursor: pointer; }
      .header-top .block-locale li a:active {
        color: #fff; }
  .header-top .user-actions {
    float: right; }
  .header-top .block-locale,
  .header-top .user-actions {
    height: 100%;
    font-size: 14px;
    color: #fff;
    display: inline-block; }
    .header-top .block-locale a,
    .header-top .user-actions a {
      transition: 0.3s;
      color: #fff; }
      .header-top .block-locale a:visited,
      .header-top .user-actions a:visited {
        color: #fff; }
      .header-top .block-locale a:hover,
      .header-top .user-actions a:hover {
        color: #1b8e5d;
        cursor: pointer; }
      .header-top .block-locale a:active,
      .header-top .user-actions a:active {
        color: #fff; }
    .header-top .block-locale ul,
    .header-top .block-locale .menu,
    .header-top .user-actions ul,
    .header-top .user-actions .menu {
      margin: 0;
      padding: 0; }
    .header-top .block-locale li,
    .header-top .block-locale .menu__item,
    .header-top .user-actions li,
    .header-top .user-actions .menu__item {
      list-style-image: none;
      list-style-type: none;
      display: inline-block;
      vertical-align: middle;
      line-height: 2.2;
      margin-left: 10px;
      padding-right: 10px;
      border-right: 1px solid rgba(255, 255, 255, 0.1); }

.messages__icon {
  display: none; }

.main {
  padding-bottom: 60px;
  background: #f2f2f2; }

.front .event-top-banner {
  width: 320px;
  /* height: 150px; */
  /* border-radius: 50%; */
  bottom: 70px;
  /* left: 50%; */
  /* display: none; */
  position: absolute;
  right: 0;
  background: #1ea069;
  padding: 10px 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  font-family: "Averta-Bold";
  text-align: center;
  bottom: 56px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  right: 30px; }

.event-top-banner {
  position: absolute;
  right: 0;
  background: #1b8e5d;
  padding: 10px 30px;
  transform: translateY(-50%);
  color: #fff;
  font-family: "Averta-Bold";
  text-align: center;
  bottom: -93px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }
  .event-top-banner p {
    margin: 0;
    padding: 5px 0; }
    .event-top-banner p.event-title {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.grippie {
  display: none !important; }

.not-front .top-banner {
  height: 400px;
  min-height: auto; }

.not-front .top-banner__image {
  display: none; }

.not-front .link-button {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02);
  background: #0f75bc;
  color: #fff;
  border: 1px solid #004176;
  border-radius: 0;
  padding: 10px 25px;
  margin-right: 0;
  font-size: 16px; }
  .not-front .link-button:hover {
    background: #1ea069;
    border: 1px solid #1b8e5d; }
  .not-front .link-button.link-button--dl {
    background: #4d4d4d; }
    .not-front .link-button.link-button--dl:hover {
      background: #1b8e5d;
      border: 1px solid #1ea069; }

.page-node.node-type-speech .page {
  background-color: #f2f2f2; }

.node-type-speech .field-label {
  font-family: Averta-Bold; }

.node-type-speech .node.node-speech.view-mode-full {
  padding-top: 0 !important; }

.node-type-speech .view-mode-full .field {
  margin-bottom: 40px; }

.node-type-speech .view-mode-full .field-name-body {
  margin-top: 60px; }

.node-type-speech .field-name-field-start-time {
  display: inline-block;
  font-style: italic;
  margin-bottom: 15px;
  font-size: 15px; }

.node-type-speech .field-name-title-field {
  font-size: 26px;
  margin-bottom: 20px; }

.node-type-speech .field-name-field-speaker a {
  transition: 0.3s;
  color: #0f75bc; }
  .node-type-speech .field-name-field-speaker a:visited {
    color: #0f75bc; }
  .node-type-speech .field-name-field-speaker a:hover {
    color: #1b8e5d;
    cursor: pointer; }
  .node-type-speech .field-name-field-speaker a:active {
    color: #0f75bc; }

.node-type-speech .field-name-field-downloads a {
  transition: 0.3s;
  color: #0f75bc; }
  .node-type-speech .field-name-field-downloads a:visited {
    color: #0f75bc; }
  .node-type-speech .field-name-field-downloads a:hover {
    color: #1b8e5d;
    cursor: pointer; }
  .node-type-speech .field-name-field-downloads a:active {
    color: #0f75bc; }

.node-type-speech .field-name-field-downloads .field-item {
  margin: 5px 0;
  font-size: 15px; }

.page-about .pane-page-messages,
.node-type-webform .pane-page-messages,
.page-node-1693 .pane-page-messages,
.node-type-speaker .pane-page-messages,
.page-organization .pane-page-messages,
.page-location .pane-page-messages,
.page-agenda .pane-page-messages,
.page-streaming .pane-page-messages,
.page-speakers .pane-page-messages {
  display: none; }

.page-about .node-top-banner,
.node-type-webform .node-top-banner,
.page-node-1693 .node-top-banner,
.node-type-speaker .node-top-banner,
.page-organization .node-top-banner,
.page-location .node-top-banner,
.page-agenda .node-top-banner,
.page-streaming .node-top-banner,
.page-speakers .node-top-banner {
  background-image: url(/sites/all/themes/delfi/images/bg-purple-wide.jpg) !important; }

.page-about .panel-1col,
.node-type-webform .panel-1col,
.page-node-1693 .panel-1col,
.node-type-speaker .panel-1col,
.page-organization .panel-1col,
.page-location .panel-1col,
.page-agenda .panel-1col,
.page-streaming .panel-1col,
.page-speakers .panel-1col {
  background: #f2f2f2; }

.node-type-speaker .node-speaker:hover .speakers__image,
.page-agenda .node-speaker:hover .speakers__image,
.page-speakers .node-speaker:hover .speakers__image,
.node-type-speaker .node-speaker:hover .speakers__image {
  box-shadow: 0 2px 8px -2px rgba(35, 31, 32, 0.55); }

.node-type-speaker .speakers__data,
.node-type-speaker .speakers,
.page-agenda .speakers__data,
.page-agenda .speakers,
.page-speakers .speakers__data,
.page-speakers .speakers,
.node-type-speaker .speakers__data,
.node-type-speaker .speakers {
  background: #f2f2f2; }

.node-type-speaker .speakers__title,
.page-agenda .speakers__title,
.page-speakers .speakers__title,
.node-type-speaker .speakers__title {
  color: #231f20;
  line-height: 35px; }

.node-type-speaker .speakers__subtitle,
.page-agenda .speakers__subtitle,
.page-speakers .speakers__subtitle,
.node-type-speaker .speakers__subtitle {
  color: #004176; }

.node-type-speaker .image-overlayed__image img,
.page-agenda .image-overlayed__image img,
.page-speakers .image-overlayed__image img,
.node-type-speaker .image-overlayed__image img {
  border-radius: 0; }

.node-type-speaker .speakers__image,
.page-agenda .speakers__image,
.page-speakers .speakers__image,
.node-type-speaker .speakers__image {
  border-radius: 0; }
  .node-type-speaker .speakers__image:before,
  .page-agenda .speakers__image:before,
  .page-speakers .speakers__image:before,
  .node-type-speaker .speakers__image:before {
    background-color: rgba(0, 65, 118, 0.55); }

.node-type-speaker .callout,
.page-agenda .callout,
.page-speakers .callout,
.node-type-speaker .callout {
  bottom: -60px; }

.node-type-speaker .callout__wrapper,
.page-agenda .callout__wrapper,
.page-speakers .callout__wrapper,
.node-type-speaker .callout__wrapper {
  height: 200px; }

.node-type-speaker .title__wrapper,
.page-agenda .title__wrapper,
.page-speakers .title__wrapper,
.node-type-speaker .title__wrapper {
  position: relative;
  display: block;
  width: auto;
  height: auto; }

.node-speaker.view-mode-full {
  background: white;
  padding: 30px !important;
  margin-top: 60px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02); }
  .node-speaker.view-mode-full .image-overlayed__subtitle {
    color: #004176; }
  .node-speaker.view-mode-full .group-speaker-info {
    text-align: left;
    margin-bottom: 80px; }
    .node-speaker.view-mode-full .group-speaker-info a {
      transition: 0.3s;
      color: #0f75bc; }
      .node-speaker.view-mode-full .group-speaker-info a:visited {
        color: #0f75bc; }
      .node-speaker.view-mode-full .group-speaker-info a:hover {
        color: #1b8e5d;
        cursor: pointer; }
      .node-speaker.view-mode-full .group-speaker-info a:active {
        color: #0f75bc; }
    .node-speaker.view-mode-full .group-speaker-info a {
      display: block; }
    .node-speaker.view-mode-full .group-speaker-info .label-above {
      margin: 10px 0; }
  .node-speaker.view-mode-full .footer-padding {
    padding-bottom: 50px; }

.inner-region {
  margin: 0 -30px;
  padding: 30px;
  border-bottom: 1px solid rgba(35, 31, 32, 0.3); }

.toggle-area {
  cursor: pointer;
  display: inline-block; }
  .toggle-area span {
    display: inline-block;
    color: #fff;
    background: #0f75bc;
    border: 1px solid rgba(0, 65, 118, 0.3);
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center; }
  .toggle-area:hover span {
    cursor: pointer;
    background: #fff;
    color: #f15b55; }

.questions-container {
  background: #f6f6f6;
  margin: 30px -30px;
  padding: 30px;
  display: none; }

.question-wrapper {
  margin-bottom: 30px; }

.question-title {
  font-family: Averta-Bold;
  width: 90%;
  margin-bottom: 10px; }

.eval-region fieldset.speaker {
  border: 1px solid rgba(35, 31, 32, 0.2);
  margin-bottom: 30px;
  padding: 30px; }

.eval-region fieldset .name {
  font-weight: 600; }

.eval-title h2,
.debate-title h2,
.quiz-title h2 {
  display: inline-block;
  font-family: Averta-Bold;
  margin-right: 10px; }

.eval-radio,
.debate-radio,
.quiz-radio {
  margin-right: 10px; }

.eval i,
.debate i,
.quiz i {
  font-size: 14px; }

.eval-subtitle,
.debate-subtitle,
.quiz-subtitle {
  display: block; }

.correct {
  color: green; }

.wrong {
  color: red; }

.eval-results:after {
  display: table;
  clear: both; }

.to-left {
  float: left;
  margin-right: 20px; }

.to-right {
  float: left; }
  .to-right span {
    display: block;
    font-size: 15px; }
    .to-right span.eval-number {
      display: inline-block; }

.eval-current-results {
  margin-top: 20px; }

.eval-results-number {
  font-size: 50px;
  font-family: Averta-Bold; }

.answer-wrapper {
  display: block; }

.debate-answer {
  display: inline-block;
  border: 1px solid rgba(15, 117, 188, 0.25);
  border-radius: 20px;
  padding: 10px 20px;
  margin: 5px 0; }
  .debate-answer:not(.disabled):hover {
    cursor: pointer;
    background: #0f75bc;
    border: 1px solid #004176;
    color: #fff; }
  .debate-answer.selected-deb {
    background: #0f75bc;
    color: #fff;
    border: 1px solid #004176; }

.start-quiz {
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 20px;
  border: 1px solid #aaa;
  color: #231f20;
  border-radius: 0;
  padding: 8px 10px;
  transition: 0.4s;
  margin-right: 15px;
  margin-top: 20px;
  max-width: 180px; }
  .start-quiz a {
    transition: 0.3s;
    color: #231f20; }
    .start-quiz a:visited {
      color: #231f20; }
    .start-quiz a:hover {
      color: #231f20;
      cursor: pointer; }
    .start-quiz a:active {
      color: #231f20; }
  .start-quiz:hover {
    cursor: pointer;
    background: #0f75bc;
    border: 1px solid #004176; }
    .start-quiz:hover a {
      color: #fff !important; }

.eval-region .star-cb-group input {
  display: none; }

.eval-region .star-cb-group label {
  display: inline-block;
  overflow: hidden;
  text-indent: 9999px;
  width: 25px;
  white-space: nowrap;
  font-size: 22px; }
  .eval-region .star-cb-group label.selected:before {
    content: "★";
    color: #eee222; }
  .eval-region .star-cb-group label:before {
    display: inline-block;
    text-indent: -9999px;
    content: "★";
    color: #888;
    text-shadow: 0 0 1px #333; }
  .eval-region .star-cb-group label:not(.disabled) {
    cursor: pointer; }
    .eval-region .star-cb-group label:not(.disabled):hover:before {
      content: "★";
      color: #eee222; }

.eval-region fieldset {
  border: 0; }

.front .center-wrapper {
  padding-bottom: 20px; }

.front .page {
  background: #f7f7f7; }

.front .panel-pane .top-banner {
  background-image: url(/sites/all/themes/delfi/images/bg-purple-wide.jpg) !important;
  background: #004176; }

.front .top-banner {
  min-height: 705px; }

.front .top-banner__image {
  display: none; }

.front.page-home .editable-page {
  padding: 10px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  margin-top: -150px;
  position: relative;
  z-index: 1; }

.front .editable-page__image-wrapper {
  background: #c5dede; }
  .front .editable-page__image-wrapper img {
    height: 350px;
    width: auto; }

.front .editable-page__text,
.front .editable-page__title {
  background-color: #fff;
  color: #231f20; }

.front .editable-page__title {
  padding: 0 32px;
  padding-top: 40px;
  color: #004176; }

.front .panel-2col-stacked .center-wrapper .inside {
  margin: 0; }

.front .link-tile {
  height: 400px;
  border-radius: 0px;
  position: relative;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  background-size: 30% !important;
  background-position: center 90px !important;
  margin: 0;
  margin: 0 1px 2px 1px; }

.front .link-tile__title {
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.35);
  height: auto;
  width: auto;
  padding-top: 0;
  z-index: 999;
  padding: 20px 20px 24px 20px;
  text-align: left;
  font-size: 26px; }

.front .link-tile__link {
  margin-top: -50px;
  top: 0;
  right: 15px;
  text-align: right;
  font-size: 26px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
  font-family: "Averta-Regular"; }

.front .link-title__link-to-content {
  position: absolute;
  bottom: 10px;
  top: 0;
  right: 0;
  left: 0; }

.front .link-7 {
  transition: all .3s;
  background-color: #eb2570; }
  .front .link-7:hover {
    background-color: #ea1c6a;
    transform: scale(1.025);
    z-index: 99; }

.front .link-12 {
  transition: all .3s;
  background-color: #eb2570;
  background-image: url(https://uroschool.gr/sites/default/files/images/links/oly.png) !important;
  background-repeat: no-repeat !important;
  background-attachment: scroll !important; }
  .front .link-12:hover {
    background-color: #ea1c6a;
    transform: scale(1.025);
    z-index: 99; }

.front .link-1699 {
  transition: all .3s;
  background-color: #86d1e9; }
  .front .link-1699:hover {
    background-color: #7dcee7;
    transform: scale(1.025);
    z-index: 99; }

.front .link-14 {
  transition: all .3s;
  background-color: #eb2570;
  background-image: url(https://uroschool.gr/sites/default/files/images/links/oly.png) !important;
  background-repeat: no-repeat !important;
  background-attachment: scroll !important; }
  .front .link-14:hover {
    background-color: #ea1c6a;
    transform: scale(1.025);
    z-index: 99; }

.front .link-9, .front .link-1700 {
  transition: all .2s;
  background-color: #ffd272; }
  .front .link-9:hover, .front .link-1700:hover {
    background-color: #ffca59;
    transform: scale(1.025);
    z-index: 99; }

.front .link-1688 {
  transition: all .2s;
  background-color: #523091; }
  .front .link-1688:hover {
    background-color: #4e2d89;
    transform: scale(1.025);
    z-index: 99; }

.front .link-1689 {
  transition: all .2s;
  background-color: #523091; }
  .front .link-1689:hover {
    background-color: #4e2d89;
    transform: scale(1.025);
    z-index: 99; }

.callout {
  top: 80px; }

.footer__wrapper {
  background: #231f20; }

.page-agenda .field-name-field-session {
  margin: 30px 0;
  border-bottom: 1px solid rgba(35, 31, 32, 0.1);
  background: #fff;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1); }

.page-agenda .node-speech a {
  transition: 0.3s;
  color: #231f20; }
  .page-agenda .node-speech a:visited {
    color: #231f20; }
  .page-agenda .node-speech a:hover {
    color: #0f75bc;
    cursor: pointer; }
  .page-agenda .node-speech a:active {
    color: #231f20; }

.page-agenda .node-speech .field-name-body {
  margin: 20px 0; }

.page-agenda .panel-panel {
  background: #f2f2f2; }

.page-agenda .node-session .group-toggle-area .field-name-field-duration {
  float: left;
  min-width: 120px; }

.page-agenda .node-session .group-toggle-area .field-name-title-field {
  font-family: "Averta-Bold";
  font-size: 20px; }
  .page-agenda .node-session .group-toggle-area .field-name-title-field a {
    transition: 0.3s;
    color: #231f20; }
    .page-agenda .node-session .group-toggle-area .field-name-title-field a:visited {
      color: #231f20; }
    .page-agenda .node-session .group-toggle-area .field-name-title-field a:hover {
      color: #0f75bc;
      cursor: pointer; }
    .page-agenda .node-session .group-toggle-area .field-name-title-field a:active {
      color: #231f20; }
  .page-agenda .node-session .group-toggle-area .field-name-title-field a:hover {
    text-decoration: underline; }

.page-agenda .node-session .field-name-body p {
  margin: 0; }

.page-agenda .node-session > .field-name-title-field {
  font-family: "Averta-Bold"; }

.page-agenda .node-session > header {
  display: none; }

.page-agenda .node-session .field-name-field-session-slots {
  position: relative;
  margin: 30px;
  margin-bottom: 60px; }
  .page-agenda .node-session .field-name-field-session-slots:after {
    content: "";
    height: 100%;
    width: 1px;
    background: #eaeaea;
    position: absolute;
    left: 20px;
    top: 0; }

.page-agenda .node-session .group-session-data {
  margin-top: 20px; }
  .page-agenda .node-session .group-session-data .field-name-body {
    margin-top: 20px;
    margin-bottom: 20px; }

.page-agenda .node-session .group-toggle-area.open {
  background: #f6f6f6;
  border-bottom: 1px solid rgba(15, 117, 188, 0.15); }

.page-agenda .node-session .group-inner-info .field-name-title-field {
  display: inline-block;
  width: calc(100% - 155px); }

.page-agenda .node-session .group-toggle-area-inner {
  display: inline-block;
  vertical-align: top; }
  .page-agenda .node-session .group-toggle-area-inner .toggle-area-btn {
    padding: 2px 8px;
    min-width: 29px;
    text-align: center;
    background: #0f75bc;
    border: 1px solid #004176;
    color: #fff;
    transition: .3s; }
    .page-agenda .node-session .group-toggle-area-inner .toggle-area-btn:hover {
      cursor: pointer;
      background: #1ea069;
      border: 1px solid #1b8e5d; }

.page-agenda .session-coordinators a {
  transition: 0.3s;
  color: #0f75bc; }
  .page-agenda .session-coordinators a:visited {
    color: #0f75bc; }
  .page-agenda .session-coordinators a:hover {
    color: #1b8e5d;
    cursor: pointer; }
  .page-agenda .session-coordinators a:active {
    color: #0f75bc; }

.page-agenda .session-coordinators .field-items,
.page-agenda .session-coordinators .field-label {
  display: inline-block; }

.page-agenda .session-coordinators .field-items .field-item {
  display: inline-block; }
  .page-agenda .session-coordinators .field-items .field-item:after {
    content: ",";
    display: inline-block;
    padding-right: 5px; }
  .page-agenda .session-coordinators .field-items .field-item:last-child:after {
    display: none; }

.page-agenda .group-toggle-area {
  padding: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.node-speech.node-teaser .links.inline {
  display: none; }

.node-speech.node-teaser .field-name-title-field a {
  transition: 0.3s;
  color: #231f20; }
  .node-speech.node-teaser .field-name-title-field a:visited {
    color: #231f20; }
  .node-speech.node-teaser .field-name-title-field a:hover {
    color: #0f75bc;
    cursor: pointer; }
  .node-speech.node-teaser .field-name-title-field a:active {
    color: #231f20; }

.node-speech.node-teaser .field-name-title-field a:hover {
  text-decoration: underline; }

.node-speech.node-teaser .field-name-field-session-slots {
  padding: 20px 40px 30px 40px; }

.node-speech.node-teaser .group-general-info {
  width: 100%;
  width: calc(100% - 190px);
  max-width: 750px;
  float: left; }

.node-speech.node-teaser .group-teaser-attachments {
  float: right;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid rgba(35, 31, 32, 0.1);
  width: auto;
  max-width: 130px; }

.node-speech.node-teaser .field-name-title-field {
  font-family: "Averta-Bold";
  padding-bottom: 10px;
  font-size: 20px; }
  .node-speech.node-teaser .field-name-title-field a:hover {
    text-decoration: underline; }

.node-speech.node-teaser .field-name-field-start-time {
  font-family: "Averta-Bold";
  background: #fff;
  position: relative;
  z-index: 99;
  min-width: 120px;
  color: #0f75bc; }

.node-speech.node-teaser .slot-secondary-info {
  padding: 20px 40px 30px 60px; }
  .node-speech.node-teaser .slot-secondary-info:after {
    content: "";
    display: table;
    clear: both;
    width: 100%; }

.node-speech.node-teaser .field-name-field-start-time {
  display: inline-block;
  vertical-align: top;
  padding: 10px 0; }

.page-gdpr .panel-1col,
.page-oroi-xrhshs .panel-1col,
.page-about .panel-1col,
.page-location .panel-1col,
.page-streaming .panel-1col,
.page-organization .panel-1col {
  background: #f2f2f2; }

.page-gdpr .editable-page,
.page-oroi-xrhshs .editable-page,
.page-about .editable-page,
.page-location .editable-page,
.page-streaming .editable-page,
.page-organization .editable-page {
  padding: 10px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1; }

.page-gdpr .editable-page__text,
.page-gdpr .editable-page__title,
.page-oroi-xrhshs .editable-page__text,
.page-oroi-xrhshs .editable-page__title,
.page-about .editable-page__text,
.page-about .editable-page__title,
.page-location .editable-page__text,
.page-location .editable-page__title,
.page-streaming .editable-page__text,
.page-streaming .editable-page__title,
.page-organization .editable-page__text,
.page-organization .editable-page__title {
  background-color: #fff;
  color: #231f20; }

.page-gdpr .editable-page__image-wrapper,
.page-oroi-xrhshs .editable-page__image-wrapper,
.page-about .editable-page__image-wrapper,
.page-location .editable-page__image-wrapper,
.page-streaming .editable-page__image-wrapper,
.page-organization .editable-page__image-wrapper {
  background: #c5dede; }

.page-gdpr .editable-page img,
.page-oroi-xrhshs .editable-page img,
.page-about .editable-page img,
.page-location .editable-page img,
.page-streaming .editable-page img,
.page-organization .editable-page img {
  width: auto; }

.page-gdpr .editable-page__title,
.page-oroi-xrhshs .editable-page__title,
.page-about .editable-page__title,
.page-location .editable-page__title,
.page-streaming .editable-page__title,
.page-organization .editable-page__title {
  padding: 0 32px;
  padding-top: 40px;
  color: #004176; }

.page-gdpr .editable-page__supertitle,
.page-oroi-xrhshs .editable-page__supertitle,
.page-about .editable-page__supertitle,
.page-location .editable-page__supertitle,
.page-streaming .editable-page__supertitle,
.page-organization .editable-page__supertitle {
  color: #004176; }

.page-contact .form {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  padding-left: 15px;
  padding-right: 15px; }

.page-contact .view-mode-full,
.node-type-webform .view-mode-full {
  padding: 30px; }
  .page-contact .view-mode-full form,
  .node-type-webform .view-mode-full form {
    margin-top: 30px; }

.page-contact .form,
.node-type-webform .form {
  background-color: #fff;
  color: #231f20;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02); }
  .page-contact .form label,
  .node-type-webform .form label {
    color: #231f20; }
  .page-contact .form select:focus, .page-contact .form input:focus, .page-contact .form textarea:focus,
  .node-type-webform .form select:focus,
  .node-type-webform .form input:focus,
  .node-type-webform .form textarea:focus {
    outline: none;
    border: 1px solid rgba(15, 117, 188, 0.5); }
  .page-contact .form input[type="text"],
  .page-contact .form input[type="email"],
  .page-contact .form textarea,
  .page-contact .form select,
  .node-type-webform .form input[type="text"],
  .node-type-webform .form input[type="email"],
  .node-type-webform .form textarea,
  .node-type-webform .form select {
    border: 1px solid #aaa;
    color: #231f20;
    border-radius: 0; }
  .page-contact .form .form-submit,
  .node-type-webform .form .form-submit {
    display: block;
    margin: 0 auto;
    background: #0f75bc;
    color: #fff;
    border: 1px solid #004176;
    border-radius: 0;
    padding: 10px 25px; }
    .page-contact .form .form-submit:hover,
    .node-type-webform .form .form-submit:hover {
      background: #1ea069;
      border: 1px solid #1b8e5d; }
    .page-contact .form .form-submit.form-button-disabled,
    .node-type-webform .form .form-submit.form-button-disabled {
      background: #999;
      border: 1px solid #231f20;
      opacity: 0.3; }
      .page-contact .form .form-submit.form-button-disabled:hover,
      .node-type-webform .form .form-submit.form-button-disabled:hover {
        border: 1px solid #231f20;
        background: #999;
        cursor: default; }
  .page-contact .form .description,
  .node-type-webform .form .description {
    color: #4c4c4c; }

.page-contact .form__text,
.node-type-webform .form__text {
  color: #231f20; }
  .page-contact .form__text a,
  .node-type-webform .form__text a {
    transition: 0.3s;
    color: #004176; }
    .page-contact .form__text a:visited,
    .node-type-webform .form__text a:visited {
      color: #004176; }
    .page-contact .form__text a:hover,
    .node-type-webform .form__text a:hover {
      color: #1b8e5d;
      cursor: pointer; }
    .page-contact .form__text a:active,
    .node-type-webform .form__text a:active {
      color: #004176; }

.page-contact #webform-client-form-1693 .webform-component,
.node-type-webform #webform-client-form-1693 .webform-component {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 30px; }

.page-contact #webform-client-form-1693 fieldset,
.node-type-webform #webform-client-form-1693 fieldset {
  border: none; }
  .page-contact #webform-client-form-1693 fieldset legend,
  .node-type-webform #webform-client-form-1693 fieldset legend {
    font-family: "Averta-Bold";
    color: #231f20;
    padding-top: 30px; }

.page-contact #webform-client-form-1693 .form-item,
.node-type-webform #webform-client-form-1693 .form-item {
  width: 100%;
  padding: 30px; }
  .page-contact #webform-client-form-1693 .form-item label,
  .node-type-webform #webform-client-form-1693 .form-item label {
    padding-bottom: 30px;
    font-family: "Averta-Bold";
    line-height: 1.5; }
  .page-contact #webform-client-form-1693 .form-item .form-item,
  .node-type-webform #webform-client-form-1693 .form-item .form-item {
    display: inline-block;
    width: 20%;
    padding: 0; }
    .page-contact #webform-client-form-1693 .form-item .form-item label,
    .node-type-webform #webform-client-form-1693 .form-item .form-item label {
      font-family: "Averta-Regular";
      position: relative;
      top: 2px;
      padding-left: 5px; }

.page-contact #webform-client-form-1693 .form-actions,
.node-type-webform #webform-client-form-1693 .form-actions {
  padding: 70px 15px 72px; }

.node-type-speech .node-session.view-mode-full,
.node-type-speech .node-speech.view-mode-full,
.node-type-session .node-session.view-mode-full,
.node-type-session .node-speech.view-mode-full {
  margin-top: 80px;
  background: #fff;
  padding: 30px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02); }
  .node-type-speech .node-session.view-mode-full .field-label,
  .node-type-speech .node-speech.view-mode-full .field-label,
  .node-type-session .node-session.view-mode-full .field-label,
  .node-type-session .node-speech.view-mode-full .field-label {
    padding-bottom: 20px; }
  .node-type-speech .node-session.view-mode-full .field-name-title-field,
  .node-type-speech .node-session.view-mode-full .field-name-field-speaker,
  .node-type-speech .node-session.view-mode-full .field-name-field-start-time,
  .node-type-speech .node-speech.view-mode-full .field-name-title-field,
  .node-type-speech .node-speech.view-mode-full .field-name-field-speaker,
  .node-type-speech .node-speech.view-mode-full .field-name-field-start-time,
  .node-type-session .node-session.view-mode-full .field-name-title-field,
  .node-type-session .node-session.view-mode-full .field-name-field-speaker,
  .node-type-session .node-session.view-mode-full .field-name-field-start-time,
  .node-type-session .node-speech.view-mode-full .field-name-title-field,
  .node-type-session .node-speech.view-mode-full .field-name-field-speaker,
  .node-type-session .node-speech.view-mode-full .field-name-field-start-time {
    display: none; }
  .node-type-speech .node-session.view-mode-full .slot-secondary-info .field-name-title-field,
  .node-type-speech .node-speech.view-mode-full .slot-secondary-info .field-name-title-field,
  .node-type-session .node-session.view-mode-full .slot-secondary-info .field-name-title-field,
  .node-type-session .node-speech.view-mode-full .slot-secondary-info .field-name-title-field {
    display: block; }
  .node-type-speech .node-session.view-mode-full .field-name-field-videos .field-items,
  .node-type-speech .node-speech.view-mode-full .field-name-field-videos .field-items,
  .node-type-session .node-session.view-mode-full .field-name-field-videos .field-items,
  .node-type-session .node-speech.view-mode-full .field-name-field-videos .field-items {
    width: 100%;
    padding: 15px 30px; }
    .node-type-speech .node-session.view-mode-full .field-name-field-videos .field-items .field-item,
    .node-type-speech .node-speech.view-mode-full .field-name-field-videos .field-items .field-item,
    .node-type-session .node-session.view-mode-full .field-name-field-videos .field-items .field-item,
    .node-type-session .node-speech.view-mode-full .field-name-field-videos .field-items .field-item {
      display: inline-block;
      width: 100%;
      max-width: 305px;
      margin-right: 20px;
      margin-bottom: 20px;
      height: 170px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02); }
      .node-type-speech .node-session.view-mode-full .field-name-field-videos .field-items .field-item iframe,
      .node-type-speech .node-speech.view-mode-full .field-name-field-videos .field-items .field-item iframe,
      .node-type-session .node-session.view-mode-full .field-name-field-videos .field-items .field-item iframe,
      .node-type-session .node-speech.view-mode-full .field-name-field-videos .field-items .field-item iframe {
        width: 100%;
        height: 170px; }
  .node-type-speech .node-session.view-mode-full .teaser-video a,
  .node-type-speech .node-speech.view-mode-full .teaser-video a,
  .node-type-session .node-session.view-mode-full .teaser-video a,
  .node-type-session .node-speech.view-mode-full .teaser-video a {
    width: 50px;
    height: 50px; }
  .node-type-speech .node-session.view-mode-full .slot-secondary-info .teaser-video a,
  .node-type-speech .node-speech.view-mode-full .slot-secondary-info .teaser-video a,
  .node-type-session .node-session.view-mode-full .slot-secondary-info .teaser-video a,
  .node-type-session .node-speech.view-mode-full .slot-secondary-info .teaser-video a {
    width: 35px;
    height: 35px; }
  .node-type-speech .node-session.view-mode-full .field-name-field-downloads .field-items,
  .node-type-speech .node-speech.view-mode-full .field-name-field-downloads .field-items,
  .node-type-session .node-session.view-mode-full .field-name-field-downloads .field-items,
  .node-type-session .node-speech.view-mode-full .field-name-field-downloads .field-items {
    width: 100%; }
    .node-type-speech .node-session.view-mode-full .field-name-field-downloads .field-items .field-item,
    .node-type-speech .node-speech.view-mode-full .field-name-field-downloads .field-items .field-item,
    .node-type-session .node-session.view-mode-full .field-name-field-downloads .field-items .field-item,
    .node-type-session .node-speech.view-mode-full .field-name-field-downloads .field-items .field-item {
      display: inline-block;
      margin-right: 20px; }

.node-type-session .node-session.view-mode-full .group-general-info .field-name-field-speaker {
  display: block; }
  .node-type-session .node-session.view-mode-full .group-general-info .field-name-field-speaker a {
    transition: 0.3s;
    color: #0f75bc; }
    .node-type-session .node-session.view-mode-full .group-general-info .field-name-field-speaker a:visited {
      color: #0f75bc; }
    .node-type-session .node-session.view-mode-full .group-general-info .field-name-field-speaker a:hover {
      color: #1b8e5d;
      cursor: pointer; }
    .node-type-session .node-session.view-mode-full .group-general-info .field-name-field-speaker a:active {
      color: #0f75bc; }

.node-type-session .node-session.view-mode-full .field-label {
  padding-bottom: 0; }

.node-session.view-mode-full {
  margin-top: 80px;
  padding: 30px !important;
  background: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); }
  .node-session.view-mode-full .field-name-field-duration {
    margin-top: 60px; }
  .node-session.view-mode-full .field-name-field-videos .field-items {
    padding: 0 !important; }
  .node-session.view-mode-full .field-name-field-downloads .field-items {
    padding: 0 !important; }
  .node-session.view-mode-full .field-name-field-session-slots {
    position: relative;
    border-top: 1px solid rgba(35, 31, 32, 0.2);
    margin: 20px 0; }
    .node-session.view-mode-full .field-name-field-session-slots:after {
      content: "";
      height: 100%;
      width: 1px;
      background: #eaeaea;
      position: absolute;
      left: 20px;
      top: 0; }
    .node-session.view-mode-full .field-name-field-session-slots .field-name-field-start-time {
      font-family: "Averta-Bold";
      position: relative;
      z-index: 99;
      min-width: 120px; }
    .node-session.view-mode-full .field-name-field-session-slots .field-name-field-start-time {
      display: inline-block;
      vertical-align: top;
      padding: 10px 0; }

.node-type-speaker .node-title {
  display: none; }

.node-type-speaker .image-overlayed__image img {
  margin-top: -180px;
  margin-bottom: 40px;
  transform: none; }

.base-node-top-banner {
  background-image: url(/sites/all/themes/delfi/images/bg-purple-wide.jpg) !important;
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  position: relative;
  background-color: #523091; }

.node-title {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  max-width: 1080px;
  text-align: left;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto; }
  .node-title h1 {
    color: #fff;
    font-family: "Averta-Bold";
    font-size: 38px;
    line-height: 1.2;
    padding-bottom: 15px;
    text-align: left; }

.start-time,
.node-speakers {
  color: #fff;
  font-size: 22px;
  padding: 5px; }

.front .base-node-top-banner {
  height: 600px; }

.page-node.node-type-speech .main-content {
  padding-top: 0 !important; }

.pre-footer {
  background: #eee;
  padding: 20px 0; }
  .pre-footer .pre-footer-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px; }
    .pre-footer .pre-footer-inner #block-views-sponsors-block-1 {
      margin: 60px 0; }
    .pre-footer .pre-footer-inner .block__title {
      font-family: "Averta-Bold";
      font-size: 22px; }
    .pre-footer .pre-footer-inner .view-sponsors .row-container {
      padding: 30px 0;
      text-align: left; }
    .pre-footer .pre-footer-inner .view-sponsors .views-row {
      margin: 5px 3px;
      background: #fff;
      position: relative;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02); }
      .pre-footer .pre-footer-inner .view-sponsors .views-row:nth-child(6n+1) {
        margin-left: 0; }
      .pre-footer .pre-footer-inner .view-sponsors .views-row:nth-child(6n) {
        margin-right: 0; }
      .pre-footer .pre-footer-inner .view-sponsors .views-row .is-normal .field-name-field-gold-sponsor {
        display: none; }
      .pre-footer .pre-footer-inner .view-sponsors .views-row .is-gold .field-name-field-gold-sponsor {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        right: 0;
        text-align: center;
        background: gold;
        color: #0549a2;
        padding: 6px 0; }
        .pre-footer .pre-footer-inner .view-sponsors .views-row .is-gold .field-name-field-gold-sponsor .field-label {
          float: none; }

.footer-padding {
  padding: 0; }

.group-speaker-main-info {
  text-align: center; }

.page-checkout .accept_terms .description,
.page-cart .accept_terms .description,
.section-cart .accept_terms .description {
  display: inline-block;
  margin-left: 5px; }

.page-checkout .label-mobile,
.page-cart .label-mobile,
.section-cart .label-mobile {
  display: none; }

.page-checkout .main-content,
.page-cart .main-content,
.section-cart .main-content {
  max-width: 1150px;
  padding: 60px 0;
  padding: 60px 40px;
  margin: 0 auto; }
  .page-checkout .main-content .fieldset-wrapper,
  .page-checkout .main-content .view,
  .page-cart .main-content .fieldset-wrapper,
  .page-cart .main-content .view,
  .section-cart .main-content .fieldset-wrapper,
  .section-cart .main-content .view {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02);
    padding: 0 30px;
    background: #fff; }

.page-checkout form,
.page-cart form,
.section-cart form {
  margin: 0 -30px; }
  .page-checkout form table th,
  .page-cart form table th,
  .section-cart form table th {
    border-bottom: none;
    padding: 30px; }
  .page-checkout form table td,
  .page-cart form table td,
  .section-cart form table td {
    padding: 30px;
    background: #fff; }
    @media screen and (max-width: 750px) {
      .page-checkout form table td,
      .page-cart form table td,
      .section-cart form table td {
        padding: 15px 30px; }
        .page-checkout form table td:before,
        .page-cart form table td:before,
        .section-cart form table td:before {
          width: 190px;
          text-align: left; } }
  .page-checkout form table tbody .views-field-edit-delete,
  .page-cart form table tbody .views-field-edit-delete,
  .section-cart form table tbody .views-field-edit-delete {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center; }
  .page-checkout form table tbody .views-field-commerce-total,
  .page-cart form table tbody .views-field-commerce-total,
  .section-cart form table tbody .views-field-commerce-total {
    text-align: center;
    background: #f6f6f6;
    border-left: 1px solid rgba(0, 0, 0, 0.2); }
  .page-checkout form table input[type='text'],
  .page-cart form table input[type='text'],
  .section-cart form table input[type='text'] {
    text-align: center; }
    .page-checkout form table input[type='text']:focus,
    .page-cart form table input[type='text']:focus,
    .section-cart form table input[type='text']:focus {
      outline: none;
      border: 1px solid rgba(35, 31, 32, 0.5); }
  .page-checkout form table input[type='submit'],
  .page-cart form table input[type='submit'],
  .section-cart form table input[type='submit'] {
    background: #f15b55;
    color: #fff;
    border: #f15a54;
    padding: 5px 10px; }

.page-checkout .views-field-commerce-total,
.page-cart .views-field-commerce-total,
.section-cart .views-field-commerce-total {
  text-align: center; }

.page-checkout .form-actions,
.page-cart .form-actions,
.section-cart .form-actions {
  clear: right;
  padding: 20px; }
  .page-checkout .form-actions #edit-checkout,
  .page-cart .form-actions #edit-checkout,
  .section-cart .form-actions #edit-checkout {
    background: #0f75bc;
    color: #fff;
    border: 1px solid #004176;
    border-radius: 0;
    padding: 10px 25px;
    margin: 0 10px;
    background: #1ea069;
    border: 1px solid #1b8e5d; }
    .page-checkout .form-actions #edit-checkout:hover,
    .page-cart .form-actions #edit-checkout:hover,
    .section-cart .form-actions #edit-checkout:hover {
      background: #1ea069;
      border: 1px solid #1b8e5d; }
    .page-checkout .form-actions #edit-checkout:hover,
    .page-cart .form-actions #edit-checkout:hover,
    .section-cart .form-actions #edit-checkout:hover {
      background: #20a96f;
      cursor: pointer; }
  .page-checkout .form-actions #edit-submit,
  .page-cart .form-actions #edit-submit,
  .section-cart .form-actions #edit-submit {
    background: none;
    border: none;
    color: #0f75bc;
    font-size: 15px; }
    .page-checkout .form-actions #edit-submit:focus,
    .page-cart .form-actions #edit-submit:focus,
    .section-cart .form-actions #edit-submit:focus {
      outline: none; }

.page-checkout .line-item-summary,
.page-cart .line-item-summary,
.section-cart .line-item-summary {
  float: right;
  max-width: 260px;
  width: 100%;
  text-align: center;
  padding: 20px 0; }

.page-checkout fieldset,
.page-cart fieldset,
.section-cart fieldset {
  margin: 30px 0; }

.page-checkout select,
.page-cart select,
.section-cart select {
  border: 1px solid #aaa;
  color: #231f20;
  border-radius: 0; }

.page-checkout form {
  margin: 0; }

.page-checkout fieldset {
  margin: 30px 0;
  padding: 0;
  padding: 40px 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2); }

.page-checkout .fieldset-wrapper {
  padding: 30px; }

.page-checkout .main-content .fieldset-wrapper {
  padding: 0; }

.page-checkout .main-content .view {
  padding: 0;
  box-shadow: none; }

.page-checkout .field-name-commerce-order-total form table tbody {
  border-top: 0; }
  .page-checkout .field-name-commerce-order-total form table tbody tr {
    border: none; }

.page-checkout #edit-customer-profile-billing .outer-fieldset-wrapper {
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02);
  padding: 60px 0;
  margin-top: 30px; }

.page-checkout #edit-customer-profile-billing .fieldset-wrapper {
  box-shadow: none;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding: 0 20px; }

.page-checkout #edit-customer-profile-billing label {
  color: #231f20;
  font-family: "Averta-Regular";
  font-weight: 300;
  padding-bottom: 5px; }

.page-checkout #edit-customer-profile-billing select,
.page-checkout #edit-customer-profile-billing input {
  width: 100%;
  border: 1px solid #aaa;
  color: #231f20;
  border-radius: 0;
  height: 40px;
  padding: 8px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .page-checkout #edit-customer-profile-billing select:focus,
  .page-checkout #edit-customer-profile-billing input:focus {
    outline: none; }

.page-checkout #edit-customer-profile-billing .addressfield-container-inline > .form-item {
  float: none;
  width: 100%;
  margin-right: 0; }

.page-checkout .commerce-order-handler-area-order-total .commerce-price-formatted-components {
  border-collapse: separate; }

.page-checkout .checkout-buttons {
  border: none; }
  .page-checkout .checkout-buttons .fieldset-wrapper {
    background: none;
    box-shadow: none;
    text-align: center; }
    .page-checkout .checkout-buttons .fieldset-wrapper #edit-continue {
      background: #0f75bc;
      color: #fff;
      border: 1px solid #004176;
      border-radius: 0;
      padding: 10px 25px;
      margin-right: 5px; }
      .page-checkout .checkout-buttons .fieldset-wrapper #edit-continue:hover {
        background: #1ea069;
        border: 1px solid #1b8e5d; }

.page-checkout #payment-details {
  padding: 30px; }
  .page-checkout #payment-details p {
    margin: 0; }

.page-checkout #edit-commerce-payment-payment-method {
  padding: 30px;
  margin: 0;
  margin-top: 30px; }
  .page-checkout #edit-commerce-payment-payment-method .form-item {
    margin: 10px 0; }
  .page-checkout #edit-commerce-payment-payment-method .accepted-cards-payment {
    padding-left: 0; }
  .page-checkout #edit-commerce-payment-payment-method li {
    display: inline-block;
    margin-right: 5px; }
  .page-checkout #edit-commerce-payment-payment-method .description {
    margin-left: 0; }

.page-checkout .view-commerce-cart-summary .views-field-commerce-total {
  background: #fff; }

.page-checkout-review .ticket-profiles {
  padding: 20px 0; }

.page-checkout-review #edit-commerce-payment .fieldset-wrapper {
  padding: 30px;
  margin-top: 10px; }

.page-checkout-review form table th {
  background: #fff; }

.page-checkout-review table.checkout-review tr.pane-title {
  border-bottom: 0; }
  .page-checkout-review table.checkout-review tr.pane-title td {
    padding-bottom: 0; }

.page-checkout-review table.checkout-review tr:first-child {
  border-bottom: 1px solid rgba(35, 31, 32, 0.2); }
  .page-checkout-review table.checkout-review tr:first-child td {
    padding-bottom: 30px; }

.page-checkout-review table.checkout-review tr:nth-child(2) .pane-data-full {
  padding: 0; }

.page-checkout-review table.checkout-review tr:not(:nth-child(2)) .pane-data-full label,
.page-checkout-review table.checkout-review tr:not(:nth-child(2)) .pane-data-full .field-label {
  float: left;
  margin-right: 10px;
  font-weight: 300;
  text-decoration: underline; }

.page-checkout-review table .field-name-commerce-customer-address .field-item div {
  float: left;
  margin-right: 10px; }
  .page-checkout-review table .field-name-commerce-customer-address .field-item div:not(:last-child):after {
    content: ",";
    display: inline-block; }

.page-checkout-review .view-commerce-cart-summary table {
  margin-top: 0; }

.page-checkout-accommodation legend,
.page-checkout-tickets legend {
  font-weight: 600; }

.page-checkout-accommodation #edit-tickets,
.page-checkout-tickets #edit-tickets {
  border: none; }
  .page-checkout-accommodation #edit-tickets input,
  .page-checkout-tickets #edit-tickets input {
    height: 35px;
    margin: 5px 0;
    padding: 5px 10px;
    line-height: 35px; }
    .page-checkout-accommodation #edit-tickets input:focus,
    .page-checkout-tickets #edit-tickets input:focus {
      outline: none;
      border: 1px solid #1a1a1a; }

.page-checkout-accommodation .field-type-addressfield,
.page-checkout-accommodation .field-type-email,
.page-checkout-accommodation .field-name-field-customer-phone,
.page-checkout-accommodation .field-name-field-customer-profession,
.page-checkout-tickets .field-type-addressfield,
.page-checkout-tickets .field-type-email,
.page-checkout-tickets .field-name-field-customer-phone,
.page-checkout-tickets .field-name-field-customer-profession {
  max-width: 650px;
  width: 100%;
  margin: 0 auto; }

.page-checkout-accommodation .locality-block .form-item,
.page-checkout-tickets .locality-block .form-item {
  width: 45%;
  margin: 0; }
  .page-checkout-accommodation .locality-block .form-item:first-child,
  .page-checkout-tickets .locality-block .form-item:first-child {
    margin-right: 10%; }

.page-checkout-accommodation input,
.page-checkout-tickets input {
  width: 100%; }

.page-checkout-accommodation .street-block .form-item,
.page-checkout-accommodation .name-block .form-item,
.page-checkout-tickets .street-block .form-item,
.page-checkout-tickets .name-block .form-item {
  width: 100%; }

.page-checkout-accommodation .main-content .fieldset-wrapper,
.page-checkout-tickets .main-content .fieldset-wrapper {
  padding: 30px; }

.page-checkout-accommodation .main-content .fieldset-wrapper,
.page-checkout-tickets .main-content .fieldset-wrapper {
  background: none;
  box-shadow: none;
  padding: 0; }

.page-checkout-accommodation .main-content .customer_profile_accommodation .fieldset-wrapper,
.page-checkout-accommodation .main-content .esx-commerce-tickets--ticket .fieldset-wrapper,
.page-checkout-tickets .main-content .customer_profile_accommodation .fieldset-wrapper,
.page-checkout-tickets .main-content .esx-commerce-tickets--ticket .fieldset-wrapper {
  background: #fff;
  padding: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02); }

.page-checkout-accommodation .main-content .field-name-commerce-customer-address fieldset,
.page-checkout-tickets .main-content .field-name-commerce-customer-address fieldset {
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0; }
  .page-checkout-accommodation .main-content .field-name-commerce-customer-address fieldset .fieldset-wrapper,
  .page-checkout-tickets .main-content .field-name-commerce-customer-address fieldset .fieldset-wrapper {
    padding: 0;
    margin: 0;
    box-shadow: none; }

.page-checkout-accommodation fieldset,
.page-checkout-tickets fieldset {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.2); }

#commerce-checkout-form-complete {
  padding: 30px;
  background: #fff; }

.page-checkout-complete .main-content .checkout-completion-message {
  text-align: center; }
  .page-checkout-complete .main-content .checkout-completion-message a {
    transition: 0.3s;
    color: #0f75bc; }
    .page-checkout-complete .main-content .checkout-completion-message a:visited {
      color: #0f75bc; }
    .page-checkout-complete .main-content .checkout-completion-message a:hover {
      color: #1ea069;
      cursor: pointer; }
    .page-checkout-complete .main-content .checkout-completion-message a:active {
      color: #0f75bc; }

.page-user-orders .field-name-field-order-balance-check,
.page-user-orders .field-name-field-customer-ticket {
  padding: 30px; }

.page-user-orders .main-content {
  background-color: #f2f2f2;
  padding: 0; }
  .page-user-orders .main-content table th {
    border-bottom: none;
    padding: 30px;
    text-align: left; }
  .page-user-orders .main-content table tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  .page-user-orders .main-content table td {
    padding: 30px;
    background: #fff; }
  .page-user-orders .main-content table tbody .views-field-edit-delete {
    border-right: 1px solid rgba(0, 0, 0, 0.2); }
  .page-user-orders .main-content table tbody .views-field-commerce-total {
    text-align: center;
    background: #f6f6f6; }
  .page-user-orders .main-content table input[type='text'] {
    text-align: center; }
    .page-user-orders .main-content table input[type='text']:focus {
      outline: none;
      border: 1px solid rgba(35, 31, 32, 0.5); }
  .page-user-orders .main-content table input[type='submit'] {
    background: #f15b55;
    color: #fff;
    border: #f15a54;
    padding: 5px 10px; }

.page-user-orders .entity-commerce-order {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02);
  background: #fff;
  padding: 0 !important; }
  .page-user-orders .entity-commerce-order .field-name-commerce-customer-billing .field-label,
  .page-user-orders .entity-commerce-order .field-name-commerce-customer-billing .field-items {
    float: none;
    padding: 10px 0; }
  .page-user-orders .entity-commerce-order .field-name-commerce-order-total .commerce-price-formatted-components {
    border-collapse: separate; }
  .page-user-orders .entity-commerce-order .order-status-custom-field,
  .page-user-orders .entity-commerce-order .field-name-commerce-customer-billing {
    padding: 15px 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.2); }

.view-commerce-cart-summary table {
  margin-bottom: 0; }

.commerce-order-handler-area-order-total .commerce-price-formatted-components {
  width: 100%;
  padding-top: 0;
  margin-top: 0;
  text-align: left;
  background: #f6f6f6; }
  .commerce-order-handler-area-order-total .commerce-price-formatted-components .component-type-commerce-price-formatted-amount {
    background: #f6f6f6; }
  .commerce-order-handler-area-order-total .commerce-price-formatted-components .component-title {
    background: #f6f6f6;
    display: inline-block; }
  .commerce-order-handler-area-order-total .commerce-price-formatted-components .component-total {
    display: inline-block;
    background: #f6f6f6; }

.checkout-buttons {
  padding: 20px 0; }
  .checkout-buttons .fieldset-wrapper {
    width: 100%;
    max-width: 230px;
    margin: 0 auto; }
  .checkout-buttons .checkout-back {
    padding: 12px 6px;
    float: none;
    display: block;
    margin: 0 auto; }
    .checkout-buttons .checkout-back:focus {
      outline: none; }

.node-ticket {
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02);
  padding: 30px !important; }
  .node-ticket .group-left {
    height: 380px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 70% !important;
    background-color: #512f8e !important;
    margin: -30px;
    max-width: 350px;
    max-width: 40%;
    width: 100%;
    margin-right: 60px;
    margin-right: 5%; }
  .node-ticket .commerce-product-field {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-top: 20px; }
    .node-ticket .commerce-product-field .field-label {
      float: none;
      display: block; }
  .node-ticket .group-right {
    position: relative;
    margin: 30px;
    width: 50%;
    text-align: center; }
    .node-ticket .group-right label {
      margin: 20px 0; }
    .node-ticket .group-right p {
      font-size: 15px;
      margin: 0; }
    .node-ticket .group-right input,
    .node-ticket .group-right select {
      max-width: 55%; }
  .node-ticket .group-product-general-info > div span {
    font-weight: 600; }
  .node-ticket .group-product-general-info .field-label {
    display: none; }
  .node-ticket select,
  .node-ticket input {
    width: 100%;
    max-width: 315px;
    border: 1px solid #aaa;
    color: #231f20;
    border-radius: 0;
    height: 40px;
    padding: 8px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
    .node-ticket select:focus,
    .node-ticket input:focus {
      outline: none; }
  .node-ticket .form-submit {
    width: 315px;
    transition: 0.3s; }
    .node-ticket .form-submit:hover {
      background: #0f75bc;
      border: 1px solid #004176;
      color: #fff; }

.page-user .form-type-password,
.page-user-login .form-type-password,
.page-user-register .form-type-password,
.page-user-password .form-type-password {
  width: 100%; }

.page-user .main-content,
.page-user-login .main-content,
.page-user-register .main-content,
.page-user-password .main-content {
  max-width: 1080px;
  padding: 60px 0;
  padding: 60px 0px;
  margin: 0 auto;
  background-color: #f2f2f2; }
  .page-user .main-content .profile,
  .page-user-login .main-content .profile,
  .page-user-register .main-content .profile,
  .page-user-password .main-content .profile {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02);
    padding: 30px;
    background: #fff; }
    .page-user .main-content .profile a,
    .page-user-login .main-content .profile a,
    .page-user-register .main-content .profile a,
    .page-user-password .main-content .profile a {
      transition: 0.3s;
      color: #0f75bc; }
      .page-user .main-content .profile a:visited,
      .page-user-login .main-content .profile a:visited,
      .page-user-register .main-content .profile a:visited,
      .page-user-password .main-content .profile a:visited {
        color: #0f75bc; }
      .page-user .main-content .profile a:hover,
      .page-user-login .main-content .profile a:hover,
      .page-user-register .main-content .profile a:hover,
      .page-user-password .main-content .profile a:hover {
        color: #1b8e5d;
        cursor: pointer; }
      .page-user .main-content .profile a:active,
      .page-user-login .main-content .profile a:active,
      .page-user-register .main-content .profile a:active,
      .page-user-password .main-content .profile a:active {
        color: #0f75bc; }
    .page-user .main-content .profile h3,
    .page-user-login .main-content .profile h3,
    .page-user-register .main-content .profile h3,
    .page-user-password .main-content .profile h3 {
      padding-bottom: 10px;
      margin-bottom: 20px; }
  .page-user .main-content form,
  .page-user-login .main-content form,
  .page-user-register .main-content form,
  .page-user-password .main-content form {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02);
    padding: 30px;
    background: #fff; }
    .page-user .main-content form input[type='text'],
    .page-user .main-content form input[type='password'],
    .page-user-login .main-content form input[type='text'],
    .page-user-login .main-content form input[type='password'],
    .page-user-register .main-content form input[type='text'],
    .page-user-register .main-content form input[type='password'],
    .page-user-password .main-content form input[type='text'],
    .page-user-password .main-content form input[type='password'] {
      height: 45px;
      margin: 5px 0;
      padding: 5px 10px; }
      .page-user .main-content form input[type='text']:focus,
      .page-user .main-content form input[type='password']:focus,
      .page-user-login .main-content form input[type='text']:focus,
      .page-user-login .main-content form input[type='password']:focus,
      .page-user-register .main-content form input[type='text']:focus,
      .page-user-register .main-content form input[type='password']:focus,
      .page-user-password .main-content form input[type='text']:focus,
      .page-user-password .main-content form input[type='password']:focus {
        outline: none;
        border: 1px solid #1a1a1a; }
    .page-user .main-content form h1,
    .page-user-login .main-content form h1,
    .page-user-register .main-content form h1,
    .page-user-password .main-content form h1 {
      font-weight: 600;
      padding-bottom: 10px; }
    .page-user .main-content form:after,
    .page-user-login .main-content form:after,
    .page-user-register .main-content form:after,
    .page-user-password .main-content form:after {
      content: "";
      display: table;
      clear: both; }

.page-user .create-account,
.page-user-login .create-account,
.page-user-register .create-account,
.page-user-password .create-account {
  float: right;
  width: 50%;
  text-align: center; }
  .page-user .create-account h2,
  .page-user-login .create-account h2,
  .page-user-register .create-account h2,
  .page-user-password .create-account h2 {
    font-weight: 600;
    padding-bottom: 10px; }
  .page-user .create-account a,
  .page-user-login .create-account a,
  .page-user-register .create-account a,
  .page-user-password .create-account a {
    transition: 0.3s;
    color: #0f75bc; }
    .page-user .create-account a:visited,
    .page-user-login .create-account a:visited,
    .page-user-register .create-account a:visited,
    .page-user-password .create-account a:visited {
      color: #0f75bc; }
    .page-user .create-account a:hover,
    .page-user-login .create-account a:hover,
    .page-user-register .create-account a:hover,
    .page-user-password .create-account a:hover {
      color: #1b8e5d;
      cursor: pointer; }
    .page-user .create-account a:active,
    .page-user-login .create-account a:active,
    .page-user-register .create-account a:active,
    .page-user-password .create-account a:active {
      color: #0f75bc; }

.page-user #user-register-form a,
.page-user #user-pass a,
.page-user-login #user-register-form a,
.page-user-login #user-pass a,
.page-user-register #user-register-form a,
.page-user-register #user-pass a,
.page-user-password #user-register-form a,
.page-user-password #user-pass a {
  transition: 0.3s;
  color: #0f75bc; }
  .page-user #user-register-form a:visited,
  .page-user #user-pass a:visited,
  .page-user-login #user-register-form a:visited,
  .page-user-login #user-pass a:visited,
  .page-user-register #user-register-form a:visited,
  .page-user-register #user-pass a:visited,
  .page-user-password #user-register-form a:visited,
  .page-user-password #user-pass a:visited {
    color: #0f75bc; }
  .page-user #user-register-form a:hover,
  .page-user #user-pass a:hover,
  .page-user-login #user-register-form a:hover,
  .page-user-login #user-pass a:hover,
  .page-user-register #user-register-form a:hover,
  .page-user-register #user-pass a:hover,
  .page-user-password #user-register-form a:hover,
  .page-user-password #user-pass a:hover {
    color: #1b8e5d;
    cursor: pointer; }
  .page-user #user-register-form a:active,
  .page-user #user-pass a:active,
  .page-user-login #user-register-form a:active,
  .page-user-login #user-pass a:active,
  .page-user-register #user-register-form a:active,
  .page-user-register #user-pass a:active,
  .page-user-password #user-register-form a:active,
  .page-user-password #user-pass a:active {
    color: #0f75bc; }

.page-user #user-register-form .form-submit,
.page-user #user-pass .form-submit,
.page-user-login #user-register-form .form-submit,
.page-user-login #user-pass .form-submit,
.page-user-register #user-register-form .form-submit,
.page-user-register #user-pass .form-submit,
.page-user-password #user-register-form .form-submit,
.page-user-password #user-pass .form-submit {
  background: #0f75bc;
  color: #fff;
  border: 1px solid #004176;
  border-radius: 0;
  padding: 10px 25px;
  margin: 20px 0;
  padding: 8px 35px; }
  .page-user #user-register-form .form-submit:hover,
  .page-user #user-pass .form-submit:hover,
  .page-user-login #user-register-form .form-submit:hover,
  .page-user-login #user-pass .form-submit:hover,
  .page-user-register #user-register-form .form-submit:hover,
  .page-user-register #user-pass .form-submit:hover,
  .page-user-password #user-register-form .form-submit:hover,
  .page-user-password #user-pass .form-submit:hover {
    background: #1ea069;
    border: 1px solid #1b8e5d; }

.page-user #user_login_form,
.page-user-login #user_login_form,
.page-user-register #user_login_form,
.page-user-password #user_login_form {
  float: left;
  width: 50%;
  padding-right: 30px;
  border-right: 1px solid rgba(35, 31, 32, 0.2);
  margin: -30px;
  padding: 30px;
  background: #f6f6f6; }
  .page-user #user_login_form a,
  .page-user-login #user_login_form a,
  .page-user-register #user_login_form a,
  .page-user-password #user_login_form a {
    transition: 0.3s;
    color: #0f75bc; }
    .page-user #user_login_form a:visited,
    .page-user-login #user_login_form a:visited,
    .page-user-register #user_login_form a:visited,
    .page-user-password #user_login_form a:visited {
      color: #0f75bc; }
    .page-user #user_login_form a:hover,
    .page-user-login #user_login_form a:hover,
    .page-user-register #user_login_form a:hover,
    .page-user-password #user_login_form a:hover {
      color: #1b8e5d;
      cursor: pointer; }
    .page-user #user_login_form a:active,
    .page-user-login #user_login_form a:active,
    .page-user-register #user_login_form a:active,
    .page-user-password #user_login_form a:active {
      color: #0f75bc; }
  .page-user #user_login_form .form-submit,
  .page-user-login #user_login_form .form-submit,
  .page-user-register #user_login_form .form-submit,
  .page-user-password #user_login_form .form-submit {
    width: 100%;
    background: #0f75bc;
    color: #fff;
    border: 1px solid #004176;
    border-radius: 0;
    padding: 10px 25px;
    margin: 20px 0;
    padding: 8px 35px; }
    .page-user #user_login_form .form-submit:hover,
    .page-user-login #user_login_form .form-submit:hover,
    .page-user-register #user_login_form .form-submit:hover,
    .page-user-password #user_login_form .form-submit:hover {
      background: #1ea069;
      border: 1px solid #1b8e5d; }

.read-more {
  display: inline-block;
  transition: .3s;
  font-size: 15px;
  vertical-align: middle;
  margin-top: 20px; }
  .read-more a {
    transition: 0.3s;
    color: #231f20; }
    .read-more a:visited {
      color: #231f20; }
    .read-more a:hover {
      color: #0f75bc;
      cursor: pointer; }
    .read-more a:active {
      color: #231f20; }

.group-teaser-attachments {
  display: block;
  width: 100%;
  width: calc(100% - 140px); }

.video-link,
.teaser-video {
  display: block;
  margin-bottom: 10px; }
  .video-link.session,
  .teaser-video.session {
    margin-bottom: 0;
    margin-top: 20px;
    display: inline-block;
    vertical-align: middle; }
  .video-link a,
  .teaser-video a {
    text-indent: -99999px;
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url(../images/icons/youtube.svg);
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02); }

.session-downloads,
.speech-downloads {
  display: inline-block;
  vertical-align: middle; }
  .session-downloads img,
  .speech-downloads img {
    display: none; }
  .session-downloads.full a,
  .speech-downloads.full a {
    padding: 30px; }
  .session-downloads .field-item,
  .speech-downloads .field-item {
    display: inline-block; }
  .session-downloads a,
  .speech-downloads a {
    width: 40px;
    height: 40px;
    display: block;
    text-indent: -99999px;
    margin-right: 10px;
    margin-top: 10px;
    transition: .4s; }
    .session-downloads a:hover,
    .speech-downloads a:hover {
      cursor: pointer; }
  .session-downloads a[type*="application/pdf"],
  .speech-downloads a[type*="application/pdf"] {
    background: url(../images/icons/pdf.svg) no-repeat; }
  .session-downloads a[type*="application/ppt"],
  .speech-downloads a[type*="application/ppt"] {
    background: url(../images/icons/ppt.svg) no-repeat; }
  .session-downloads a[type*="application/pptx"],
  .speech-downloads a[type*="application/pptx"] {
    background: url(../images/icons/ppt.svg) no-repeat; }
  .session-downloads a[type*='application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  .speech-downloads a[type*='application/vnd.openxmlformats-officedocument.wordprocessingml.document'] {
    background: url(../images/icons/doc.svg) no-repeat; }
  .session-downloads a[type*='application/msword'],
  .speech-downloads a[type*='application/msword'] {
    background: url(../images/icons/doc.svg) no-repeat; }
  .session-downloads a[type*='application/vnd.ms-excel'],
  .speech-downloads a[type*='application/vnd.ms-excel'] {
    background: url(../images/icons/xls.svg) no-repeat; }

.session-downloads {
  margin-top: 20px;
  margin-left: 20px; }

.main-navigation .menu {
  max-width: 1080px;
  margin: 0 auto; }

.page-user-orders .main-content .view-commerce-user-orders {
  background: #fff;
  padding: 30px; }
  .page-user-orders .main-content .view-commerce-user-orders table a {
    transition: 0.3s;
    color: #231f20; }
    .page-user-orders .main-content .view-commerce-user-orders table a:visited {
      color: #231f20; }
    .page-user-orders .main-content .view-commerce-user-orders table a:hover {
      color: #0f75bc;
      cursor: pointer; }
    .page-user-orders .main-content .view-commerce-user-orders table a:active {
      color: #231f20; }
  .page-user-orders .main-content .view-commerce-user-orders table th {
    padding-top: 0; }

.node-special-page {
  background: #fff;
  padding: 30px 60px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.02); }
  .node-special-page a {
    transition: 0.3s;
    color: #0f75bc; }
    .node-special-page a:visited {
      color: #0f75bc; }
    .node-special-page a:hover {
      color: #1b8e5d;
      cursor: pointer; }
    .node-special-page a:active {
      color: #0f75bc; }
  .node-special-page .field-name-field-account-image {
    display: inline-block;
    width: 70px;
    margin-left: 20px; }
  .node-special-page .student,
  .node-special-page .speaker {
    max-width: 340px;
    max-width: 55%;
    width: 100%;
    display: inline-block;
    padding-left: 40px; }
  .node-special-page .score {
    font-weight: 600; }
  .node-special-page .esx-top-speakers li {
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative; }
  .node-special-page .esx-top-speakers .top-speakers-image .image-overlayed__image {
    display: inline-block;
    margin-left: 15px; }
    .node-special-page .esx-top-speakers .top-speakers-image .image-overlayed__image img {
      border: none;
      border-radius: 0;
      width: 70px;
      transform: none; }
  .node-special-page .esx-top-students li {
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative; }
    .node-special-page .esx-top-students li:nth-child(1):after, .node-special-page .esx-top-students li:nth-child(2):after, .node-special-page .esx-top-students li:nth-child(3):after {
      content: "";
      background: url(https://www.andrologyupdate.gr/sites/all/themes/delfi/images/icons/trophy.svg);
      width: 45px;
      height: 45px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 20px;
      bottom: 0; }

.page-node.node-type-session .main-content {
  padding-top: 0 !important; }

.sidr {
  background: #231f20; }
  .sidr .sidr-inner {
    padding-bottom: 0; }
  .sidr ul {
    padding: 10px 0px; }
  .sidr .sidr-class-block__title,
  .sidr .sidr-class-contextual-links {
    display: none; }
  .sidr ul {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.page-node-1712 .form .form-item {
  width: 100%;
  text-align: center; }

.page-user .messages-status {
  position: relative;
  z-index: 99999999; }

.page-user .node-title {
  display: none; }

.page-user .profile .field-name-field-account-bio .field-label {
  padding: 20px 0; }

.page-user .profile .field-name-field-account-lastname,
.page-user .profile .field-name-field-account-firstname {
  display: inline-block;
  margin-right: 5px; }

.page-user .profile .field-name-field-account-image img {
  margin-top: -180px;
  margin-bottom: 40px;
  border: 7px solid #f2f2f2;
  position: relative; }

.page-user .profile dl,
.page-user .profile h3 {
  display: none; }

.webform-client-form-1712 label {
  line-height: 30px; }

#webform-notification {
  text-align: center;
  font-size: 14px;
  line-height: 20px; }

.eval-link {
  font-size: 14px;
  margin-left: 5px; }

.node-speech.node-teaser .field-name-field-speaker {
  margin-bottom: 20px; }

body[class*=" page-user-orders-"] .field-name-commerce-order-total .commerce-price-formatted-components {
  margin-left: 0; }

body[class*=" page-user-orders-"] .field-type-addressfield .field-item div {
  display: inline; }

body[class*=" page-user-orders-"] .field-name-field-related-users {
  padding: 0 30px 30px 30px; }

.form .form-required {
  color: red; }

.i18n-en .imop-logo {
  top: 47px; }

.footer__wrapper .accepted-cards-payment {
  padding-left: 0; }
  .footer__wrapper .accepted-cards-payment li {
    display: inline-block; }

.page-node-2 .node-webform .form__text {
  max-width: 100%; }

.page-contact .form .form_text {
  max-width: 100%; }

.imop-logo {
  display: inline-block;
  position: relative;
  z-index: 99;
  max-width: 90px;
  top: 27px; }

.header__logo {
  display: inline-block;
  max-width: 150px;
  padding-top: 63px; }

.main-navigation .menu li {
  padding: 0 8px 0 0; }

.page-agenda .node-session.no-title-link .group-toggle-area.open {
  background: #fff; }

.sun-pr:hover {
  cursor: pointer; }

.sun-pr-container {
  text-align: center;
  background: #fff;
  padding: 30px;
  margin: 80px 0; }

.page-sponsors .view-sponsors .views-row {
  position: relative; }
  .page-sponsors .view-sponsors .views-row a {
    position: absolute;
    text-indent: -99999999px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

.pane-page-messages {
  margin: 0 auto;
  max-width: 1090px;
  background: none; }

#backtotop {
  background: none !important; }
  #backtotop:after {
    background: url(../images/totop.png);
    background-size: contain; }

.form-type-password-confirm {
  overflow: scroll; }

#user-register-form .field-name-field-account-mobile input {
  padding-left: 60px;
  max-width: 300px;
  width: 100%; }

#block-views-food-ticket-block {
  padding: 0 !important;
  margin-top: 20px; }

.node-ticket .node-food-ticket .commerce-product-field {
  width: 50%; }

.node-ticket .node-food-ticket .group-left {
  height: 495px;
  background: url(../images/coffee.jpg) !important;
  background-size: cover !important;
  background-color: none; }

#sliding-popup .popup-content {
  display: block;
  padding: 18px 0; }
  #sliding-popup .popup-content:after {
    content: "";
    display: table;
    clear: both; }
  #sliding-popup .popup-content #popup-text {
    margin: 0;
    max-width: 57%;
    margin-right: 3%; }
    #sliding-popup .popup-content #popup-text p {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0; }
  #sliding-popup .popup-content #popup-buttons {
    margin: 13px 0 0; }
    #sliding-popup .popup-content #popup-buttons button {
      border-radius: 0;
      border: none;
      background: #fff;
      color: #000000 !important;
      padding: 10px 30px;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      margin-top: 0;
      box-shadow: none;
      text-shadow: none;
      transition: all 0.7s;
      margin-bottom: 5px; }
      #sliding-popup .popup-content #popup-buttons button:hover {
        background: #000000;
        color: #fff !important; }
    #sliding-popup .popup-content #popup-buttons .agree-button {
      margin-right: 10px; }

.eu-cookie-compliance-more-button {
  font-size: 16px; }

@media screen and (max-width: 800px) {
  #sliding-popup .popup-content {
    padding: 13px 0; }
    #sliding-popup .popup-content #popup-buttons {
      float: none;
      max-width: 100%;
      margin-top: 15px; }
      #sliding-popup .popup-content #popup-buttons button {
        padding: 7px 20px; }
    #sliding-popup .popup-content #popup-text {
      float: none;
      max-width: 100%; } }

@media screen and (max-width: 1350px) {
  .base-node-top-banner {
    background-position-y: 0; } }

@media screen and (max-width: 1100px) {
  .event-top-banner {
    right: 10px; }
  .panel-2col-stacked .panel-col-top .inside {
    padding: 0 15px; }
  .pre-footer .pre-footer-inner .view-sponsors .row-container {
    text-align: center; }
    .pre-footer .pre-footer-inner .view-sponsors .row-container .views-row {
      margin: 5px 3px; }
      .pre-footer .pre-footer-inner .view-sponsors .row-container .views-row:nth-child(6n+1) {
        margin: 5px 3px; }
      .pre-footer .pre-footer-inner .view-sponsors .row-container .views-row:nth-child(6n) {
        margin: 5px 3px; }
  .view-program .view-content {
    margin: 0 -15px; }
  .view-program .node-day-s-full-program > .field-name-title-field {
    padding-left: 15px; } }

@media screen and (max-width: 1090px) {
  .logo-wrapper {
    text-align: center; }
  .header__logo {
    padding-top: 0; }
  .block-menu {
    display: none; }
  #sidr-0-button {
    padding-top: 70px; }
  .header__logo {
    padding-right: 0; }
    .header__logo img {
      margin-top: 50px;
      width: 100%; } }

@media screen and (max-width: 890px) {
  .front .event-top-banner {
    font-size: 17px;
    bottom: 46px; }
  .node-title h1 {
    font-size: 30px; }
  .event-top-banner {
    bottom: -102px; } }

@media screen and (max-width: 850px) {
  #block-views-sponsors-block-1 {
    text-align: center; }
  .l-four-column-asymetric__column--first {
    width: 100%; }
    .l-four-column-asymetric__column--first .footer-item {
      margin: 0 auto;
      text-align: center; }
      .l-four-column-asymetric__column--first .footer-item .footer-item__fixed-width {
        margin: 0 auto; }
  .l-four-column-asymetric__column--fourth,
  .l-four-column-asymetric__column--third {
    float: left;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    display: block;
    clear: left; }
    .l-four-column-asymetric__column--fourth .footer-item,
    .l-four-column-asymetric__column--third .footer-item {
      text-align: center;
      max-width: 100%;
      padding-top: 20px;
      padding-bottom: 20px; }
      .l-four-column-asymetric__column--fourth .footer-item .footer-item__link-social,
      .l-four-column-asymetric__column--third .footer-item .footer-item__link-social {
        padding: 0 10px; }
  .l-four-column-asymetric--bottom {
    text-align: center; } }

@media screen and (max-width: 790px) {
  .page-checkout .main-content,
  .page-cart .main-content,
  .section-cart .main-content {
    padding: 40px 20px; }
  #block-views-food-ticket-block,
  .node-ticket {
    margin-top: 60px;
    padding: 0 !important;
    max-width: 85% !important; }
    #block-views-food-ticket-block .group-left,
    .node-ticket .group-left {
      height: 180px;
      margin: 0;
      max-width: 100%;
      width: 100%;
      margin: 0; }
    #block-views-food-ticket-block .group-right,
    .node-ticket .group-right {
      margin: 0;
      position: relative;
      float: left;
      width: 100%;
      padding: 30px;
      text-align: center; }
      #block-views-food-ticket-block .group-right input,
      #block-views-food-ticket-block .group-right select,
      .node-ticket .group-right input,
      .node-ticket .group-right select {
        max-width: 315px; }
      #block-views-food-ticket-block .group-right .form-submit,
      .node-ticket .group-right .form-submit {
        max-width: 315px;
        width: 100%;
        position: relative;
        right: auto;
        margin-top: 0px;
        line-height: 10px; }
      #block-views-food-ticket-block .group-right .group-product-general-info,
      .node-ticket .group-right .group-product-general-info {
        position: relative;
        right: auto;
        width: auto;
        top: auto; }
      #block-views-food-ticket-block .group-right:after,
      .node-ticket .group-right:after {
        content: none; }
    #block-views-food-ticket-block .commerce-product-field,
    .node-ticket .commerce-product-field {
      display: inline-block;
      margin-right: 15px; }
      #block-views-food-ticket-block .commerce-product-field .field-items,
      .node-ticket .commerce-product-field .field-items {
        float: none; }
  #block-views-food-ticket-block .node-ticket {
    max-width: 100% !important; } }

@media screen and (max-width: 780px) {
  .front .link-tile {
    height: 300px;
    background-size: 130px !important;
    background-position: center 50px !important; }
  .front .link-title__link-to-content {
    height: 200px;
    padding-top: 220px; } }

@media screen and (max-width: 650px) {
  .front .event-top-banner {
    font-size: 17px;
    left: auto;
    bottom: 70px; }
  .page-agenda .node-session .group-inner-info .field-name-title-field {
    width: calc(100% - 35px); }
  .page-agenda .node-session .group-toggle-area .field-name-field-duration {
    display: block;
    float: none; }
  .page-user-orders .main-content table td {
    padding: 10px 30px; }
    .page-user-orders .main-content table td:before {
      width: auto; }
  .password-strength {
    float: none; }
  .page-checkout .views-field-edit-delete:before,
  .page-cart .views-field-edit-delete:before,
  .section-cart .views-field-edit-delete:before {
    content: none; }
  .page-checkout form table td,
  .page-cart form table td,
  .section-cart form table td {
    border: none !important; }
    .page-checkout form table td:before,
    .page-cart form table td:before,
    .section-cart form table td:before {
      width: 150px; }
    .page-checkout form table td.component-title,
    .page-cart form table td.component-title,
    .section-cart form table td.component-title {
      padding-bottom: 0; }
  .page-checkout form table tbody .views-field-commerce-total,
  .page-cart form table tbody .views-field-commerce-total,
  .section-cart form table tbody .views-field-commerce-total {
    text-align: left; }
  .page-checkout .line-item-summary,
  .page-cart .line-item-summary,
  .section-cart .line-item-summary {
    max-width: 100%; }
  .node-speech.node-teaser {
    padding: 20px 0; }
  .node-type-speech .node-session.view-mode-full,
  .node-type-speech .node-speech.view-mode-full,
  .node-type-session .node-session.view-mode-full,
  .node-type-session .node-speech.view-mode-full {
    padding: 20px !important; }
  .page-node:not(.node-type-speaker):not(.node-type-press-article) .main-content {
    padding-top: 0;
    padding-bottom: 0; }
    .page-node:not(.node-type-speaker):not(.node-type-press-article) .main-content > article {
      margin-top: 0; }
  .event-top-banner {
    right: 0;
    left: 0;
    bottom: -80px;
    border-radius: 0;
    padding: 10px 0; }
    .event-top-banner p {
      display: block;
      line-height: 10px;
      font-size: 14px; }
      .event-top-banner p.event-title {
        border-bottom: 0; }
  .node-type-webform #webform-client-form-1693 fieldset {
    padding: 0; }
  .node-type-webform #webform-client-form-1693 .form-item {
    padding: 30px 0; }
    .node-type-webform #webform-client-form-1693 .form-item .form-item {
      width: 50%;
      padding: 10px 0; }
  .node-speech.node-teaser .slot-secondary-info {
    padding: 0; }
  .page-agenda .node-session .field-name-field-session-slots:after {
    display: none; }
  .node-session.view-mode-full .field-name-field-session-slots:after {
    display: none; } }

@media screen and (max-width: 600px) {
  .form .form-item {
    width: 100%; } }

@media screen and (max-width: 575px) {
  .page-checkout-tickets .locality-block .form-item {
    width: 100%; }
  #sidr-0-button {
    padding-left: 20px; }
  .base-node-top-banner {
    height: 300px; }
  .node-title h1 {
    text-align: center; }
  .node-type-speech .node-title,
  .node-type-session .node-title {
    text-align: center;
    bottom: 30px;
    padding: 0 5px; }
    .node-type-speech .node-title h1,
    .node-type-session .node-title h1 {
      font-size: 20px;
      text-align: center; }
    .node-type-speech .node-title .start-time,
    .node-type-speech .node-title .node-speakers,
    .node-type-session .node-title .start-time,
    .node-type-session .node-title .node-speakers {
      font-size: 16px; }
  .unified-login-register--user-login #user-login-form {
    width: 100%; }
  .page-user .create-account, .page-user-login .create-account, .page-user-register .create-account, .page-user-password .create-account {
    width: 100%;
    float: none;
    clear: left;
    margin-top: 40px;
    text-align: left; } }

@media screen and (max-width: 500px) {
  .logo-wrapper {
    text-align: right; }
  .not-front .link-button {
    min-width: 280px;
    margin: 5px 0; }
  .node-special-page {
    padding: 30px !important; }
    .node-special-page ol {
      padding-left: 0; }
    .node-special-page .student,
    .node-special-page .speaker {
      padding-left: 10px; }
    .node-special-page li:nth-child(1):after, .node-special-page li:nth-child(2):after, .node-special-page li:nth-child(3):after {
      content: "";
      right: 0px; }
  .node-speech.node-teaser .group-general-info {
    width: 100%; }
  .node-speech.node-teaser .group-teaser-attachments {
    float: none;
    margin-left: 0;
    margin-right: 0;
    border-left: 0;
    padding-left: 0;
    padding-top: 20px;
    width: 100%;
    clear: left;
    max-width: 100%; }
    .node-speech.node-teaser .group-teaser-attachments .teaser-video,
    .node-speech.node-teaser .group-teaser-attachments .speech-downloads {
      display: block;
      vertical-align: top;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 0;
      margin-right: 10px; }
  .page-user-register input[type="file"] {
    font-size: 14px; }
  .page-user-register #addressfield-wrapper {
    overflow: hidden; }
    .page-user-register #addressfield-wrapper fieldset {
      margin-left: 0;
      padding: 0;
      border: none; } }

@media screen and (max-width: 400px) {
  .node-title h1 {
    font-size: 34px;
    text-align: center; }
  .editable-page__title,
  .editable-page__text {
    padding: 10px !important; }
  .front .link-tile__title {
    font-size: 22px; }
  .front .panel-2col-stacked .panel-col-top .inside,
  .front .center-wrapper {
    padding: 0; } }

.node-uro-olympics {
  padding: 30px !important;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  position: relative; }

.node-type-speaker .panel-panel .contextual-links-region {
  display: none; }

.view-esx-hotel-products-table {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 10px;
  margin-top: 20px;
  margin: 0 -30px;
  margin-top: 20px;
  background: #fafafa;
  border: none;
  padding: 60px 30px;
  margin-bottom: -30px; }
  .view-esx-hotel-products-table .views-table {
    margin: 0; }
  .view-esx-hotel-products-table thead {
    text-align: left; }
  .view-esx-hotel-products-table th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding-bottom: 20px;
    font-family: "Averta-Bold"; }
  .view-esx-hotel-products-table td {
    padding-top: 20px;
    max-width: 250px;
    padding-right: 20px; }
    @media screen and (max-width: 750px) {
      .view-esx-hotel-products-table td {
        max-width: 100%;
        padding-right: 0; } }
  .view-esx-hotel-products-table #edit-line-item-fields {
    display: inline-block; }

.field-name-field-hotel-image img {
  width: 100%; }

.node-type-hotel .pane__title {
  display: none; }

.node-type-hotel .panel-display {
  padding: 30px !important;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  position: relative; }

.node-type-hotel .field-label {
  font-family: "Averta-Bold"; }

.node-type-hotel .thoroughfare {
  float: left;
  margin-right: 8px; }
  .node-type-hotel .thoroughfare:after {
    content: ":";
    display: inline-block; }

.node-type-hotel .field-name-field-hotel-facilities ul {
  padding-left: 0; }

.node-type-hotel .field-name-field-hotel-facilities li {
  list-style-type: none;
  display: inline-block;
  width: 33%;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 12px; }
  .node-type-hotel .field-name-field-hotel-facilities li:before {
    content: "✔";
    color: green;
    padding-right: 10px; }

.node-type-hotel input[type="submit"] {
  border: 1px solid #aaa;
  color: #231f20;
  border-radius: 0;
  height: 40px;
  padding: 8px 10px;
  -webkit-appearance: none;
  width: auto;
  margin: 0 auto;
  display: block;
  font-size: 15px;
  float: right; }
  .node-type-hotel input[type="submit"]:hover {
    cursor: pointer;
    background: #0f75bc;
    border: 1px solid #004176;
    color: #fff; }

.node-type-hotel .in-stock .form-wrapper {
  display: inline-block; }
  @media screen and (max-width: 750px) {
    .node-type-hotel .in-stock .form-wrapper {
      display: block; } }

.node-type-hotel input[disabled="disabled"] {
  background: #fff;
  border: 1px solid #efefef;
  color: #d2d2d2; }
  .node-type-hotel input[disabled="disabled"]:hover {
    cursor: default;
    background: #fff;
    border: 1px solid #efefef;
    color: #d2d2d2; }

@media screen and (max-width: 750px) {
  .node-type-hotel table.bt tfoot th::before, .node-type-hotel table.bt tfoot td::before, .node-type-hotel table.bt tbody td::before {
    width: 190px; } }

.field-name-field-accommodation-days label {
  display: inline-block;
  vertical-align: middle;
  display: none; }

.view-facilities-hotels {
  display: table;
  clear: both;
  width: 100%; }
  @media screen and (max-width: 550px) {
    .view-facilities-hotels .view-content {
      text-align: center; }
      .view-facilities-hotels .view-content:after {
        content: "";
        display: table;
        clear: both; } }
  .view-facilities-hotels .l-two-column--column {
    width: 32.18182%;
    width: 32%;
    margin-right: 22px;
    margin-bottom: 30px;
    background: white; }
    .view-facilities-hotels .l-two-column--column:nth-child(3n) {
      margin-right: 0; }
    @media screen and (max-width: 550px) {
      .view-facilities-hotels .l-two-column--column {
        width: 70% !important;
        margin-right: 0 !important;
        display: inline-block;
        float: none; } }
    @media screen and (max-width: 350px) {
      .view-facilities-hotels .l-two-column--column {
        width: 90% !important; } }
    @media screen and (max-width: 1100px) {
      .view-facilities-hotels .l-two-column--column {
        width: 45%;
        margin-bottom: 70px; }
        .view-facilities-hotels .l-two-column--column:nth-child(2n) {
          margin-right: 0; }
        .view-facilities-hotels .l-two-column--column:nth-child(3n) {
          margin-right: 22px;
          clear: none; }
        .view-facilities-hotels .l-two-column--column:nth-child(2n+1) {
          clear: left; } }
  .view-facilities-hotels .field-name-field-hotel-image {
    margin: 0 -15px;
    margin-top: -17px;
    margin-bottom: 20px; }
  .view-facilities-hotels .links {
    margin: 0 -15px;
    margin-bottom: -17px;
    padding: 15px;
    background: #fafafa;
    font-size: 15px; }
    .view-facilities-hotels .links a {
      transition: 0.3s;
      color: #0f75bc; }
      .view-facilities-hotels .links a:visited {
        color: #0f75bc; }
      .view-facilities-hotels .links a:hover {
        color: #0f75bc;
        cursor: pointer; }
      .view-facilities-hotels .links a:active {
        color: #0f75bc; }
  .view-facilities-hotels .field-name-body {
    min-height: 120px; }

.hotel-title {
  font-size: 14px;
  color: grey; }

.section-speakers .messages--error {
  display: none; }

.section-hotels .view-header {
  padding: 30px;
  background: white;
  margin: -15px;
  margin-bottom: 50px; }

.urolympics-action {
  text-align: center;
  margin-bottom: 0; }
  .urolympics-action a {
    background: #1ea03a;
    color: white;
    display: inline-block;
    margin: 0 auto;
    padding: 12px 30px;
    border-radius: 20px; }

.group-hotel-image-wrapper {
  position: relative; }
  .group-hotel-image-wrapper .field-name-field-hotel-website {
    position: absolute;
    bottom: 7px;
    background: linear-gradient(to left, #2d0b6b, transparent);
    left: 0;
    right: 0;
    padding: 8px;
    text-align: right; }
    .group-hotel-image-wrapper .field-name-field-hotel-website a {
      color: white; }

.node-type-speaker .panel-display .pane-node {
  text-indent: -99999px; }
  .node-type-speaker .panel-display .pane-node.main-bg {
    text-indent: 0; }

.component-type-commerce-price-formatted-amount,
.component-type-base-price,
.component-type-nights {
  background: #f6f6f6 !important; }
  .component-type-commerce-price-formatted-amount .component-total,
  .component-type-commerce-price-formatted-amount .component-title,
  .component-type-base-price .component-total,
  .component-type-base-price .component-title,
  .component-type-nights .component-total,
  .component-type-nights .component-title {
    width: 50%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  .component-type-commerce-price-formatted-amount .component-total,
  .component-type-base-price .component-total,
  .component-type-nights .component-total {
    text-align: right;
    padding-right: 67px; }

.view-commerce-user-orders td a {
  padding: 10px 20px;
  border-radius: 20px;
  background: #0f75bc;
  border: 1px solid #004176;
  color: #fff !important; }

.olympics .l-two-column-thirds__left {
  height: 285px; }

.i18n-en .link-button--dl {
  display: none; }

.day-button:last-child {
  display: none; }

a[href="#node-1876"] {
  display: none; }

.not-logged-in #node-1876 {
  display: none; }

.eval-results {
  margin-top: 40px; }
  .eval-results:after {
    content: "";
    display: table;
    clear: both; }

#block-system-main-menu .leaf.last a {
  color: red !important; }
