$break-400: 400px;
$break-500: 500px;
$break-575: 575px;
$break-600: 600px;
$break-650: 650px;
$break-780: 780px;
$break-790: 790px;
$break-850: 850px;
$break-890: 890px;
$break-1090: 1090px;
$break-1100: 1100px;
$break-1350: 1350px;

@media screen and (max-width: $break-1350) {
	.base-node-top-banner {
		background-position-y: 0;
	}
}

@media screen and (max-width: $break-1100) {

	.event-top-banner {
		right: 10px;
		//bottom: -105px;
	}

	.panel-2col-stacked {
		.panel-col-top {
			.inside {
				padding: 0 15px;
			}
		}
	}

	.pre-footer {
		.pre-footer-inner {
			.view-sponsors {
				.row-container {
					text-align: center;
					.views-row {
		        margin: 5px 3px;
		        &:nth-child(6n+1) {
		          margin: 5px 3px;
		        }
		        &:nth-child(6n) {
		          margin: 5px 3px;
		        }
		      }
				}
			}
		}
	}

	//program view page
	.view-program {
		.view-content {
			margin: 0 -15px;
		}
		.node-day-s-full-program >.field-name-title-field {
			padding-left: 15px;
		}
	}


}

@media screen and (max-width: $break-1090) {
	
	.logo-wrapper {
		text-align: center;
	}

	.header__logo {
		padding-top: 0;
	}

	.block-menu {
		display: none;
	}
	#sidr-0-button {
		padding-top: 70px;
	}
	.header__logo {
		padding-right: 0;
		img {
			margin-top: 50px;
			width: 100%;
		}
	}
}


@media screen and (max-width: $break-890) {


.front {
  .event-top-banner {
 	font-size: 17px;
 	bottom: 46px;
  }
}

	.node-title {
		h1 {
			font-size: 30px;
		}
	}
	.event-top-banner {
		bottom: -102px;
	}
}

@media screen and (max-width: $break-850) {

	//sponsors
	#block-views-sponsors-block-1 {
		text-align: center;
	}

	.l-four-column-asymetric__column--first {
		width: 100%;
		.footer-item {
			margin: 0 auto;
			text-align: center;
			.footer-item__fixed-width {
				margin: 0 auto;
			}
		}
	}
	.l-four-column-asymetric__column--fourth,
	.l-four-column-asymetric__column--third {
		float: left;
		width: 100%;
		margin-right: 0;
		margin-left: 0;
		display: block;
		clear: left;
		.footer-item {
			text-align: center;
			max-width: 100%;
			padding-top: 20px;
			padding-bottom: 20px;
			.footer-item__link-social {
				padding: 0 10px;
			}
		}
	}

	.l-four-column-asymetric--bottom {
		text-align: center;
	}
}

@media screen and (max-width: $break-790) {

	.page-checkout,
	.page-cart,
	.section-cart {
		.main-content {
			padding: 40px 20px;
		}
	}

	#block-views-food-ticket-block,
	.node-ticket {
		margin-top: 60px;
		padding: 0 !important;
		max-width: 85% !important;
		.group-left {
			height: 180px;
			margin: 0;
			max-width: 100%;
			width: 100%;
			margin: 0;
		}
		.group-right {
			margin: 0;
		    position: relative;
		    float: left;
		    width: 100%;
		    padding: 30px;
		    text-align: center;
		    input,
		    select {
		    	max-width: 315px;
		    }
		    .form-submit {
		    	max-width: 315px;
		    	width: 100%;
		    	position: relative;
		    	right: auto;
		    	margin-top: 0px;
		    	line-height: 10px;
		    }
		    .group-product-general-info {
		    	position: relative;
		    	right: auto;
		    	width: auto;
		    	top: auto;
		    }
				&:after {
					content: none;
				}
			}
			.commerce-product-field {
				display: inline-block;
				margin-right: 15px;
				.field-items {
					float: none;
				}
			}
	}

	#block-views-food-ticket-block {
		.node-ticket {
			max-width: 100% !important;
		}
	}


}


@media screen and (max-width: $break-780) {
	.front {
		.link-tile {
			height: 300px;
			background-size: 130px !important;
			background-position: center 50px !important;
		}
		.link-title__link-to-content {
			height: 200px;
			padding-top: 220px;
		}
	}
}

@media screen and (max-width: $break-650) {


	.front {
		.event-top-banner {
		    font-size: 17px;
		    left: auto;
		    bottom: 70px;
		}
	}

	.page-agenda {
		.node-session {

			.group-inner-info {
				.field-name-title-field {
					width: calc(100% - 35px);
				}
			}

			.group-toggle-area {
				.field-name-field-duration {
					display: block;
					float: none;
				}
			}
		}
	}


	.page-user-orders {
		.main-content {
			table {
				td {
					padding: 10px 30px;
					&:before {
						width: auto;
					}
				}
			}
		}
	}

	.password-strength {
		float: none;
	}

	.page-checkout, 
	.page-cart, 
	.section-cart {
		.views-field-edit-delete {
			&:before {
				content: none;
			}
		}
		form {
			table {
				td {
					border: none !important;
					&:before {
						width: 150px;
					}
					&.component-title {
						padding-bottom: 0;
					}
				}
				tbody {
					.views-field-commerce-total {
						text-align: left;
					}
				}
			}
		}
	
		.line-item-summary {
			max-width: 100%;
		}
	}
	
	.node-speech {
		&.node-teaser {
			padding: 20px 0;
		}
	}

	.node-type-speech,
	.node-type-session {
	  .node-session,
	  .node-speech {
	    &.view-mode-full {
	      padding: 20px !important;
	    }
		}
	}


	.page-node:not(.node-type-speaker):not(.node-type-press-article) {
		.main-content	{
			padding-top: 0;
			padding-bottom: 0;
			&>article {
				margin-top: 0;
			}
		}
	 }
	 	
	.event-top-banner {
    right: 0;
    left: 0;
    bottom: -80px;
    border-radius: 0;
    padding: 10px 0;
    p {
    	display: block;
    	line-height: 10px;
    	font-size: 14px;
    	&.event-title {
    		border-bottom: 0;
    	}
    }
	}

	.node-type-webform {
		#webform-client-form-1693 {
			fieldset {
				padding: 0;
			}
			.form-item {
				padding: 30px 0;
				.form-item {
					width: 50%;
					padding: 10px 0;
				}
			}
		}
	}

	//program view page 
	.node-speech {
		&.node-teaser {
			.slot-secondary-info {
				padding: 0;
			}
		}
	}
	.page-agenda {
		.node-session {
			.field-name-field-session-slots {
				&:after {
					display: none;
				}
			}
		}
	}


	.node-session.view-mode-full .field-name-field-session-slots:after {
		display: none;
	}

}

@media screen and (max-width: $break-600) {
	//Contact Form
	.form {
		.form-item {
			width: 100%;
		}
	}

}

@media screen and (max-width: $break-575) {

	.page-checkout-tickets {
		.locality-block {
			.form-item {
				width: 100%;
			}
		}
	}

	#sidr-0-button {
		padding-left: 20px;
	}
	.base-node-top-banner {
		height: 300px;
	}
	.node-title {
		h1 {
			//font-size: 44px;
			text-align: center;
		}
	}

	//speech-session node-title
	.node-type-speech,
	.node-type-session {
		.node-title {
			text-align: center;
			bottom: 30px;
			padding: 0 5px;
			h1 {
				font-size: 20px;
				text-align: center;
			}
			.start-time,
			.node-speakers {
				font-size: 16px;
			}
		}
	}

	.unified-login-register--user-login {
		#user-login-form {
			width: 100%;
		}
	}
	.page-user, .page-user-login, .page-user-register, .page-user-password {
		.create-account {
			width: 100%;
			float: none;
			clear: left;
			margin-top: 40px;
			text-align: left;
		}
	}

}

@media screen and (max-width: $break-500) {

	.logo-wrapper {
		text-align: right;
	}

	.not-front {
		.link-button {
	    min-width: 280px;
    	margin: 5px 0;
		}
	}

	.node-special-page {
		padding: 30px !important;
		ol {
			padding-left: 0;
		}
		.student,
		.speaker {
			padding-left: 10px;
		}
		li {
	    &:nth-child(1),
	    &:nth-child(2),
	    &:nth-child(3) {
	      &:after {
	        content: "";
	        right: 0px;
	      }
	    }
	  }
	}

	.node-speech {
		&.node-teaser {

			.group-general-info {
				width: 100%;

			}
			.group-teaser-attachments {
				float: none;
				margin-left: 0;
				margin-right: 0;
				border-left: 0;
				padding-left: 0;
				padding-top: 20px;
				width: 100%;
				clear: left;
				max-width: 100%;


				.teaser-video, 
				.speech-downloads {
					display: block;
					vertical-align: top;
					margin-bottom: 0;
					margin-left: 0;
					padding-left: 0;
					margin-right: 10px;
				}
			}
		}
	}


	.page-user-register {
		input[type="file"] {
			font-size: 14px;
		}
		#addressfield-wrapper {
			overflow: hidden;
			fieldset {
			    margin-left: 0;
			    padding: 0;
			    border: none;
			}
		}
	}


}

@media screen and (max-width: $break-400) {
	.node-title {
		h1 {
			font-size: 34px;
			text-align: center;
		}
	}

	.editable-page__title,
	.editable-page__text {
		padding: 10px !important;
	}
	.front {
		.link-tile__title {
			font-size: 22px;
		}
		.panel-2col-stacked .panel-col-top .inside,
		.center-wrapper {
			padding: 0;
		}
	}
}