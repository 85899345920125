//Colors//
//$grey_c: #000000;
$hover: #000000;

//Font Sizes//
$tiny: 14px;
$small: 16px;


#sliding-popup {
  .popup-content {
    display: block;
    padding: 18px 0;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    #popup-text {
      margin: 0;
      max-width: 57%;
      margin-right: 3%;

      p {
        font-size: $small;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    #popup-buttons {
      margin: 13px 0 0;
      button{
        border-radius: 0;
        border: none;
        background: $white;
        color: $hover !important;
        padding: 10px 30px;
        display: inline-block;
        //font-family: $alegreya;
        font-size: $tiny;
        font-weight: 400;
        margin-top: 0;
        box-shadow: none;
        text-shadow: none;
        transition: all 0.7s;
        margin-bottom: 5px;
        
        &:hover {
          background: $hover;
          color: $white !important;
        }
      }

      .agree-button {
        margin-right: 10px;
      }
    }
  }
}

.eu-cookie-compliance-more-button {
  font-size: $small;
}


//////////////////////
//                  //
//    RESPONSIVE    //
//                  //
//////////////////////

@media screen and (max-width: 800px) {

  #sliding-popup {
    .popup-content {
      padding: 13px 0;

      #popup-buttons {
        float: none;
        max-width: 100%;
        margin-top: 15px;

        button {
          padding: 7px 20px;
        }
      }

      #popup-text {
        float: none;
        max-width: 100%;
      }
    }
  }

}