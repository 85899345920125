
//colors
$color_rangoon_green_approx: #1a1a1a;
$color_carnation_approx: #f15b55;
$color_mountain_mist_approx: #999;
$color_celeste_approx: #ccc;
$yellow: #ff0;
$color_de_york_approx: #83c994;
$white: #fff;
$color_blue_bayoux_approx: #526479;
$gray: #808080;
$color_mercury_approx: #e6e6e6;
$concrete: #f2f2f2;
$color_chicago_approx: #58595b;
$color_nobel_approx: #b3b3b3;
$color_emperor_approx: #4d4d4d;
$color_punch_approx: #db3b30;
$color_cream_can_approx: #f3c969;
$black: #231f20;
$color_lochmara_approx: #0072b9;
$color_scotch_mist_approx: #ffd;
$color_cadet_blue_approx: #a7b4c3;
$color_your_pink_approx: #fbc9bc;
$color_cinderella_approx: #fee7dc;
$color_moss_green_approx: #b6dcae;
$color_frostee_approx: #e3f2e7;
$color_guardsman_red_approx: #c00;
$color_gallery_approx: #eee;
$color_alto_approx: #ddd;
$color_geyser_approx: #cfe2e5;
$color_jungle_mist_approx: #b6d3d7;
$color_sulu_approx: #be7;
$color_sugar_cane_approx: #f8fff0;
$color_green_house_approx: #234600;
$color_pueblo_approx: #8c2e0b;
$provincial_pink: #fef5f1;
$color_confetti_approx: #ed5;
$color_flamingo_approx: #ed541d;
$color_storm_dust_approx: #666;
$black_10: rgba(0, 0, 0, 0.1);
$white_60: rgba(255, 255, 255, 0.6);
$color_mine_shaft_approx: #333;
$white_90: rgba(255, 255, 255, 0.9);
$color_lemon_chiffon_approx: #ffffc4;
$color_gray_nurse_approx: #eaeaea;
$seashell: #f1f1f1;
$color_black_haze_approx: #f7f7f7;

@import url("//hello.myfonts.net/count/36a61d");
@font-face {
  font-family: Averta-Bold;
  src: url(../webfonts/36A61D_0_0.eot);
  src: url(../webfonts/36A61D_0_0.eot?#iefix) format("embedded-opentype"),url(../webfonts/36A61D_0_0.woff2) format("woff2"),url(../webfonts/36A61D_0_0.woff) format("woff"),url(../webfonts/36A61D_0_0.ttf) format("truetype");
}
@font-face {
  font-family: Averta-Regular;
  src: url(../webfonts/36A61D_1_0.eot);
  src: url(../webfonts/36A61D_1_0.eot?#iefix) format("embedded-opentype"),url(../webfonts/36A61D_1_0.woff2) format("woff2"),url(../webfonts/36A61D_1_0.woff) format("woff"),url(../webfonts/36A61D_1_0.ttf) format("truetype");
}
html {
  font-size: 106.25%;
  line-height: 1.41176em;
  font-family: Averta-Regular;
  min-height: 100%;
  input[type="button"] {
  }
  &.js {
    input {
      &.form-autocomplete {
        background-image: url(../sass/components/autocomplete/throbber.gif?1539002482);
        background-position: 100% 2px;
        background-repeat: no-repeat;
      }
      &.throbbing {
        background-position: 100% -18px;
      }
      &.is-throbbing.form-autocomplete {
        background-position: 100% -18px;
      }
    }
    .js-hidden {
      display: none;
    }
    .element-hidden {
      display: none;
    }
    .js-hide {
      display: none;
    }
    [dir="rtl"] input {
      &.form-autocomplete {
        background-position: 0 2px;
      }
      &.throbbing {
        background-position: 0 -18px;
      }
      &.is-throbbing.form-autocomplete {
        background-position: 0 -18px;
      }
    }
  }
}
@media print {
  html {
    font-size: 12pt;
  }
  :link {
    text-decoration: underline;
  }
  :visited {
    text-decoration: underline;
  }
  .main-navigation {
    display: none;
  }
  .region-sidebar-first {
    display: none;
  }
  .region-sidebar-second {
    display: none;
  }
  .footer {
    display: none;
  }
  .breadcrumb {
    display: none;
  }
  .pager {
    display: none;
  }
  .print-none {
    display: none;
  }
  .toolbar {
    display: none;
  }
  .action-links {
    display: none;
  }
  .links {
    display: none;
  }
  .book-navigation {
    display: none;
  }
  .forum-topic-navigation {
    display: none;
  }
  .feed-icons {
    display: none;
  }
  .skip-link__wrapper {
    display: none;
  }
  .tabs {
    display: none;
  }
  .tabs--secondary {
    display: none;
  }
  a[href]:after {
    content: "(" attr(href) ")";
    font-weight: normal;
    font-size: 17px;
    text-decoration: none;
  }
  a[href^='javascript:']:after {
    content: '';
  }
  a[href^='#']:after {
    content: '';
  }
  abbr[title]:after {
    content: "(" attr(title) ")";
  }
  .sidebar-first .main-content {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .sidebar-second .main-content {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .two-sidebars .main-content {
    float: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
@media(min-width: 481px) and(max-width: 880px) {
  html {
    font-size: 18px;
    line-height: 32px;
  }
  .top-banner {
    min-height: 600px;
    background-attachment: scroll;
  }
  .top-banner__image {
    padding-top: 60px;
  }
  .top-banner--signature::after {
    font-size: 18px;
    line-height: 26px;
    bottom: 50px;
    width: 500px;
    margin-left: -250px;
  }
  .top-banner__title {
    font-size: 58px;
    line-height: 48px;
    font-size: 3.41176rem;
    line-height: 2.82353rem;
  }
  .front-deprecated {
    .top-banner__logo {
      width: 180px;
      height: 196px;
      background-size: contain;
      margin-top: 80px;
    }
    .top-banner__title {
      font-size: 21px;
      line-height: 24px;
      font-size: 1.23529rem;
      line-height: 1.41176rem;
    }
    .top-banner__subtitle {
      font-size: 21px;
      line-height: 24px;
      font-size: 1.23529rem;
      line-height: 1.41176rem;
    }
  }
  .top-banner--short {
    min-height: 400px;
  }
  div.panel-pane div.top-banner {
    padding-top: 70px;
  }
  .top-banner--front .top-banner__title {
    font-size: 40px;
    line-height: 36px;
    font-size: 2.35294rem;
    line-height: 2.11765rem;
    br {
      display: none;
    }
  }
}
@media(min-width: 0) and(max-width: 480px) {
  html {
    font-size: 17px;
    line-height: 30px;
  }
  #google-map {
    height: 300px;
  }
  .top-banner {
    min-height: 450px;
    background-attachment: scroll;
  }
  .top-banner--signature::after {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    left: 0;
    margin-left: 0;
    padding: 0 40px;
    bottom: 30px;
  }
  .top-banner__logo {
    width: 95px;
    height: 104px;
    background-size: contain;
  }
  .top-banner__title {
    padding-top: 70px;
    font-size: 37px;
    line-height: 36px;
    font-size: 2.17647rem;
    line-height: 2.11765rem;
  }
  .top-banner__subtitle {
    font-size: 21px;
    line-height: 24px;
    font-size: 1.23529rem;
    line-height: 1.41176rem;
  }
  .top-banner--front {
    .top-banner__title {
      padding-top: 70px;
      font-size: 24px;
      line-height: 34px;
      br {
        display: none;
      }
    }
    .top-banner__subtitle {
      padding-top: 8px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .front-deprecated {
    .top-banner__logo {
      width: 115px;
      height: 126px;
      background-size: contain;
      margin-top: 35px;
    }
    .top-banner__title {
      font-size: 21px;
      line-height: 24px;
      font-size: 1.23529rem;
      line-height: 1.41176rem;
      padding-top: 15px;
    }
    .top-banner__subtitle {
      font-size: 21px;
      line-height: 24px;
      font-size: 1.23529rem;
      line-height: 1.41176rem;
    }
    div.panel-pane div.top-banner {
      padding-top: 70px;
    }
  }
  .top-banner--short {
    min-height: 350px;
  }
  .page-venue .footer-negative-margin {
    margin-top: 34px;
  }
  div.panel-pane div.top-banner {
    padding-top: 70px;
  }
}
body {
  margin: 0;
  padding: 0;
  background-repeat: repeat;
  color: $color_rangoon_green_approx;
  &.drag {
    cursor: move;
  }
}
* {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
}
article {
  display: block;
}
aside {
  display: block;
}
details {
  display: block;
}
figcaption {
  display: block;
}
figure {
  display: block;
  margin: 0 0 24px;
  margin: 0 0 1.41176rem;
}
footer {
  display: block;
}
header {
  display: block;
}
hgroup {
  display: block;
}
main {
  display: block;
}
menu {
  display: block;
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 0 0 0 34px;
}
nav {
  display: block;
}
section {
  display: block;
}
summary {
  display: block;
}
audio {
  display: inline-block;
  vertical-align: baseline;
  &:not([controls]) {
    display: none;
    height: 0;
  }
}
canvas {
  display: inline-block;
  vertical-align: baseline;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
video {
  display: inline-block;
  vertical-align: baseline;
}
[hidden] {
  display: none;
}
template {
  display: none;
}
img {
  border: 0;
  max-width: 100%;
  height: auto;
  width: auto;
}
svg {
  max-width: 100%;
  height: auto;
  width: auto;
  &:not(:root) {
    overflow: hidden;
  }
}
:link {
  color: $color_carnation_approx;
  text-decoration: none;
}
:visited {
  color: $color_carnation_approx;
}
a {
  &:active {
    color: $color_carnation_approx;
    background-color: transparent;
    outline: 0;
  }
  &:hover {
    outline: 0;
  }
}
.button {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: $color_rangoon_green_approx;
  &:hover {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
  &:focus {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
  &:active {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
}
button {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: $color_rangoon_green_approx;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
  &:hover {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
  &:focus {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
  &:active {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
}
[type="button"] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: $color_rangoon_green_approx;
  &:hover {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
  &:focus {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
  &:active {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
}
[type="reset"] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: $color_rangoon_green_approx;
  &:hover {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
  &:focus {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
  &:active {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
}
[type="submit"] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: $color_rangoon_green_approx;
  &:hover {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
  &:focus {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
  &:active {
    text-decoration: none;
    color: $color_rangoon_green_approx;
  }
}
.button[disabled] {
  cursor: default;
  color: $color_mountain_mist_approx;
  &:hover {
    color: $color_mountain_mist_approx;
  }
  &:focus {
    color: $color_mountain_mist_approx;
  }
  &:active {
    color: $color_mountain_mist_approx;
  }
}
button[disabled] {
  cursor: default;
  color: $color_mountain_mist_approx;
  &:hover {
    color: $color_mountain_mist_approx;
  }
  &:focus {
    color: $color_mountain_mist_approx;
  }
  &:active {
    color: $color_mountain_mist_approx;
  }
}
[disabled][type="button"] {
  cursor: default;
  color: $color_mountain_mist_approx;
  &:hover {
    color: $color_mountain_mist_approx;
  }
  &:focus {
    color: $color_mountain_mist_approx;
  }
  &:active {
    color: $color_mountain_mist_approx;
  }
}
[disabled][type="reset"] {
  cursor: default;
  color: $color_mountain_mist_approx;
  &:hover {
    color: $color_mountain_mist_approx;
  }
  &:focus {
    color: $color_mountain_mist_approx;
  }
  &:active {
    color: $color_mountain_mist_approx;
  }
}
[disabled][type="submit"] {
  cursor: default;
  color: $color_mountain_mist_approx;
  &:hover {
    color: $color_mountain_mist_approx;
  }
  &:focus {
    color: $color_mountain_mist_approx;
  }
  &:active {
    color: $color_mountain_mist_approx;
  }
}
input {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
}
optgroup {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
  font-weight: bold;
}
select {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
  text-transform: none;
}
textarea {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
  line-height: inherit;
  overflow: auto;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  line-height: inherit;
}
input[type="search"]::-webkit-search-cancel-button {
}
input[type="search"]::-webkit-search-decoration {
}
input[type="text"] {
  line-height: inherit;
}
input[type="tel"] {
  line-height: inherit;
}
input[type="url"] {
  line-height: inherit;
}
input[type="email"] {
  line-height: inherit;
}
input[type="password"] {
  line-height: inherit;
}
input[type="date"] {
  line-height: inherit;
}
input[type="time"] {
  line-height: inherit;
}
input[type="number"] {
  line-height: inherit;
}
input[type="checkbox"] {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  padding: 0;
}
input[type="radio"] {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  padding: 0;
}
fieldset {
  padding: 8.4px 15px 15.6px;
  padding: 0.49412rem 0.88235rem 0.91765rem;
  border: 1px solid $color_celeste_approx;
  margin: 0 2px;
}
legend {
  border: 0;
  margin-left: -5px;
  padding: 0 5px;
}
label {
  display: block;
  font-weight: bold;
  &.option {
    display: inline;
    font-weight: normal;
  }
}
.divider {
  margin: 24px 0;
  margin: 1.41176rem 0;
  border: 0;
  border-top: 1px solid $color_celeste_approx;
  > :first-child {
    margin-top: 24px;
    margin-top: 1.41176rem;
  }
}
hr {
  margin: 24px 0;
  margin: 1.41176rem 0;
  border: 0;
  border-top: 1px solid $color_celeste_approx;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: content-box;
  height: 0;
  > :first-child {
    margin-top: 24px;
    margin-top: 1.41176rem;
  }
}
blockquote {
  margin: 0 34px 24px;
  margin: 0 2rem 1.41176rem;
}
p {
  margin: 24px 0;
  margin: 1.41176rem 0;
}
pre {
  margin: 24px 0;
  margin: 1.41176rem 0;
  font-family: Menlo, DejaVu Sans Mono, Ubuntu Mono, Courier, Courier New, monospace, sans-serif;
  font-size: 1em;
  overflow: auto;
}
code {
  font-family: Menlo, DejaVu Sans Mono, Ubuntu Mono, Courier, Courier New, monospace, sans-serif;
  font-size: 1em;
}
kbd {
  font-family: Menlo, DejaVu Sans Mono, Ubuntu Mono, Courier, Courier New, monospace, sans-serif;
  font-size: 1em;
}
samp {
  font-family: Menlo, DejaVu Sans Mono, Ubuntu Mono, Courier, Courier New, monospace, sans-serif;
  font-size: 1em;
}
var {
  font-family: Menlo, DejaVu Sans Mono, Ubuntu Mono, Courier, Courier New, monospace, sans-serif;
  font-size: 1em;
}
dl {
  margin: 24px 0;
  margin: 1.41176rem 0;
}
ol {
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 0 0 0 34px;
  ol {
    margin: 0;
  }
  ul {
    margin: 0;
  }
}
ul {
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 0 0 0 34px;
  ol {
    margin: 0;
  }
  ul {
    margin: 0;
  }
}
dd {
  margin: 0 0 0 34px;
}
[dir="rtl"] {
  dd {
    margin: 0 34px 0 0;
  }
  menu {
    padding: 0 34px 0 0;
  }
  ol {
    padding: 0 34px 0 0;
  }
  ul {
    padding: 0 34px 0 0;
  }
  .autocomplete {
    background-position: 0 2px;
    &.is-throbbing {
      background-position: 0 -18px;
    }
  }
  .autocomplete-is-throbbing {
    background-position: 0 -18px;
  }
  .collapsible-fieldset__legend {
    padding-left: 0;
    padding-right: 15px;
    background-position: 98% 75%;
  }
  .collapsible {
    .fieldset-legend {
      padding-left: 0;
      padding-right: 15px;
      background-position: 98% 75%;
    }
    &.collapsed .fieldset-legend {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
      background-position: 98% 50%;
    }
  }
  .is-collapsed {
    .collapsible-fieldset__legend {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
      background-position: 98% 50%;
    }
    &.menu__item {
      list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
    }
    .collapsible .fieldset-legend {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
      background-position: 98% 50%;
    }
  }
  .collapsible-fieldset-is-collapsed__legend {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
    background-position: 98% 50%;
  }
  .comment--nested {
    margin-left: 0;
    margin-right: 34px;
  }
  .indented {
    margin-left: 0;
    margin-right: 34px;
  }
  .form-table__header {
    text-align: right;
    padding-left: 1em;
    padding-right: 0;
  }
  .header__logo {
    float: right;
    margin: 0 0 0 10px;
  }
  .header__secondary-menu {
    float: left;
  }
  .inline-links__item {
    display: inline-block;
    padding: 0 0 0 1em;
  }
  .inline-sibling {
    margin-right: 0;
    margin-left: 10px;
  }
  .field__label-inline {
    margin-right: 0;
    margin-left: 10px;
  }
  .messages {
    padding: 10px 50px 10px 10px;
    background-position: 99% 8px;
    margin-bottom: 20px;
    &.warning {
      padding: 10px 50px 10px 10px;
      background-position: 99% 8px;
    }
    &.error {
      padding: 10px 50px 10px 10px;
      background-position: 99% 8px;
    }
  }
  .messages--warning {
    padding: 10px 50px 10px 10px;
    background-position: 99% 8px;
  }
  .messages--status {
    padding: 10px 50px 10px 10px;
    background-position: 99% 8px;
  }
  .messages--error {
    padding: 10px 50px 10px 10px;
    background-position: 99% 8px;
  }
  .more-link {
    text-align: left;
  }
  .more-help-link {
    text-align: left;
    a {
      padding: 1px 20px 1px 0;
      background-position: 100% 50%;
    }
  }
  .more-link__help-icon {
    padding: 1px 20px 1px 0;
    background-position: 100% 50%;
  }
  .nav-menu__item-is-collapsed {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
  }
  .progress-bar__percentage {
    float: left;
  }
  .progress-bar--inline {
    float: right;
  }
  .ajax-progress-bar {
    float: right;
  }
  .progress-throbber {
    float: right;
  }
  .ajax-progress {
    float: right;
    .throbber {
      float: right;
    }
  }
  .progress-throbber__widget {
    float: right;
  }
  .table-drag__handle {
    float: right;
    margin-right: -1em;
    margin-left: 0;
  }
  .tabledrag-handle {
    float: right;
    margin-right: -1em;
    margin-left: 0;
  }
  .table-drag__toggle-weight-wrapper {
    text-align: left;
  }
  .tabledrag-toggle-weight-wrapper {
    text-align: left;
  }
  .table-drag__indentation {
    float: right;
    margin: -.4em -.4em -.4em .2em;
    padding: .42em .6em .42em 0;
  }
  .indentation {
    float: right;
    margin: -.4em -.4em -.4em .2em;
    padding: .42em .6em .42em 0;
  }
  .table-drag__tree-child {
    background-position: -65px center;
  }
  .table-drag__tree-child-last {
    background-position: -65px center;
  }
  .tree-child-last {
    background-position: -65px center;
  }
  .table-drag__tree-child-horizontal {
    background-position: -65px center;
  }
  .tree-child-horizontal {
    background-position: -65px center;
  }
  .tree-child {
    background-position: -65px center;
  }
  .tabs__tab {
    float: right;
  }
  .tabs--secondary__tab {
    float: right;
  }
  html.js input {
    &.form-autocomplete {
      background-position: 0 2px;
    }
    &.throbbing {
      background-position: 0 -18px;
    }
    &.is-throbbing.form-autocomplete {
      background-position: 0 -18px;
    }
  }
  .form-table th {
    text-align: right;
    padding-left: 1em;
    padding-right: 0;
  }
  form table th {
    text-align: right;
    padding-left: 1em;
    padding-right: 0;
  }
  .inline.links li {
    display: inline-block;
    padding: 0 0 0 1em;
  }
  span.field-label {
    margin-right: 0;
    margin-left: 10px;
  }
  .nav-menu__item.is-collapsed {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
  }
  .menu__item.is-collapsed {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
  }
  .progress .percentage {
    float: left;
  }
}
h1 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal;
}
.header__site-name {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal;
  margin: 0;
}
h2 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal;
  &.sidr-class-visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
}
h3 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal;
}
h4 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal;
}
h5 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal;
}
h6 {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  margin-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 0;
  font-weight: normal;
}
table {
  margin: 24px 0;
  margin: 1.41176rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  .form-item {
    margin: 0;
  }
}
td {
  padding: 0;
  .checkbox {
    text-align: center;
  }
  &.active {
    background-color: $color_alto_approx;
  }
  &.menu-disabled {
    background: $color_celeste_approx;
  }
}
th {
  padding: 0;
  .checkbox {
    text-align: center;
  }
}
abbr[title] {
  border-bottom: 1px dotted;
}
b {
  font-weight: bold;
}
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
mark {
  background: $yellow;
  color: $color_rangoon_green_approx;
}
small {
  font-size: 16px;
  font-size: 0.94118rem;
}
sub {
  font-size: 13px;
  font-size: 0.76471rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  bottom: -.25em;
}
sup {
  font-size: 13px;
  font-size: 0.76471rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -.5em;
}
.node-download-item {
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 24px;
  margin-top: 1.41176rem;
  margin-bottom: 0;
  margin-bottom: 0;
  .field-name-field-file {
    a {
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.4s ease-out;
      min-width: 135px;
      min-height: 45px;
      display: inline-block;
      margin-top: 23px;
      margin-right: 16px;
      padding: 10px 30px;
      text-align: center;
      text-decoration: none;
      border: 1px solid $color_nobel_approx;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 5px;
      font-family: Averta-Regular;
      letter-spacing: 0.25px;
      font-size: 18px;
      line-height: 23px;
      color: $color_rangoon_green_approx;
      margin-right: 0;
      background-color: $color_emperor_approx;
      color: $white;
      background-image: url(../images/dl.png);
      background-position: 15px 14px;
      background-repeat: no-repeat;
      padding-left: 40px;
      &:hover {
        color: $color_carnation_approx;
        border-color: $color_carnation_approx;
        color: $white;
        background-color: $color_carnation_approx;
      }
      &:focus {
        color: $white;
        background-color: $color_carnation_approx;
      }
    }
    img {
      display: none;
    }
  }
}
.messages--status {
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 10px 10px 10px 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid $color_sulu_approx;
  background-color: $color_sugar_cane_approx;
  color: $color_green_house_approx;
  background-color: $provincial_pink;
  color: $color_pueblo_approx;
  border-color: $color_flamingo_approx;
  display: block;
  max-width: 920px;
  margin: 0 auto;
  background: $provincial_pink;
  color: $color_green_house_approx;
  text-align: left;
  border-color: $color_green_house_approx;
}
.contained {
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  padding-left: 15px;
  padding-right: 15px;
}
.contained--no-gutter {
  padding-left: 0;
  padding-right: 0;
}
.page {
  margin-left: auto;
  margin-right: auto;
}
.region-bottom {
  margin-left: auto;
  margin-right: auto;
}
@media all and(min-width: 0px) {
  .main {
    position: relative;
  }
  .main-navigation {
    position: absolute;
    top: 0;
    height: 34px;
    width: 100%;
    z-index: 10;
  }
}
@media all and(min-width: 880px) {
  .main-navigation {
    position: absolute;
    top: 0;
    height: 194px;
    width: 100%;
    z-index: 10;
  }
}
@media all and(min-width: 480px) and(max-width: 959px) {
  .sidebar-first {
    .main-content {
      float: left;
      width: 66.66667%;
      margin-left: 33.33333%;
      margin-right: -100%;
    }
    .region-sidebar-first {
      float: left;
      width: 33.33333%;
      margin-left: 0;
      margin-right: -100%;
    }
  }
  .sidebar-second {
    .main-content {
      float: left;
      width: 66.66667%;
      margin-left: 0;
      margin-right: -100%;
    }
    .region-sidebar-second {
      float: left;
      width: 33.33333%;
      margin-left: 66.66667%;
      margin-right: -100%;
    }
  }
  .two-sidebars {
    .main-content {
      float: left;
      width: 66.66667%;
      margin-left: 33.33333%;
      margin-right: -100%;
    }
    .region-sidebar-first {
      float: left;
      width: 33.33333%;
      margin-left: 0;
      margin-right: -100%;
    }
    .region-sidebar-second {
      float: left;
      width: 100%;
      margin-left: 0;
      margin-right: -100%;
      padding-left: 0;
      padding-right: 0;
      clear: left;
      &:before {
        content: "";
        display: table;
      }
      &:after {
        content: "";
        display: table;
        clear: both;
      }
      .block {
        padding-left: 10px;
        padding-right: 10px;
        //Instead of the line below you could use @include box-sizing($bs)
        box-sizing: border-box;
        &:nth-child(3n + 1) {
          float: left;
          width: 33.33333%;
          margin-left: 0;
          margin-right: -100%;
          clear: left;
        }
        &:nth-child(3n + 2) {
          float: left;
          width: 33.33333%;
          margin-left: 33.33333%;
          margin-right: -100%;
        }
        &:nth-child(3n) {
          float: left;
          width: 33.33333%;
          margin-left: 66.66667%;
          margin-right: -100%;
        }
      }
    }
  }
}
@media all and(min-width: 960px) {
  .sidebar-first {
    .main-content {
      float: left;
      width: 80%;
      margin-left: 20%;
      margin-right: -100%;
    }
    .region-sidebar-first {
      float: left;
      width: 20%;
      margin-left: 0;
      margin-right: -100%;
    }
  }
  .sidebar-second {
    .main-content {
      float: left;
      width: 80%;
      margin-left: 0;
      margin-right: -100%;
    }
    .region-sidebar-second {
      float: left;
      width: 20%;
      margin-left: 80%;
      margin-right: -100%;
    }
  }
  .two-sidebars {
    .main-content {
      float: left;
      width: 60%;
      margin-left: 20%;
      margin-right: -100%;
    }
    .region-sidebar-first {
      float: left;
      width: 20%;
      margin-left: 0;
      margin-right: -100%;
    }
    .region-sidebar-second {
      float: left;
      width: 20%;
      margin-left: 80%;
      margin-right: -100%;
    }
  }
}
.l-two-column-thirds {
  display: flex;
}
.l-two-column-thirds__left {
  display: flex;
  width: 41.66667%;
}
@media (min-width: 0px) and (max-width: 780px) {
  .l-two-column-thirds__left {
    float: none;
    margin: 0;
    width: 100%;
  }
  .l-two-column-thirds__right {
    float: none;
    margin: 0;
    width: 100%;
  }
  .panel-2col-stacked {
    .panel-col-last {
      float: none;
      width: 100%;
      .inside {
        margin: 0;
      }
      .link-tile {
        margin-top: 20px;
        // margin-top: 1.41176rem;
      }
    }
    .panel-col-first {
      float: none;
      width: 100%;
      .inside {
        margin: 0;
      }
      .link-tile {
        margin-top: 20px;
        // margin-top: 1.41176rem;
        // margin-top: -24px;
        // margin-top: -1.41176rem;
      }
    }
  }
}
.l-two-column-thirds__right {
  width: 58.33333%;
}
.l-four-column-asymetric {
  &:before {
    content: "";
    display: table;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.l-four-column-asymetric__column {
  padding-left: 15px;
  padding-right: 15px;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
}
.l-four-column-asymetric__column--first {
  float: left;
  width: 100%;
  text-align: center;
  margin-left: 0;
  margin-right: -100%;
}
.l-four-column-asymetric__column--second {
  float: left;
  width: 25%;
  margin-left: 25%;
  margin-right: -100%;
}
.l-four-column-asymetric__column--third {
  float: left;
  width: 33.33333%;
  margin-left: 50%;
  margin-right: -100%;
}
.l-four-column-asymetric__column--fourth {
  float: left;
  width: 16.66667%;
  margin-left: 83.33333%;
  margin-right: -100%;
}
@media(min-width: 501px) and(max-width: 840px) {
  .l-four-column-asymetric__column--first {
    width: 100%;
    float: none;
    margin: 0;
  }
  .l-four-column-asymetric__column--second {
    float: left;
    width: 50%;
    margin-left: 0;
    margin-right: -100%;
  }
  .l-four-column-asymetric__column--third {
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
  }
  .l-four-column-asymetric__column--fourth {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%;
    margin-top: -18px;
  }
}
@media(min-width: 0) and(max-width: 500px) {
  .l-four-column-asymetric__column--fourth {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%;
  }
  .l-four-column-asymetric__column--third {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%;
  }
  .l-four-column-asymetric__column--second {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%;
  }
  .l-four-column-asymetric__column--first {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0;
    margin-right: -100%;
  }
  .l-four-column-asymetric--bottom {
    padding-bottom: 20px;
    padding-top: 20px;
    .l-four-column-asymetric__column--first .l-four-column-asymetric__column--fourth {
      display: none;
    }
  }
  .callout {
    top: auto;
    bottom: 20px;
    width: 100px;
    height: auto;
  }
  .footer-item {
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 0;
  }
  .footer-item_small {
    margin-bottom: 0;
    text-align: center;
    line-height: 15px;
  }
}
@media(min-width: 500px) and(max-width: 780px) {
  .l-four-column-asymetric--bottom {
    .l-four-column-asymetric__column--third {
      clear: none;
    }
    .l-four-column-asymetric__column--first .l-four-column-asymetric__column--fourth {
      display: none;
    }
  }
  .l-three-column--column {
    width: 50%;
  }
  .l-three-column__column {
    width: 50%;
  }
}
.l-three-column--column {
  padding: 17px 15px;
  float: left;
  width: 33.3%;
}
.l-three-column__column {
  padding: 17px 15px;
  float: left;
  width: 33.3%;
}
@media(min-width: 0px) and(max-width: 500px) {
  .l-three-column--column {
    width: 100%;
  }
  .l-three-column__column {
    width: 100%;
  }
}
.l-four-column--column {
  padding: 17px 15px;
  float: left;
  width: 25%;
}
.l-four-column__column {
  padding: 17px 15px;
  float: left;
  width: 25%;
}
@media(min-width: 0) and(max-width: 600px) {
  .l-four-column--column {
    width: 50%;
  }
  .l-four-column__column {
    width: 50%;
  }
}
.l-four-column--no-padding {
  .l-four-column--column {
    padding: 0;
  }
  .l-four-column__column {
    padding: 0;
  }
}
.l-two-column--column {
  padding: 17px 15px;
  float: left;
  width: 50%;
}
.l-two-column__column {
  padding: 17px 15px;
  float: left;
  width: 50%;
}
@media(min-width: 0) and(max-width: 580px) {
  .l-two-column--column {
    width: 100%;
  }
  .l-two-column__column {
    width: 100%;
  }
}
.l-four-column-centered {
  text-align: center;
}
.l-four-column-centered--column {
  margin: 5px 8px;
  display: inline-block;
}
.l-four-column-centered__column {
  margin: 5px 8px;
  display: inline-block;
}
.l-four-column-centered--no-padding {
  .l-four-column-centered--column {
    padding: 0;
  }
  .l-four-column-centered__column {
    padding: 0;
  }
}
div {
  &#admin-toolbar {
    &.horizontal div.admin-tabs {
      height: 35px;
    }
    div.admin-tab {
      height: 30px;
    }
  }
  &.krumo-expand {
    text-align: left;
  }
  &.panel-pane div {
    &.footer-padding {
      padding-bottom: 144px;
      padding-bottom: 8.47059rem;
    }
    &.top-banner {
      padding-top: 194px;
    }
  }
  &.pane-node div.node-photo-gallery {
    margin-top: 96px;
    margin-top: 5.64706rem;
    margin-bottom: 96px;
    margin-bottom: 5.64706rem;
  }
}
.agenda {
  padding-top: 48px;
  padding-top: 2.82353rem;
  padding-bottom: 24px;
  padding-bottom: 1.41176rem;
  .view-header {
    padding-bottom: 24px;
    padding-bottom: 1.41176rem;
  }
}
.agenda__entry {
  border-top: 1px solid $color_celeste_approx;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.4s ease-out;
  line-height: 25px;
  display: flex;
  margin-top: 10px;
  &:not(&.agenda__entry--no-time) {
    background-color: $color_mercury_approx;
    font-family: Averta-Regular;
    font-size: 17px;
    line-height: 24px;
    color: $white;
  }
  &:not(&.is-expandable) .agenda__title {
    background-color: $color_de_york_approx;
  }
  &.agenda__entry--no-time {
    float: right;
    width: 80%;
    .agenda__time {
      display: none;
    }
  }
}
@media(min-width: 550px) {
  .agenda__entry {
    margin-top: 0;
  }
}
.agenda__header {
  display: flex;
}
@media(min-width: 0) and(max-width: 550px) {
  .agenda__header {
    display: inline-block;
    width: 100%;
  }
  .agenda__time {
    float: none;
    width: 100%;
    min-height: 0;
    paddng: 10px;
  }
  .agenda__text {
    float: none;
    width: 100%;
    min-height: 0;
    margin-left: 0;
    padding-left: 15px;
  }
  .agenda__venue {
    float: none;
    width: 100%;
    padding-left: 30px;
    background-position: 15px 50%;
    padding-right: 15px;
  }
  .agenda__title {
    float: none;
    width: 100%;
    padding: 15px;
    margin-top: 0;
  }
  .agenda__topic {
    float: none;
    width: 100%;
    padding-left: 15px;
    padding-top: 15px;
  }
  .agenda__col-right {
    float: none;
    width: 100%;
  }
  .agenda__toggle {
    top: 0;
  }
  .agenda__entry {
    &.agenda__entry--no-time {
      width: 100%;
    }
    &:not(.agenda__entry--no-time) .agenda__time + .agenda__venue {
      padding-top: 0;
    }
  }
}
.agenda__day {
  padding-top: 36px;
  padding-top: 2.11765rem;
  &.agenda__day--tiny {
    .agenda__entry {
      width: 100%;
      border: 0;
    }
    .agenda__venue {
      display: none;
    }
  }
}
.field-name-field-start-time {
  margin: auto auto;
  .field-items .field-item {
    margin: 0;
  }
}
.agenda__time {
  float: left;
  width: 15%;
  min-height: 86px;
  font-family: Averta-Regular;
  font-size: 17px;
  line-height: 24px;
  color: $white;
  text-align: center;
  padding: 10px;
  background-color: $color_blue_bayoux_approx;
  display: flex;
}
@media(min-width: 0) and(max-width: 850px) {
  .agenda__time {
    paddng: 15px;
  }
  .sponsors__item {
    height: 180px;
    width: 180px;
    img {
      top: 90px;
    }
  }
}
@media(min-width: 0) and(max-width: 650px) {
  .agenda__time {
    paddng: 10px;
  }
  .form .form-item {
    width: 100%;
    float: none;
  }
}
.agenda__text {
  padding-left: 27px;
  clear: both;
  color: $color_rangoon_green_approx;
  padding-right: 15px;
  font-size: 17px;
  background-color: colro(blue-gray-light_text_back);
  p {
    margin-top: 0;
  }
}
.agenda__venue {
  padding-left: 27px;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 16px;
  line-height: 34px;
  color: $color_carnation_approx;
  margin-bottom: 10px;
  background-image: url(../images/venue-marker.png);
  background-position: 0 50%;
  background-repeat: no-repeat;
  padding-left: 15px;
}
.agenda__title {
  padding-left: 27px;
  position: relative;
  font-family: Averta-Regular;
  font-size: 17px;
  line-height: 24px;
  background-color: $color_carnation_approx;
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 15px;
}
.agenda__topic {
  padding-left: 27px;
  padding-top: 6px;
  color: $gray;
  font-size: 14px;
  line-height: 34px;
  min-height: 34px;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.agenda__col-right {
  padding-left: 27px;
}
@media(min-width: 0) and(max-width: 950px) {
  .agenda__title {
    padding: 15px;
  }
  .agenda__topic.agenda__venue--empty {
    display: none;
  }
  .agenda__entry.agenda__entry--no-time {
    .agenda__text {
      margin-left: 27px;
    }
    .agenda__title {
      margin-left: 27px;
    }
  }
}
.agenda__title-inner {
  margin: auto 0;
  margin-left: 26px;
  flex-grow: 2;
}
.agenda__toggle {
  height: 28px;
  width: 28px;
  cursor: pointer;
  font-size: 36px;
  line-height: 28px;
  border: 1px solid $white;
  text-align: center;
  line-height: 18px;
  margin: auto 0;
  margin-right: 20px;
  min-width: 28px;
  &:hover {
    border: 1px solid $white;
  }
}
.agenda__toggle-inner {
  width: 28px;
  height: 28px;
}
.agenda__text-inner {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Averta-Regular;
  font-size: 17px;
  line-height: 24px;
}
@media(min-width: 951px) {
  .agenda__entry.agenda__entry--no-time {
    .agenda__venue {
      width: 17.24138%;
    }
    .agenda__title {
      width: 82.75862%;
    }
    .agenda__text {
      margin-left: 17.24138%;
      width: 82.75862%;
    }
  }
}
.field-collection-container {
  margin-top: 22px;
  border-bottom: 1px solid $color_celeste_approx;
}
.field-collection-view {
  padding: 0;
  margin: 0;
  border-bottom: 0;
}
.image-overlayed {
  // text-align: center;
  // max-width: 635px;
  margin-left: auto;
  margin-right: auto;
}
.image-overlayed__image {
  line-height: 0;
  img {
    border: 7px solid $concrete;
    position: relative;
    top: 0;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateY(-70%);
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 100%;
  }
}
.article__title {
  font-family: Averta-Bold;
  font-size: 24px;
  line-height: 51px;
  color: $color_blue_bayoux_approx;
  margin-top: -150px;
  z-index: 1;
  position: relative;
  max-width: 308px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 72px;
  margin-bottom: 4.23529rem;
  margin-top: 0;
  font-family: Averta-Bold;
  &:before {
    bottom: -40px;
  }
}
.image-overlayed__title {
  font-family: Averta-Bold;
  font-size: 24px;
  line-height: 51px;
  color: $color_blue_bayoux_approx;
  margin-top: -150px;
  z-index: 1;
  position: relative;
  max-width: 308px;
  margin-left: auto;
  margin-right: auto;
}
.image-overlayed__subtitle {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 15px;
  line-height: 19px;
  color: $color_chicago_approx;
  margin-top: 12px;
  color: $color_carnation_approx;
  font-family: Averta-Regular;
  max-width: 308px;
  margin-left: auto;
  margin-right: auto;
}
.image-overlayed__bottom_line {
  position: relative;
}
.image-overlayed__text {
  margin-top: 52px;
  text-align: left;
  font-size: 17px;
  line-height: 24px;
  font-family: Averta-Regular;
  p:last-child {
    margin-bottom: 0;
  }
}
.section-register {
  .link-button {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    min-width: 135px;
    min-height: 45px;
    display: inline-block;
    margin-top: 23px;
    margin-right: 16px;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    border: 1px solid $color_nobel_approx;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    font-family: Averta-Regular;
    letter-spacing: 0.25px;
    font-size: 18px;
    line-height: 23px;
    color: $color_rangoon_green_approx;
    &:hover {
      color: $color_carnation_approx;
      border-color: $color_carnation_approx;
    }
  }
  .link-button--square {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    background-color: $color_punch_approx;
    font-size: 21px;
    line-height: 26px;
    border: 0;
    min-width: 280px;
    &:hover {
      background-color: $color_cream_can_approx;
      color: $color_rangoon_green_approx;
    }
  }
}
.article__dl {
  text-align: left;
  a {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    min-width: 135px;
    min-height: 45px;
    display: inline-block;
    margin-top: 23px;
    margin-right: 16px;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    border: 1px solid $color_nobel_approx;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    font-family: Averta-Regular;
    letter-spacing: 0.25px;
    font-size: 18px;
    line-height: 23px;
    color: $color_rangoon_green_approx;
    margin-right: 0;
    background-color: $color_emperor_approx;
    color: $white;
    background-image: url(../images/dl.png);
    background-position: 15px 14px;
    background-repeat: no-repeat;
    padding-left: 40px;
    &:hover {
      color: $color_carnation_approx;
      border-color: $color_carnation_approx;
      color: $white;
      background-color: $color_carnation_approx;
    }
    &:focus {
      color: $white;
      background-color: $color_carnation_approx;
    }
  }
  img {
    display: none;
  }
}
.link-button {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.4s ease-out;
  min-width: 135px;
  min-height: 45px;
  display: inline-block;
  margin-top: 23px;
  margin-right: 16px;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  border: 1px solid $color_nobel_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px;
  font-family: Averta-Regular;
  letter-spacing: 0.25px;
  font-size: 18px;
  line-height: 23px;
  color: $color_rangoon_green_approx;
  &:hover {
    color: $color_carnation_approx;
    border-color: $color_carnation_approx;
  }
}
.link-button__extra-text {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 15px;
  line-height: 24px;
  display: block;
}
.link-button--right {
  float: right;
  margin-right: 0;
}
.link-button--dl {
  margin-right: 0;
  background-color: $color_emperor_approx;
  color: $white;
  &:focus {
    color: $white;
    background-color: $color_carnation_approx;
  }
  &:hover {
    color: $white;
    background-color: $color_carnation_approx;
  }
}
.link-button--dl-icon {
  background-image: url(../images/dl.png);
  background-position: 15px 14px;
  background-repeat: no-repeat;
  padding-left: 40px;
}
.link-button--square {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  background-color: $color_punch_approx;
  font-size: 21px;
  line-height: 26px;
  border: 0;
  min-width: 280px;
  &:hover {
    background-color: $color_cream_can_approx;
    color: $color_rangoon_green_approx;
  }
}
.article {
  max-width: 727px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  .entity_pager_prev {
    float: left;
    min-width: 140px;
  }
  .entity_pager_next {
    float: right;
    min-width: 140px;
    text-align: right;
  }
  .entity_pager {
    padding-top: 24px;
    padding-top: 1.41176rem;
    border-top: 1px solid $color_nobel_approx;
    li {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }
    a {
      font-family: Averta-Regular;
      font-size: 16px;
      line-height: 24px;
      margin-top: 25px;
      margin-bottom: 25px;
      color: $color_chicago_approx;
      letter-spacing: .25px;
      &:hover {
        color: $color_carnation_approx;
      }
    }
    .inactive {
      visibility: hidden;
    }
  }
  &.node.node-press-article {
    margin-left: auto;
    margin-right: auto;
  }
}
.article__date {
  margin-top: 72px;
  margin-top: 4.23529rem;
  margin-bottom: 24px;
  margin-bottom: 1.41176rem;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 17px;
  line-height: 25px;
  color: $color_chicago_approx;
}
.article__body {
  text-align: left;
  font-family: Helvetica, Arial, Nimbus Sans L, sans-serif !important;
}
#block-views-entity-pager-article-block {
  padding-top: 48px;
  padding-top: 2.82353rem;
  padding-bottom: 144px;
  padding-bottom: 8.47059rem;
  background-color: $concrete;
}
.view-articles {
  padding-bottom: 144px;
  padding-bottom: 8.47059rem;
}
.article-teaser {
  margin-top: 48px;
  margin-top: 2.82353rem;
  margin-bottom: 48px;
  margin-bottom: 2.82353rem;
  padding-bottom: 24px;
  padding-bottom: 1.41176rem;
  position: relative;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  &.node.node-press-article {
    margin-top: 48px;
    margin-top: 2.82353rem;
    margin-bottom: 48px;
    margin-bottom: 2.82353rem;
    padding-bottom: 24px;
    padding-bottom: 1.41176rem;
    margin-left: auto;
    margin-right: auto;
  }
  &:hover .article-teaser__image {
    &:before {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      opacity: 0.8;
    }
    &:after {
      filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
      opacity: 1;
      font-size: 18px;
    }
  }
}
.article-teaser__date {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 17px;
  line-height: 25px;
  color: $color_chicago_approx;
  width: 95px;
  background: $white;
  margin-top: -55px;
  padding: 15px 0;
  position: relative;
  z-index: 1;
}
.article-teaser__title {
  margin-top: 18px;
  margin-top: 1.05882rem;
  font-family: Helvetica, Arial, Nimbus Sans L, sans-serif !important;
  font-size: 24px;
  line-height: 24px;
  font-size: 1.41176rem;
  line-height: 1.41176rem;
  font-family: Averta-Bold;
  color: $color_blue_bayoux_approx;
  margin-top: 24px;
}
.article-teaser__body {
  font-family: Helvetica, Arial, Nimbus Sans L, sans-serif !important;
  font-size: 15px;
  color: $color_carnation_approx;
  font-family: Averta-Regular;
  margin-top: 11px;
  p {
    margin-top: 1em;
  }
}
.article-teaser__read-more {
  font-family: Helvetica, Arial, Nimbus Sans L, sans-serif !important;
  font-size: 17px;
  line-height: 25px;
  color: $black;
  position: absolute;
  bottom: 0;
}
.autocomplete {
  background-image: url(../sass/components/autocomplete/throbber.gif?1539002482);
  background-position: 100% 2px;
  background-repeat: no-repeat;
  &.is-throbbing {
    background-position: 100% -18px;
  }
}
.autocomplete-is-throbbing {
  background-position: 100% -18px;
}
.autocomplete__list-wrapper {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  ul {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
  }
  li {
    background: $white;
    color: $color_rangoon_green_approx;
    cursor: default;
    white-space: pre;
  }
  .selected {
    background: $color_lochmara_approx;
    color: $white;
  }
}
.autocomplete__list {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}
.autocomplete__list-item {
  background: $white;
  color: $color_rangoon_green_approx;
  cursor: default;
  white-space: pre;
}
.autocomplete__list-item-is-selected {
  background: $color_lochmara_approx;
  color: $white;
}
.box {
  margin-bottom: 24px;
  margin-bottom: 1.41176rem;
  border: 5px solid $color_celeste_approx;
  padding: 1em;
  &:focus {
    border-color: $color_rangoon_green_approx;
  }
  &:hover {
    border-color: $color_rangoon_green_approx;
  }
  &.is-focus {
    border-color: $color_rangoon_green_approx;
  }
}
.box-is-focus {
  border-color: $color_rangoon_green_approx;
}
.box__title {
  margin: 0;
}
.box--highlight {
  border-color: $color_carnation_approx;
}
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
.visually-hidden--focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  &:active {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
  }
  &:focus {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
  }
}
.element-focusable {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  &:active {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
  }
  &:focus {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
  }
}
.element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
.breadcrumb__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
.main-navigation {
  .links {
    padding: 85px 0;
    text-align: right;
    margin-top: 0;
    margin-bottom: 0;
    li {
      display: inline-block;
      padding: 0 15px 0 0;
      list-style-type: none;
      list-style-image: none;
      font-family: Averta-Bold;
      font-size: 14px;
      line-height: 24px;
      font-size: 0.82353rem;
      line-height: 1.41176rem;
      &:last-child {
        padding-right: 0;
      }
      a {
        color: $white;
        padding-bottom: 3px;
        border-bottom: 1px solid transparent;
        //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
        transition: all 0.4s ease-out;
        &.active-trail {
          border-bottom: 1px solid $white;
        }
        &.active {
          border-bottom: 1px solid $white;
        }
        &:focus {
          border-bottom: 1px solid $white;
        }
        &:hover {
          border-bottom: 1px solid $white;
        }
      }
    }
  }
  .menu {
    padding: 85px 0;
    padding: 100px 0;
    padding-left: 285px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    li {
      display: inline-block;
      padding: 0 25px 0 0;
      list-style-type: none;
      list-style-image: none;
      font-family: Averta-Bold;
      font-size: 14px;
      line-height: 24px;
      font-size: 0.82353rem;
      line-height: 1.41176rem;
      &:last-child {
        padding-right: 0;
      }
      a {
        color: $white;
        padding-bottom: 3px;
        border-bottom: 1px solid transparent;
        //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
        transition: all 0.4s ease-out;
        &.active-trail {
          border-bottom: 1px solid $white;
        }
        &.active {
          border-bottom: 1px solid $white;
        }
        &:focus {
          border-bottom: 1px solid $white;
        }
        &:hover {
          border-bottom: 1px solid $white;
        }
      }
    }
  }
  .block-menu .block__title {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  .block-menu-block .block__title {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
}
.visually-hidden--off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}
.breadcrumb__list {
  margin: 0;
  padding: 0;
}
.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.callout {
  position: absolute;
  top: 20px;
  right: 50px;
}
@media(min-width: 501px) and(max-width: 800px) {
  .callout {
    top: auto;
    bottom: 20px;
  }
}
.callout__wrapper {
  position: relative;
  padding: 0;
}
.centered-text {
  margin-top: 72px;
  margin-top: 4.23529rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}
.clearfix {
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
.header {
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  .contained {
    height: 0;
  }
}
.tabs {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjY2NjY2NjIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: linear-gradient(to top, $color_celeste_approx 1px,rgba(0,0,0,0) 1px);
  margin: 24px 0;
  margin: 1.41176rem 0;
  border-bottom: 1px solid $color_celeste_approx \0/ie;
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  + .tabs--secondary {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjY2NjY2NjIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
    background-size: 100%;
    background-image: linear-gradient(to top, $color_celeste_approx 1px,rgba(0,0,0,0) 1px);
    margin-top: -24px;
    margin-top: -1.41176rem;
  }
}
.tabs--secondary {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjY2NjY2NjIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: linear-gradient(to top, $color_celeste_approx 1px,rgba(0,0,0,0) 1px);
  margin: 24px 0;
  margin: 1.41176rem 0;
  border-bottom: 1px solid $color_celeste_approx \0/ie;
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
  font-size: .9em;
  background-image: none;
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  + .tabs--secondary {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjEuMCIgeDI9IjAuNSIgeTI9IjAuMCI+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjY2NjY2NjIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
    background-size: 100%;
    background-image: linear-gradient(to top, $color_celeste_approx 1px,rgba(0,0,0,0) 1px);
    margin-top: -24px;
    margin-top: -1.41176rem;
  }
}
.collapsible-fieldset {
  position: relative;
  &.is-collapsed {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    height: 1em;
  }
}
.collapsible {
  position: relative;
  &.collapsed {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    height: 1em;
    .fieldset-wrapper {
      display: none;
    }
    .fieldset-legend {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
      background-position: 5px 50%;
    }
    [dir="rtl"] .fieldset-legend {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
      background-position: 98% 50%;
    }
  }
  .fieldset-legend {
    display: block;
    padding-left: 15px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
    background-position: 5px 65%;
    background-repeat: no-repeat;
    .summary {
      color: $color_mountain_mist_approx;
      font-size: .9em;
      margin-left: .5em;
    }
  }
  [dir="rtl"] {
    .fieldset-legend {
      padding-left: 0;
      padding-right: 15px;
      background-position: 98% 75%;
    }
    .is-collapsed .fieldset-legend {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABNJREFUCB1j4GASYFJgcmD+A4IADUIDfIUMT4wAAAAASUVORK5CYII=");
      background-position: 98% 50%;
    }
  }
  .is-collapsed .fieldset-legend {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
    background-position: 5px 50%;
  }
}
.collapsible-fieldset-is-collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}
.is-collapsed {
  &.collapsible {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    height: 1em;
  }
  .collapsible-fieldset__wrapper {
    display: none;
  }
  .collapsible-fieldset__legend {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
    background-position: 5px 50%;
  }
  &.menu__item {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
    list-style-type: disc;
  }
  .collapsible .fieldset-legend {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
    background-position: 5px 50%;
  }
}
.collapsible-fieldset-is-collapsed__wrapper {
  display: none;
}
.collapsible-fieldset__legend {
  display: block;
  padding-left: 15px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  background-position: 5px 65%;
  background-repeat: no-repeat;
}
.collapsible-fieldset-is-collapsed__legend {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  background-position: 5px 50%;
}
.collapsible-fieldset__summary {
  color: $color_mountain_mist_approx;
  font-size: .9em;
  margin-left: .5em;
}
.colored-box {
  background-color: $color_carnation_approx;
  color: $white;
  padding-top: 48px;
  padding-top: 2.82353rem;
  padding-bottom: 48px;
  padding-bottom: 2.82353rem;
  padding-left: 17%;
  padding-right: 17%;
  text-align: center;
}
.colored-box__title {
  font-family: Averta-Regular;
  font-size: 40px;
  line-height: 36px;
  font-size: 2.35294rem;
  line-height: 2.11765rem;
  margin-bottom: 12px;
  margin-bottom: 0.70588rem;
}
.colored-box__link {
  font-size: 18px;
  line-height: 36px;
  font-size: 1.05882rem;
  line-height: 2.11765rem;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: background-color 0.4s ease-out;
  color: $white;
  font-family: Averta-Regular;
  width: 166px;
  border: 1px solid $white;
  display: inline-block;
  margin-top: 33px;
  margin-bottom: 0;
  &:hover {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: background-color 0.4s ease-out;
    background-color: $white;
    color: $color_carnation_approx;
  }
}
.footer-padding {
  padding-bottom: 144px;
  padding-bottom: 8.47059rem;
}
.footer-negative-margin {
  margin-top: -144px;
  margin-top: -8.47059rem;
  position: relative;
  z-index: 12;
}
.margin-bottom-3 {
  margin-bottom: 72px;
  margin-bottom: 4.23529rem;
}
.main-bg {
  background-color: $concrete;
}
.section-contact {
  .footer-contained {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    .colored-box__text {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .colored-box.contained {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    .colored-box__text {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.footer__wrapper {
  background-image: url(../images/imop_footer.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.comment__section {
  margin: 24px 0;
  margin: 1.41176rem 0;
}
.comments {
  margin: 24px 0;
  margin: 1.41176rem 0;
}
.comment__title {
  margin: 0;
}
.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}
.comment--preview {
  background-color: $color_scotch_mist_approx;
}
.comment-preview {
  background-color: $color_scotch_mist_approx;
}
.comment--nested {
  margin-left: 34px;
}
.indented {
  margin-left: 34px;
}
.speakers {
  padding-top: 72px;
  padding-top: 4.23529rem;
  background-color: $concrete;
  .pane-title {
    font-size: 40px;
    line-height: 36px;
    font-size: 2.35294rem;
    line-height: 2.11765rem;
    margin-bottom: 0;
    margin-bottom: 0;
    padding-top: 48px;
    color: $white;
    font-family: Averta-Bold;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 65px;
    max-width: 350px;
    position: relative;
    text-align: center;
    max-width: none;
    &:before {
      left: 50%;
      margin-left: -85px;
    }
  }
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  &:first-child {
    padding-top: 96px;
    padding-top: 5.64706rem;
  }
  &:last-child {
    // padding-bottom: 216px;
    // padding-bottom: 12.70588rem;
  }
}
.sponsors {
  padding-bottom: 0;
  padding-bottom: 0;
  h3 {
    font-size: 40px;
    line-height: 36px;
    font-size: 2.35294rem;
    line-height: 2.11765rem;
    margin-bottom: 0;
    margin-bottom: 0;
    padding-top: 48px;
    color: $white;
    font-family: Averta-Bold;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 65px;
    max-width: 350px;
    position: relative;
    text-align: center;
    max-width: none;
    font-size: 28px;
    line-height: 36px;
    font-size: 1.64706rem;
    line-height: 2.11765rem;
    max-width: none;
    padding-top: 34px;
    font-family: Averta-Bold;
    font-size: 21.78px;
    color: $color_carnation_approx;
    margin-top: 24px;
    margin-top: 1.41176rem;
    width: 100%;
    float: left;
    &:before {
      left: 50%;
      margin-left: -85px;
    }
  }
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
.title {
  font-size: 40px;
  line-height: 36px;
  font-size: 2.35294rem;
  line-height: 2.11765rem;
  margin-bottom: 0;
  margin-bottom: 0;
  padding-top: 48px;
  color: $white;
  font-family: Averta-Bold;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 65px;
  max-width: 350px;
  position: relative;
}
@media(min-width: 410px) {
  .title {
    font-size: 54px;
  }
  .speakers .pane-title {
    font-size: 54px;
  }
  .sponsors h3 {
    font-size: 54px;
  }
}
@media(min-width: 0) and(max-width: 780px) {
  .title {
    margin-bottom: 24px;
    margin-bottom: 1.41176rem;
    max-width: none;
  }
  .speakers__inside {
    padding-top: 0;
    padding-top: 0;
  }
  .view-facilities-hotels {
    padding-top: 0;
    padding-top: 0;
  }
  .view-speakers {
    padding-top: 0;
    padding-top: 0;
  }
  .padding-4 {
    padding-top: 48px;
    padding-top: 2.82353rem;
  }
  .speakers .pane-title {
    margin-bottom: 24px;
    margin-bottom: 1.41176rem;
    max-width: none;
  }
  .sponsors h3 {
    margin-bottom: 24px;
    margin-bottom: 1.41176rem;
    max-width: none;
  }
}
.title__wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
}
.title--red {
  color: $color_carnation_approx;
}
.title--panel {
  margin: auto;
}
.title--panel-location {
  padding-right: 10%;
  padding-bottom: 12%;
}
.title--centered {
  text-align: center;
  max-width: none;
  &:before {
    left: 50%;
    margin-left: -85px;
  }
}
.title--medium {
  font-size: 28px;
  line-height: 36px;
  font-size: 1.64706rem;
  line-height: 2.11765rem;
  max-width: none;
  padding-top: 34px;
  font-family: Averta-Bold;
  font-size: 21.78px;
  color: $color_carnation_approx;
}
.node--bg {
  background-color: $color_carnation_approx;
  color: $white;
  .title {
    color: $white;
    &:before {
      background-color: $white;
    }
  }
}
.title--bg {
  color: $white;
  &:before {
    background-color: $white;
  }
}
.editable-page {
  margin-top: 24px;
  margin-top: 1.41176rem;
  img {
    margin: auto;
    width: 75%;
  }
  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  a.image-left {
    font-family: Averta-Regular;
    letter-spacing: 0.25px;
    font-size: 16px;
    border-bottom: 1px solid transparent;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    padding-bottom: 3px;
    &:hover {
      border-color: $color_carnation_approx;
    }
  }
}
.editable-page__supertitle {
  font-family: Averta-Bold;
  font-size: 21.78px;
  line-height: 26.14px;
  color: $color_carnation_approx;
  margin-top: 64px;
  margin-bottom: 40px;
}
.editable-page__image-wrapper {
  display: none;
  background-color: $color_cadet_blue_approx;
}
@media(min-width: 880px) {
  .editable-page__image-wrapper {
    display: flex;
  }
  .header__logo {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(1);
    margin: 0 auto;
    padding-top: 53px;
  }
}
.editable-page__text-wrapper {
  width: 100%;
}
.editable-page__title {
  background-color: $color_blue_bayoux_approx;
  color: $white;
  padding: 15px 32px;
  font-family: Averta-Bold;
  font-size: 28px;
  line-height: 36px;
}
.editable-page__text {
  background-color: $color_mercury_approx;
  font-family: Averta-Regular;
  padding: 29px 32px;
  font-size: 17px;
  line-height: 24px;
}
.editable-page--red {
  .editable-page__image-wrapper {
    background-color: $color_your_pink_approx;
  }
  .editable-page__title {
    background-color: $color_carnation_approx;
  }
  .editable-page__text {
    background-color: $color_cinderella_approx;
  }
}
.editable-page--green {
  .editable-page__image-wrapper {
    background-color: $color_moss_green_approx;
  }
  .editable-page__title {
    background-color: $color_de_york_approx;
  }
  .editable-page__text {
    background-color: $color_frostee_approx;
  }
}
.editable-page--no-top-padding {
  padding-top: 0;
}
.editable-page--distance-top {
  margin-top: 95px;
}
.editable-page--distance-bottom {
  margin-bottom: 70px;
}
.footer-item {
  padding-bottom: 36px;
  padding-bottom: 2.11765rem;
  padding-top: 44px;
  max-width: 290px;
  .form-item {
    margin: 0 0 17px;
  }
}
@media(min-width: 501px) and(max-width: 780px) {
  .footer-item {
    padding-top: 34px;
    padding-bottom: 0;
    max-width: 100%;
  }
  .footer-item__textfield {
    width: 100%;
  }
  .footer-item__submit {
    width: 100px;
    margin-top: 6px;
  }
}
.footer-item__fixed-width {
  width: 196px;
}
.footer-item__title {
  font-size: 17px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.41176rem;
  font-family: Averta-Regular;
  color: $color_carnation_approx;
  margin-bottom: 6px;
}
.footer-item__text {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 16px;
  line-height: 16.8px;
  font-size: 0.94118rem;
  line-height: 0.98824rem;
  color: $white;
  margin-bottom: 6px;
  margin-bottom: 0.35294rem;
  line-height: 30px;
}
.footer-item__link {
  color: $white;
  &:focus {
    color: $color_carnation_approx;
  }
  &:hover {
    color: $color_carnation_approx;
  }
}
// .icons-sprite {
//   //background-image: url(../sass/components/footer-item/icons-sb6f5af8fb1.png);
//   background-repeat: no-repeat;
// }
// .footer-item__link-facebook {
//   background-image: url(../sass/components/footer-item/icons-sb6f5af8fb1.png);
//   background-repeat: no-repeat;
//   background-position: 0 0;
// }
// .footer-item__link-twitter {
//   background-image: url(../sass/components/footer-item/icons-sb6f5af8fb1.png);
//   background-repeat: no-repeat;
//   background-position: 0 -103px;
// }
// .footer-item__link-linkedin {
//   background-image: url(../sass/components/footer-item/icons-sb6f5af8fb1.png);
//   background-repeat: no-repeat;
//   background-position: 0 -51px;
// }
// .footer-item__link-youtube {
//   background-image: url(../sass/components/footer-item/icons-sb6f5af8fb1.png);
//   background-repeat: no-repeat;
//   background-position: 0 -120px;
// }
// .footer-item__link-googleplus {
//   background-image: url(../sass/components/footer-item/icons-sb6f5af8fb1.png);
//   background-repeat: no-repeat;
//   background-position: 0 -34px;
// }
// .footer-item__link-pinterest {
//   background-image: url(../sass/components/footer-item/icons-sb6f5af8fb1.png);
//   background-repeat: no-repeat;
//   background-position: 0 -85px;
// }
.footer-item__link-social {
  color: $white;
  display: inline-block;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-bottom: 0.70588rem;
  line-height: 17px;
  * {
    padding: 10px;
  }
}
.footer-item__textfield {
  width: 71.5%;
  float: left;
  padding: 10px 12px;
  height: 35px;
  border: 1px solid $color_celeste_approx;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 16px;
  line-height: 16px;
}
.footer-item__submit {
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.4s ease-out;
  width: 27.1%;
  float: right;
  padding: 10px 12px;
  height: 35px;
  border: 1px solid $color_celeste_approx;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: $white;
  background-color: $color_emperor_approx;
  &:focus {
    background-color: $color_carnation_approx;
    color: $white;
  }
  &:hover {
    background-color: $color_carnation_approx;
    color: $white;
  }
}
.footer-item_small {
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  font-size: 13px;
  line-height: 28px;
  color: $white;
  margin-bottom: 24px;
  margin-bottom: 1.41176rem;
}
.footer-item_small-link {
  color: $color_carnation_approx;
  &:hover {
    color: $color_carnation_approx;
  }
}
.form-item {
  margin: 24px 0;
  margin: 1.41176rem 0;
  .description {
    font-size: 16px;
    font-size: 0.94118rem;
  }
  &.is-error {
    .form-item__widget {
      border: 1px solid $color_guardsman_red_approx;
    }
    input {
      border: 1px solid $color_guardsman_red_approx;
    }
    textarea {
      border: 1px solid $color_guardsman_red_approx;
    }
    select {
      border: 1px solid $color_guardsman_red_approx;
    }
  }
  .form-item--radio .description {
    margin-left: 1.4em;
  }
  .form-type-radio .description {
    margin-left: 1.4em;
  }
  .form-type-checkbox .description {
    margin-left: 1.4em;
  }
  input.error {
    border: 1px solid $color_guardsman_red_approx;
  }
  textarea.error {
    border: 1px solid $color_guardsman_red_approx;
  }
  select.error {
    border: 1px solid $color_guardsman_red_approx;
  }
}
.form-item__required {
  color: $color_guardsman_red_approx;
}
.form-required {
  color: $color_guardsman_red_approx;
}
.form-item__description {
  font-size: 16px;
  font-size: 0.94118rem;
}
.form-item--inline {
  div {
    display: inline;
  }
  label {
    display: inline;
  }
}
.container-inline {
  .form-item {
    div {
      display: inline;
    }
    label {
      display: inline;
    }
  }
  .fieldset-wrapper {
    display: block;
  }
}
.form-item--inline__exception {
  display: block;
}
.form-item--tight {
  margin: 0;
}
.form-item--radio {
  margin: 0;
  .form-item__label {
    display: inline;
    font-weight: normal;
  }
  .form-item__description {
    margin-left: 1.4em;
  }
  .form-item .description {
    margin-left: 1.4em;
  }
}
.form-type-radio {
  margin: 0;
  .form-item__label {
    display: inline;
    font-weight: normal;
  }
  .description {
    margin-left: 1.4em;
  }
  .form-item__description {
    margin-left: 1.4em;
  }
  .form-item .description {
    margin-left: 1.4em;
  }
}
.form-type-checkbox {
  margin: 0;
  .form-item__label {
    display: inline;
    font-weight: normal;
  }
  .description {
    margin-left: 1.4em;
  }
  .form-item__description {
    margin-left: 1.4em;
  }
  .form-item .description {
    margin-left: 1.4em;
  }
}
.password-parent {
  margin: 0;
}
.confirm-parent {
  margin: 0;
}
.form-item--radio__label {
  display: inline;
  font-weight: normal;
}
.form-item--radio__description {
  margin-left: 1.4em;
}
.form-item-is-error__widget {
  border: 1px solid $color_guardsman_red_approx;
}
.form-item-is-error {
  input {
    border: 1px solid $color_guardsman_red_approx;
  }
  textarea {
    border: 1px solid $color_guardsman_red_approx;
  }
  select {
    border: 1px solid $color_guardsman_red_approx;
  }
}
.form-table__sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: $white;
  &.is-sticky {
    visibility: visible;
  }
}
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: $white;
}
.form-table__sticky-header-is-sticky {
  visibility: visible;
}
.form-table__header {
  border-bottom: 3px solid $color_celeste_approx;
  padding-right: 1em;
  text-align: left;
}
.form-table {
  th {
    border-bottom: 3px solid $color_celeste_approx;
    padding-right: 1em;
    text-align: left;
    &.form-table__narrow-column {
      text-align: center;
    }
  }
  tbody {
    border-top: 1px solid $color_celeste_approx;
    tr {
      padding: .1em .6em;
      border-bottom: 1px solid $color_celeste_approx;
      background-color: $color_gallery_approx;
      &:nth-child(even) {
        background-color: $white;
      }
    }
  }
  ul {
    margin: 0;
  }
  [dir="rtl"] th {
    text-align: right;
    padding-left: 1em;
    padding-right: 0;
  }
}
.form-table__body {
  border-top: 1px solid $color_celeste_approx;
}
.form-table__row {
  padding: .1em .6em;
  border-bottom: 1px solid $color_celeste_approx;
  background-color: $color_gallery_approx;
}
.form-table__row-even {
  background-color: $white;
}
.form-table__list {
  margin: 0;
}
.form-table__narrow-column {
  text-align: center;
}
tr {
  &.drag {
    background-color: $color_scotch_mist_approx;
  }
  &.drag-previous {
    background-color: $color_lemon_chiffon_approx;
  }
  &.selected td {
    background: $color_scotch_mist_approx;
  }
  .ajax-progress .throbber {
    margin: 0 2px;
  }
}
.form {
  //background-color: $color_carnation_approx;
  form {
    margin: 0 auto;
    max-width: 950px;
    color: $white;
  }
  .form-item {
    width: 50%;
    float: left;
    padding: 10px 15px;
    margin: 0;
  }
  .webform-component-managed_file {
    clear: both;
    padding-top: 0;
    color: $color_geyser_approx;
    font-size: 17px;
  }
  .webform-component-textarea {
    clear: both;
    width: 100%;
  }
  input[type="email"] {
    border: 1px solid $color_jungle_mist_approx;
    background-color: transparent;
    height: 45px;
    padding: 8px 10px;
    color: $color_geyser_approx;
    font-size: 17px;
    &.error {
      border-color: $color_guardsman_red_approx;
    }
  }
  input[type="text"] {
    border: 1px solid $color_jungle_mist_approx;
    background-color: transparent;
    height: 45px;
    padding: 8px 10px;
    color: $color_geyser_approx;
    font-size: 17px;
    &.error {
      border-color: $color_guardsman_red_approx;
    }
  }
  textarea {
    border: 1px solid $color_jungle_mist_approx;
    background-color: transparent;
    height: 45px;
    padding: 8px 10px;
    color: $color_geyser_approx;
    font-size: 17px;
    width: 100%;
    height: 170px;
    &.error {
      border-color: $color_guardsman_red_approx;
    }
  }
  .description {
    font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
    font-size: 15px;
    line-height: 21px;
    margin-top: 15px;
    color: $color_geyser_approx;
  }
  select {
    border: 1px solid $white;
    color: $white;
    background: transparent;
    width: 100%;
    padding: 8px 35px 8px 8px;
    height: 45px;
    appearance: none;
    background: url(../sass/components/form/select-arrow.png?1539002441) 96% 50% no-repeat transparent;
  }
  .form-required {
    font-family: Averta-Regular;
    font-size: 18px;
    line-height: 36px;
    font-size: 1.05882rem;
    line-height: 2.11765rem;
    letter-spacing: 0.25px;
    color: $white;
    font-weight: normal;
  }
  label {
    font-family: Averta-Regular;
    font-size: 18px;
    line-height: 36px;
    font-size: 1.05882rem;
    line-height: 2.11765rem;
    letter-spacing: 0.25px;
    color: $white;
    font-weight: normal;
  }
  #edit-submitted-proof-of-payment-upload-button {
    display: none;
  }
  #edit-submitted-proof-of-payment-upload-button--2 {
    display: none;
  }
  .form-actions {
    clear: both;
    padding: 17px 15px 72px;
    text-align: right;
  }
  .form-submit {
    padding: 15px 38px;
    border: 0 solid $color_chicago_approx;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    background-color: $color_punch_approx;
    font-family: Averta-Regular;
    font-size: 18px;
    line-height: 36px;
    font-size: 1.05882rem;
    line-height: 2.11765rem;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: $color_chicago_approx;
    text-transform: uppercase;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    &:hover {
      color: $color_carnation_approx;
      background-color: $white;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.4s ease-out;
    }
  }
}
.form__text {
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-top: 1.41176rem;
  color: $white;
  h3 {
    margin-top: 24px;
    margin-top: 1.41176rem;
    color: $color_rangoon_green_approx;
    font-family: Averta-Regular;
    font-size: 21px;
    line-height: 24px;
    font-size: 1.23529rem;
    line-height: 1.41176rem;
    letter-spacing: 0.5px;
  }
  p {
    margin: 0;
  }
  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.form__title {
  margin-top: 72px;
  margin-top: 4.23529rem;
  color: $color_rangoon_green_approx;
  font-family: Averta-Regular;
  font-size: 21px;
  line-height: 24px;
  font-size: 1.23529rem;
  line-height: 1.41176rem;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
}
.gallery {
  padding-top: 96px;
  padding-top: 5.64706rem;
  padding-bottom: 240px;
  padding-bottom: 14.11765rem;
}
#google-map {
  height: 750px;
  background-color: $color_chicago_approx;
}
@media(min-width: 480px) and(max-width: 780px) {
  #google-map {
    height: 550px;
  }
  .article-teaser__body {
    font-size: 16px;
    line-height: 21px;
  }
  .speakers__subtitle {
    font-size: 16px;
    line-height: 21px;
  }
}
.header__logo {
  padding: 0 10px 0 0;
  display: block;
  position: relative;
  top: 0;
  left: 1px;
  z-index: 101;
  //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
  transform: scale(0.7);
  width: 206px;
  height: 141px;
  margin: 0 auto;
  margin-right: -28px;
  margin-top: -10px;
  img {
    z-index: -1;
  }
}
@media(min-width: 576px) {
  .header__logo {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(0.8);
    margin: 0 auto;
  }
}
@media(min-width: 1090px) {
  .header__logo {
    margin: 0;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: scale(1);
    padding-top: 53px;
    z-index: 96;
  }
}
.header__logo-image {
  vertical-align: bottom;
}
.header__name-and-slogan {
  float: left;
}
.header__site-link {
  &:link {
    color: $color_rangoon_green_approx;
    text-decoration: none;
  }
  &:visited {
    color: $color_rangoon_green_approx;
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
}
.header__site-slogan {
  margin: 0;
}
.header__secondary-menu {
  float: right;
}
.header__region {
  clear: both;
}
.hidden {
  display: none;
}
.hidden-for-anonymous {
  display: none;
}
.highlight-mark {
  color: $color_guardsman_red_approx;
  background-color: transparent;
}
.new {
  color: $color_guardsman_red_approx;
  background-color: transparent;
}
.update {
  color: $color_guardsman_red_approx;
  background-color: transparent;
}
.image-link {
  line-height: 0;
}
.image-link--overlay-hover {
  position: relative;
  a {
    &:hover {
      &:before {
        opacity: 0.8;
      }
      &:after {
        font-size: 108px;
        opacity: 1;
      }
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: $color_carnation_approx;
      opacity: 0;
      z-index: 10;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.4s ease-out;
    }
    &:after {
      content: ' +';
      font-size: 78px;
      line-height: 78px;
      color: $white;
      text-align: center;
      display: block;
      position: absolute;
      width: 100%;
      top: 50%;
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: translateY(-50%);
      opacity: 0;
      z-index: 11;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.4s ease-out;
    }
  }
}
.inline-links {
  padding: 0;
}
.inline-links__item {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}
.inline-sibling {
  display: inline;
  margin-right: 10px;
  * {
    display: inline;
  }
  + * {
    display: inline;
    > *:first-child {
      display: inline;
    }
  }
}
.field__label-inline {
  display: inline;
  margin-right: 10px;
  * {
    display: inline;
  }
  + * {
    display: inline;
    > *:first-child {
      display: inline;
    }
  }
}
.inline-sibling__child {
  display: inline;
}
.inline-sibling__adjacent {
  display: inline;
}
.field__items-inline {
  display: inline;
}
.link-tile {
  background-size: cover;
  margin-top: -36px;
  margin-top: -2.11765rem;
  margin-bottom: 24px;
  margin-bottom: 1.41176rem;
  &:hover .link-tile__link {
    opacity: 1;
    top: 0;
  }
}
.link-title__link-to-content {
  height: 400px;
  padding-top: 320px;
  display: block;
  color: $white;
}
.link-tile__title {
  font-size: 36px;
  line-height: 36px;
  font-size: 2.11765rem;
  line-height: 2.11765rem;
  text-align: center;
  font-family: Averta-Regular;
  letter-spacing: 0.25px;
  color: $white;
  padding-left: 15px;
  padding-right: 15px;
}
.link-tile__link {
  margin-top: -5px;
  text-align: center;
  font-family: Arial, Helvetica, Nimbus Sans L, sans-serif;
  opacity: 0;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.4s ease-out;
  position: relative;
  top: 20px;
}
.panel-2col-stacked {
  .panel-col-top .inside {
    margin: 0;
  }
  .panel-col-last .inside {
    margin: 0 0 0 15px;
  }
  .panel-col-first .inside {
    margin: 0 15px 0 0;
  }
}
.messages {
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 10px 10px 10px 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid $color_sulu_approx;
  background-color: $color_sugar_cane_approx;
  color: $color_green_house_approx;
  &.warning {
    margin: 24px 0;
    margin: 1.41176rem 0;
    padding: 10px 10px 10px 50px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
    background-position: 8px 8px;
    background-repeat: no-repeat;
    border: 1px solid $color_sulu_approx;
    background-color: $color_sugar_cane_approx;
    color: $color_green_house_approx;
    background-color: $color_scotch_mist_approx;
    color: $color_pueblo_approx;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACuElEQVRIiWP4//8/Ay0xSYqntTpnT252zqeJBf0Njhsykrz/pyd6/e9vcNpGVQv6q2wlm0qc/r0+IPD/3UG+/61l9v9mdrjIUc2C7hqHUzc3S///eZwBjO9tF/vfWe1wjioWTKixVm8otPn38wQT3IKfxxn/t5Va/utpsNSg2ILWcttrNzdJgQ3+dpQRjEHs+9tE/zeXWt+gyILOamuTqlxrsOtPLub+7+emBsSq/88v5wL7oqHQ9H9nmbkF2RbUF1rev7lJEuziuU3i/90ddcB4UZsoJC62ifyvK7R4QJYFrcUGrmUZ5v9hYb9hosh/bzcDMN42VRgeF9W5hv8bi/XdSbagKtfs2c1NEvCIPbaQ/7+/pwkYn17Ki0hR24T/l2eZPCfJgsZ83dCiNOP/yCnn7iau/8G+5mD8aBsHSoqqyNL9X5erHUm0BcVpRm9ubhZHMoTh/4eDzP/DA23+RwTZ/P96hAlF7t5Wof8FyfpvibKgNk8noyDZ4D9quofg1Bjr/1kJlhjiIF+Upmn/r83RzCJoQXaC3qcbm8SwGMLwvybP/H8jMGlik7u7VeB/Zqz2J7wWVGdr1uTG62J1PQgfWST1/+hiCaxyIF8UJqv9r8hQrcVpQVqkzrcbG0WwGvB2H/P/lnx5MAaxsam5vYn3f2KY+jesFpSlqfZnxWjidP2OGWL/g/0swBjExu4Lhv958Ur/i5KU+lEsCA1lYI4JUv95bZ0gTo2Pt3P+z0myBmMQG5e6mxu4/kf4Kf8EmQm3oCRNebKrvSawIGPBqRG9sMOp5hjjfwdrlf/58bKT4RaUpWvtcLZV/39iscD/H0AFP46jYwYiMeP/44u4/9tbKQODSXUH3II9G7v18hI0n8YGKv+IDVT6joxj/BVx4mgcOCde/SnITPRUJAHEGlTCEkQV19TAAN8FC67hZdFXAAAAAElFTkSuQmCC");
    border-color: $color_confetti_approx;
  }
  &.error {
    margin: 24px 0;
    margin: 1.41176rem 0;
    padding: 10px 10px 10px 50px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
    background-position: 8px 8px;
    background-repeat: no-repeat;
    border: 1px solid $color_sulu_approx;
    background-color: $color_sugar_cane_approx;
    color: $color_green_house_approx;
    background-color: $provincial_pink;
    color: $color_pueblo_approx;
    border-color: $color_flamingo_approx;
    display: block;
    max-width: 920px;
    margin: 0 auto;
    background: $provincial_pink;
    .error {
      color: $color_rangoon_green_approx;
    }
  }
  &.clientside-error {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.messages--warning {
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 10px 10px 10px 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid $color_sulu_approx;
  background-color: $color_sugar_cane_approx;
  color: $color_green_house_approx;
  background-color: $color_scotch_mist_approx;
  color: $color_pueblo_approx;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACuElEQVRIiWP4//8/Ay0xSYqntTpnT252zqeJBf0Njhsykrz/pyd6/e9vcNpGVQv6q2wlm0qc/r0+IPD/3UG+/61l9v9mdrjIUc2C7hqHUzc3S///eZwBjO9tF/vfWe1wjioWTKixVm8otPn38wQT3IKfxxn/t5Va/utpsNSg2ILWcttrNzdJgQ3+dpQRjEHs+9tE/zeXWt+gyILOamuTqlxrsOtPLub+7+emBsSq/88v5wL7oqHQ9H9nmbkF2RbUF1rev7lJEuziuU3i/90ddcB4UZsoJC62ifyvK7R4QJYFrcUGrmUZ5v9hYb9hosh/bzcDMN42VRgeF9W5hv8bi/XdSbagKtfs2c1NEvCIPbaQ/7+/pwkYn17Ki0hR24T/l2eZPCfJgsZ83dCiNOP/yCnn7iau/8G+5mD8aBsHSoqqyNL9X5erHUm0BcVpRm9ubhZHMoTh/4eDzP/DA23+RwTZ/P96hAlF7t5Wof8FyfpvibKgNk8noyDZ4D9quofg1Bjr/1kJlhjiIF+Upmn/r83RzCJoQXaC3qcbm8SwGMLwvybP/H8jMGlik7u7VeB/Zqz2J7wWVGdr1uTG62J1PQgfWST1/+hiCaxyIF8UJqv9r8hQrcVpQVqkzrcbG0WwGvB2H/P/lnx5MAaxsam5vYn3f2KY+jesFpSlqfZnxWjidP2OGWL/g/0swBjExu4Lhv958Ur/i5KU+lEsCA1lYI4JUv95bZ0gTo2Pt3P+z0myBmMQG5e6mxu4/kf4Kf8EmQm3oCRNebKrvSawIGPBqRG9sMOp5hjjfwdrlf/58bKT4RaUpWvtcLZV/39iscD/H0AFP46jYwYiMeP/44u4/9tbKQODSXUH3II9G7v18hI0n8YGKv+IDVT6joxj/BVx4mgcOCde/SnITPRUJAHEGlTCEkQV19TAAN8FC67hZdFXAAAAAElFTkSuQmCC");
  border-color: $color_confetti_approx;
}
.messages--error {
  margin: 24px 0;
  margin: 1.41176rem 0;
  padding: 10px 10px 10px 50px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=");
  background-position: 8px 8px;
  background-repeat: no-repeat;
  border: 1px solid $color_sulu_approx;
  background-color: $color_sugar_cane_approx;
  color: $color_green_house_approx;
  background-color: $provincial_pink;
  color: $color_pueblo_approx;
  border-color: $color_flamingo_approx;
  display: block;
  max-width: 920px;
  margin: 0 auto;
  background: $provincial_pink;
  .error {
    color: $color_rangoon_green_approx;
  }
}
.messages--ok-color {
  background-color: $color_sugar_cane_approx;
  color: $color_green_house_approx;
}
.ok {
  background-color: $color_sugar_cane_approx;
  color: $color_green_house_approx;
}
.revision-current {
  background-color: $color_sugar_cane_approx;
  color: $color_green_house_approx;
}
.messages--warning-color {
  background-color: $color_scotch_mist_approx;
  color: $color_pueblo_approx;
}
.warning {
  background-color: $color_scotch_mist_approx;
  color: $color_pueblo_approx;
}
.messages--error-color {
  background-color: $provincial_pink;
  color: $color_pueblo_approx;
}
.error {
  background-color: $provincial_pink;
  color: $color_pueblo_approx;
}
.messages--error__highlighted-text {
  color: $color_rangoon_green_approx;
}
.messages__list {
  margin: 0;
}
.messages__item {
  list-style-image: none;
}
.pane-page-messages {
  background-color: $color_carnation_approx;
  padding-top: 24px;
  padding-top: 1.41176rem;
  padding-bottom: 24px;
  padding-bottom: 1.41176rem;
}
.more-link {
  text-align: right;
}
.more-help-link {
  text-align: right;
  a {
    padding: 1px 0 1px 20px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7UlEQVR42qWTPQqDQBCFcwSPkCNITpAj5AjeIm1uYpkyR7Cy2Mot7OwsBAsRwUKwmOwLGRle3EIy8PyBfZ/z3J2TiPylz8VWWZZpUB40BonRKyizaxkA88MYYiqCEgv4MTvnZJom0VqWRbz3FlJZgLYtqmEY1Lg9r+sKsIXcLSC3AC019H0vqLquLeC5AfiHYSGkcdAJimKIBQiJ4+CO92OAtm0FNc8zOjkMwE5Q63FAtbeg6zpAYvG8BWR7i5qmQYwY4MIHqYhE2DOPQWcGJBQF2XU72ZzyUeZ5GCNt5/hybJgYdAXsq5sOEE/jG6dC5IOqCXTmAAAAAElFTkSuQmCC");
    background-position: 0 50%;
    background-repeat: no-repeat;
  }
  [dir="rtl"] a {
    padding: 1px 20px 1px 0;
    background-position: 100% 50%;
  }
}
.more-link__help-icon {
  padding: 1px 0 1px 20px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA7UlEQVR42qWTPQqDQBCFcwSPkCNITpAj5AjeIm1uYpkyR7Cy2Mot7OwsBAsRwUKwmOwLGRle3EIy8PyBfZ/z3J2TiPylz8VWWZZpUB40BonRKyizaxkA88MYYiqCEgv4MTvnZJom0VqWRbz3FlJZgLYtqmEY1Lg9r+sKsIXcLSC3AC019H0vqLquLeC5AfiHYSGkcdAJimKIBQiJ4+CO92OAtm0FNc8zOjkMwE5Q63FAtbeg6zpAYvG8BWR7i5qmQYwY4MIHqYhE2DOPQWcGJBQF2XU72ZzyUeZ5GCNt5/hybJgYdAXsq5sOEE/jG6dC5IOqCXTmAAAAAElFTkSuQmCC");
  background-position: 0 50%;
  background-repeat: no-repeat;
}
.nav-menu__item {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAD1BMVEX///+/v7+Li4sAAADAwMBFvsw8AAAAAXRSTlMAQObYZgAAAB1JREFUCFtjYAADYwMGBmYVZSDhKAwkFJWhYiAAAB2+Aa/9ugeaAAAAAElFTkSuQmCC");
  list-style-type: square;
  &.is-expanded {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
    list-style-type: circle;
  }
  &.is-collapsed {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
    list-style-type: disc;
  }
}
.menu__item {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHBAMAAAA2fErgAAAAD1BMVEX///+/v7+Li4sAAADAwMBFvsw8AAAAAXRSTlMAQObYZgAAAB1JREFUCFtjYAADYwMGBmYVZSDhKAwkFJWhYiAAAB2+Aa/9ugeaAAAAAElFTkSuQmCC");
  list-style-type: square;
  &.is-expanded {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
    list-style-type: circle;
  }
  &.is-collapsed {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
    list-style-type: disc;
  }
}
.nav-menu__item-is-expanded {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  list-style-type: circle;
}
.nav-menu__item-is-collapsed {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABFJREFUCB1jVmCGQClmEWYOAAZ8AMy3HPLXAAAAAElFTkSuQmCC");
  list-style-type: disc;
}
.nav-menu__link-is-active {
  color: $color_rangoon_green_approx;
}
.navbar {
  padding: 85px 0;
  text-align: right;
  margin-top: 0;
  margin-bottom: 0;
  li {
    display: inline-block;
    padding: 0 25px 0 0;
    list-style-type: none;
    list-style-image: none;
    font-family: Averta-Bold;
    font-size: 14px;
    line-height: 24px;
    font-size: 0.82353rem;
    line-height: 1.41176rem;
    &:last-child {
      padding-right: 0;
    }
    a {
      color: $white;
      padding-bottom: 3px;
      border-bottom: 1px solid transparent;
      //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
      transition: all 0.4s ease-out;
      &.active-trail {
        border-bottom: 1px solid $white;
      }
      &.active {
        border-bottom: 1px solid $white;
      }
      &:focus {
        border-bottom: 1px solid $white;
      }
      &:hover {
        border-bottom: 1px solid $white;
      }
    }
  }
}
.navbar__item {
  display: inline-block;
  padding: 0 25px 0 0;
  list-style-type: none;
  list-style-image: none;
  font-family: Averta-Bold;
  font-size: 14px;
  line-height: 24px;
  font-size: 0.82353rem;
  line-height: 1.41176rem;
  &:last-child {
    padding-right: 0;
  }
  a {
    color: $white;
    padding-bottom: 3px;
    border-bottom: 1px solid transparent;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    &.active-trail {
      border-bottom: 1px solid $white;
    }
    &.active {
      border-bottom: 1px solid $white;
    }
    &:focus {
      border-bottom: 1px solid $white;
    }
    &:hover {
      border-bottom: 1px solid $white;
    }
  }
}
.pager {
  clear: both;
  padding: 0;
  text-align: center;
}
.pager__item {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}
.pager__current-item {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
  font-weight: bold;
}
.pager-current {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
  font-weight: bold;
}
.pager-item {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}
.pager-first {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}
.pager-previous {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}
.pager-next {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}
.pager-last {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}
.pswp__bg {
  background-color: $white;
}
.pswp__top-bar {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  background-color: transparent;
}
.pswp__ui--fit {
  .pswp__top-bar {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    background-color: transparent;
  }
  .pswp__caption {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    opacity: 0.7;
    background-color: $white;
  }
}
.pswp__caption {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  background-color: $white;
}
.pswp__caption__center {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  text-align: center;
  max-width: 80%;
  background: transparent;
  color: $color_emperor_approx;
}
.pswp__counter {
  color: $white;
  background-color: $black;
}
.progress-bar {
  font-weight: bold;
}
.progress {
  font-weight: bold;
  .bar {
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    margin: 0 .2em;
    border: 1px solid $color_storm_dust_approx;
    background-color: $color_celeste_approx;
  }
  .filled {
    height: 1.5em;
    width: 5px;
    background: $color_lochmara_approx url(../sass/components/progress-bar/progress-bar.gif?1539002460) repeat 0 0;
  }
  .percentage {
    float: right;
  }
  [dir="rtl"] .percentage {
    float: left;
  }
}
.progress-bar__bar {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid $color_storm_dust_approx;
  background-color: $color_celeste_approx;
}
.progress-bar__fill {
  height: 1.5em;
  width: 5px;
  background: $color_lochmara_approx url(../sass/components/progress-bar/progress-bar.gif?1539002460) repeat 0 0;
}
.progress-bar__percentage {
  float: right;
}
.progress-bar--inline {
  width: 16em;
  display: inline-block;
}
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}
.progress-throbber {
  display: inline-block;
}
.ajax-progress {
  display: inline-block;
  .throbber {
    background: url(../sass/components/progress-throbber/progress-throbber.gif?1539002454) no-repeat 0 -18px transparent;
    float: left;
    height: 15px;
    margin: 2px;
    width: 15px;
  }
  .message {
    padding-left: 20px;
  }
  [dir="rtl"] .throbber {
    float: right;
  }
}
.progress-throbber__widget {
  background: url(../sass/components/progress-throbber/progress-throbber.gif?1539002454) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}
.progress-throbber__widget-in-tr {
  margin: 0 2px;
}
.progress-throbber__message {
  padding-left: 20px;
}
.resizable-textarea {
  width: 100%;
  vertical-align: bottom;
  .grippie {
    background: url(../sass/components/resizable-textarea/grippie.png?1539002453) no-repeat center 2px $color_gallery_approx;
    border: 1px solid $color_celeste_approx;
    border-top-width: 0;
    cursor: s-resize;
    height: 9px;
    overflow: hidden;
  }
}
.resizable-textarea__grippie {
  background: url(../sass/components/resizable-textarea/grippie.png?1539002453) no-repeat center 2px $color_gallery_approx;
  border: 1px solid $color_celeste_approx;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}
.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.media-youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.responsive-video__embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-video--4-3 {
  padding-bottom: 75%;
}
#sidr-0-button {
  font-size: 16px;
  line-height: 24px;
  font-size: 0.94118rem;
  line-height: 1.41176rem;
  font-family: Averta-Regular;
  color: $white;
  position: relative;
  padding-left: 68px;
  padding-top: 38px;
  display: inline-block;
  text-transform: uppercase;
  &:before {
    content: '';
    //background-image: url(../sass/components/sidr/hamburger.png?1539002461);
    width: 27px;
    height: 21px;
    position: absolute;
    left: 30px;
    bottom: 5px;
  }
}
.sidr {
  display: none;
  position: absolute;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  overflow-x: none;
  overflow-y: auto;
  font-family: Averta-Regular;
  font-size: 16px;
  line-height: 19px;
  background: $color_carnation_approx;
  color: $white;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  .sidr-inner {
    padding: 0 0 15px;
    > p {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  &.right {
    left: auto;
    right: -260px;
  }
  &.left {
    left: -260px;
    right: auto;
  }
  h1 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: $white;
    line-height: 24px;
    background-image: none;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
  h2 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: $white;
    line-height: 24px;
    background-image: none;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
  h3 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: $white;
    line-height: 24px;
    background-image: none;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
  h4 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: $white;
    line-height: 24px;
    background-image: none;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
  h5 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: $white;
    line-height: 24px;
    background-image: none;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
  h6 {
    font-size: 11px;
    font-weight: 400;
    padding: 0 15px;
    margin: 0 0 5px;
    color: $white;
    line-height: 24px;
    background-image: none;
    //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    box-shadow: none;
  }
  p {
    font-size: 13px;
    margin: 0 0 12px;
    a {
      color: $white;
    }
  }
  > p {
    margin-left: 15px;
    margin-right: 15px;
  }
  ul {
    display: block;
    margin: 0 0 15px;
    padding: 0;
    padding-top: 30px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    li {
      display: block;
      margin: 0;
      line-height: 36px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      &:hover {
        border-top: 1px solid transparent;
        line-height: 36px;
        > a {
          //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          box-shadow: none;
        }
        > span {
          //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          box-shadow: none;
        }
      }
      &.active {
        border-top: 1px solid transparent;
        line-height: 36px;
        > a {
          //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          box-shadow: none;
        }
        > span {
          //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          box-shadow: none;
        }
      }
      &.sidr-class-active {
        border-top: 1px solid transparent;
        line-height: 36px;
        > a {
          //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          box-shadow: none;
        }
        > span {
          //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
          box-shadow: none;
        }
      }
      a {
        padding: 0 30px;
        padding-bottom: 3px;
        display: block;
        text-decoration: none;
        color: $white;
        &.sidr-class-active-trail {
          color: $white;
        }
        &.sidr-class-active {
          color: $white;
        }
        &:hover {
          color: $white;
        }
        &:focus {
          color: $white;
        }
      }
      span {
        padding: 0 30px;
        padding-bottom: 3px;
        display: block;
        text-decoration: none;
        color: $white;
      }
      ul {
        border-bottom: none;
        margin: 0;
        li {
          line-height: 40px;
          font-size: 13px;
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            border-top: none;
            line-height: 41px;
            > a {
              //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
              box-shadow: none;
            }
            > span {
              //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
              box-shadow: none;
            }
          }
          &.active {
            border-top: none;
            line-height: 41px;
            > a {
              //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
              box-shadow: none;
            }
            > span {
              //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
              box-shadow: none;
            }
          }
          &.sidr-class-active {
            border-top: none;
            line-height: 41px;
            > a {
              //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
              box-shadow: none;
            }
            > span {
              //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
              box-shadow: none;
            }
          }
          a {
            color: $white;
            padding-left: 30px;
          }
          span {
            color: $white;
            padding-left: 30px;
          }
        }
      }
    }
  }
  form {
    margin: 0 15px;
  }
  label {
    font-size: 13px;
  }
  input[type="text"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  input[type="password"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  input[type="date"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  input[type="datetime"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  input[type="email"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  input[type="number"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  input[type="search"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  input[type="tel"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  input[type="time"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  input[type="url"] {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  textarea {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  select {
    width: 100%;
    font-size: 13px;
    padding: 5px;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    margin: 0 0 10px;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 2px;
    border: none;
    background: $black_10;
    color: $white_60;
    display: block;
    clear: both;
  }
  input[type=checkbox] {
    width: auto;
    display: inline;
    clear: none;
  }
  input[type=button] {
    color: $color_mine_shaft_approx;
    background: $white;
    &:hover {
      background: $white_90;
    }
  }
  input[type=submit] {
    color: $color_mine_shaft_approx;
    background: $white;
    &:hover {
      background: $white_90;
    }
  }
}
.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
  &:link {
    background-color: $color_storm_dust_approx;
    color: $white;
  }
  &:visited {
    background-color: $color_storm_dust_approx;
    color: $white;
  }
}
.skip-link__wrapper {
  margin: 0;
}
.speakers__inside {
  padding-top: 36px;
  padding-top: 2.11765rem;
}
.view-facilities-hotels {
  padding-top: 36px;
  padding-top: 2.11765rem;
}
.view-speakers {
  padding-top: 36px;
  padding-top: 2.11765rem;
}
.padding-4 {
  padding-top: 72px;
  padding-top: 4.23529rem;
  padding-bottom: 72px;
  padding-bottom: 4.23529rem;
}
.speakers__article {
  &.speakers__article--with-link {
    &:focus .speakers__image {
      &:before {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
        opacity: 0.8;
      }
      &:after {
        filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
        font-size: 18px;
      }
    }
    &:hover .speakers__image {
      &:before {
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
        opacity: 0.8;
      }
      &:after {
        filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
        opacity: 1;
        font-size: 18px;
      }
    }
  }
  &.speakers__article.node {
    max-width: 308px;
    margin-left: auto;
    margin-right: auto;
  }
}
.article-teaser__image {
  line-height: 0;
  position: relative;
  overflow: hidden;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 100%;
  &:before {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $color_carnation_approx;
    z-index: 10;
  }
  &:after {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateY(-50%);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    //content: 'ΠΕΡΙΣΣΟΤΕΡΑ';
    font-family: Averta-Regular;
    font-size: 22px;
    line-height: 18px;
    color: $white;
    text-align: center;
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    z-index: 11;
  }
}
.speakers__image {
  line-height: 0;
  position: relative;
  overflow: hidden;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 100%;
  &:before {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $color_carnation_approx;
    z-index: 10;
  }
  &:after {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateY(-50%);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    //content: 'ΠΕΡΙΣΣΟΤΕΡΑ';
    font-family: Averta-Regular;
    font-size: 22px;
    line-height: 18px;
    color: $white;
    text-align: center;
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    z-index: 11;
  }
}
.speakers__data {
  background-color: $concrete;
  position: relative;
  z-index: 12;
  text-align: center;
  a {
    &:focus {
      text-decoration: underline;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.speakers__title {
  font-size: 24px;
  line-height: 24px;
  font-size: 1.41176rem;
  line-height: 1.41176rem;
  font-family: Averta-Bold;
  color: $color_blue_bayoux_approx;
  margin-top: 24px;
}
.speakers__subtitle {
  font-size: 15px;
  color: $color_carnation_approx;
  font-family: Averta-Regular;
  margin-top: 11px;
  p {
    margin-top: 1em;
  }
}
.sponsors__item {
  height: 255px;
  width: 255px;
  display: block;
  text-align: center;
  position: relative;
  img {
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: translateY(-50%);
    position: relative;
    top: 127px;
  }
  .sponsors__logo-nocolor {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    line-height: 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .sponsors__logo-color {
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: all 0.4s ease-out;
    line-height: 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    z-index: 10;
  }
  &:hover .sponsors__logo-color {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }
}
.table-drag__wrapper {
  cursor: move;
}
.table-drag__item {
  background-color: $color_scotch_mist_approx;
}
.table-drag__item-previous {
  background-color: $color_lemon_chiffon_approx;
}
.table-drag__handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
}
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  .handle {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAA8CAMAAACO5hB7AAAALVBMVEUAAAD///////8AAAAQEBBycnJ7e3uFhYWLi4uTk5OZmZnCwsLe3t7l5eXw8PBW32uGAAAAA3RSTlMAQb9IyhSHAAAAi0lEQVR42sVOAQ4DIQibsIF3Kv9/7szgQlgfYBNC2trKyzFiP7SPQi/RIixZSbZjYns/j+easuceXiWBj4YQvgzMY79HPIv35Q1noZX2txaXuQqTZ81ydnRetn1brJ5NhJA+5rE/OrQKCvcfBf1Ro0qtCs1aEgpOQa219htymiDwIQ/9+D/e59kj+ALoHAYllVUliQAAAABJRU5ErkJggg==") no-repeat 6px 9px;
    height: 13px;
    margin: -.4em .5em;
    padding: .42em .5em;
    width: 13px;
  }
}
.table-drag__handle-icon {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAA8CAMAAACO5hB7AAAALVBMVEUAAAD///////8AAAAQEBBycnJ7e3uFhYWLi4uTk5OZmZnCwsLe3t7l5eXw8PBW32uGAAAAA3RSTlMAQb9IyhSHAAAAi0lEQVR42sVOAQ4DIQibsIF3Kv9/7szgQlgfYBNC2trKyzFiP7SPQi/RIixZSbZjYns/j+easuceXiWBj4YQvgzMY79HPIv35Q1noZX2txaXuQqTZ81ydnRetn1brJ5NhJA+5rE/OrQKCvcfBf1Ro0qtCs1aEgpOQa219htymiDwIQ/9+D/e59kj+ALoHAYllVUliQAAAABJRU5ErkJggg==") no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}
.table-drag__handle-icon-is-hover {
  background-position: 6px -11px;
}
.table-drag__toggle-weight-wrapper {
  text-align: right;
}
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}
.table-drag__toggle-weight {
  font-size: .9em;
}
.tabledrag-toggle-weight {
  font-size: .9em;
}
.table-drag__indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}
.table-drag__tree-child {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center;
}
.table-drag__tree-child-last {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACZJREFUOMtjWIUFMDQwYACmUcFRwVFBnIJTQzFAGMMoGAWjgGIAALExJ/w2ePm7AAAAAElFTkSuQmCC");
}
.tree-child-last {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACZJREFUOMtjWIUFMDQwYACmUcFRwVFBnIJTQzFAGMMoGAWjgGIAALExJ/w2ePm7AAAAAElFTkSuQmCC");
}
.table-drag__tree-child-horizontal {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center;
  background-position: -11px center;
}
.tree-child-horizontal {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center;
  background-position: -11px center;
}
.tree-child {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABRAgMAAAA4I8mbAAAACVBMVEX///+FhYWAgICko1U2AAAAAXRSTlMAQObYZgAAACdJREFUOE9jWIUFMDQwYACmUcFRwVFBnIKj+WhUcFSQcsGpoRggDAA5C0kS2yLjkQAAAABJRU5ErkJggg==") no-repeat 11px center;
}
.tabs__tab {
  //Instead of the line below you could use @include border-top-left-radius($radius)
  border-top-left-radius: 4px;
  //Instead of the line below you could use @include border-top-right-radius($radius)
  border-top-right-radius: 4px;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: 1px 1px 0 $white;
  float: left;
  margin: 0 3px;
  border: 1px solid $color_celeste_approx;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  &.is-active {
    border-bottom-color: $white;
  }
}
.tabs__tab-link {
  border: 1px solid $color_gray_nurse_approx;
  border-right: 0;
  border-bottom: 0;
  display: block;
  text-decoration: none;
  //Instead of the line below you could use @include border-top-left-radius($radius)
  border-top-left-radius: 4px;
  //Instead of the line below you could use @include border-top-right-radius($radius)
  border-top-right-radius: 4px;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: background-color 0.3s;
  color: $color_mine_shaft_approx;
  background-color: $color_alto_approx;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center;
  &:focus {
    background-color: $color_gray_nurse_approx;
    border-color: $seashell;
  }
  &:hover {
    background-color: $color_gray_nurse_approx;
    border-color: $seashell;
  }
  &.is-active {
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFEAEAEA', endColorstr='#00EAEAEA');
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIgc3RvcC1vcGFjaXR5PSIwLjAiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=") transparent;
    background: linear-gradient($color_gray_nurse_approx,rgba(234,234,234,0)) transparent;
    border-color: $color_black_haze_approx;
  }
  &:active {
    filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFEAEAEA', endColorstr='#00EAEAEA');
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2VhZWFlYSIgc3RvcC1vcGFjaXR5PSIwLjAiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=") transparent;
    background: linear-gradient($color_gray_nurse_approx,rgba(234,234,234,0)) transparent;
    border-color: $color_black_haze_approx;
  }
}
.tabs--secondary__tab-link {
  border: 1px solid $color_gray_nurse_approx;
  border-right: 0;
  border-bottom: 0;
  display: block;
  text-decoration: none;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0.75em;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: background-color 0.3s;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: 1px 1px 0 $white;
  color: $color_storm_dust_approx;
  background-color: $seashell;
  padding: 0 .5em;
  &:focus {
    color: $color_mine_shaft_approx;
    background-color: $color_alto_approx;
    border-color: $color_mountain_mist_approx;
  }
  &:hover {
    color: $color_mine_shaft_approx;
    background-color: $color_alto_approx;
    border-color: $color_mountain_mist_approx;
  }
  &.is-active {
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 1px 1px 0 $color_mine_shaft_approx;
    color: $white;
    background-color: $color_storm_dust_approx;
    border-color: $black;
  }
  &:active {
    //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
    text-shadow: 1px 1px 0 $color_mine_shaft_approx;
    color: $white;
    background-color: $color_storm_dust_approx;
    border-color: $black;
  }
}
.tabs--secondary__tab {
  margin: 12px 3px;
  margin: 0.70588rem 0.17647rem;
  float: left;
}
#backtotop {
  background-color: $color_nobel_approx;
  width: 45px;
  height: 42px;
  line-height: 42px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  z-index: 100;
  text-align: center;
  display: none;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  border: 0;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 0.4s ease-out;
  text-indent: -666px;
  overflow: hidden;
  &:hover {
    background-color: $color_mountain_mist_approx;
  }
  &:focus {
    background-color: $color_mountain_mist_approx;
  }
  &:after {
    position: absolute;
    content: '';
    left: -1px;
    top: -2px;
    display: block;
    width: 45px;
    height: 45px;
    background: url(../sass/components/to_top/arrow.png?1539002474) center center no-repeat;
    z-index: 101;
  }
}
.top-banner {
  min-height: 815px;
  background-size: cover;
  background-position: center center;
  text-align: center;
  background-attachment: fixed;
}
.top-banner__image {
  padding: 20px 40px;
}
.top-banner--signature {
  position: relative;
}
.top-banner--signature::after {
  content: '';
  position: absolute;
  bottom: 80px;
  width: 550px;
  left: 50%;
  margin-left: -275px;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  text-align: center;
  font-family: Averta-Regular;
  font-size: 20px;
  line-height: 30px;
  color: $white;
}
@media(min-width: 481px) and(max-width: 560px) {
  .top-banner--signature::after {
    width: 100%;
    left: 0;
    margin-left: 0;
    padding: 0 40px;
  }
}
.top-banner__logo {
  background-image: url(../sass/components/top-banner/plovdiv-logo.png?1539002452);
  background-position: center top;
  background-repeat: no-repeat;
  margin-top: -10px;
  width: 200px;
  height: 85px;
  margin-left: auto;
  margin-right: auto;
  text-indent: -6666px;
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 10;
}
.top-banner__title {
  padding-top: 72px;
  padding-top: 4.23529rem;
  font-size: 74px;
  line-height: 60px;
  font-size: 4.35294rem;
  line-height: 3.52941rem;
  color: $white;
  font-family: Averta-Regular;
}
.top-banner__subtitle {
  font-size: 24px;
  line-height: 24px;
  font-size: 1.41176rem;
  line-height: 1.41176rem;
  padding-top: 12px;
  padding-top: 0.70588rem;
  font-style: italic;
  color: $white;
}
.front-deprecated {
  .top-banner__logo {
    margin-top: 72px;
    margin-top: 4.23529rem;
    background-image: url(../sass/components/top-banner/plovdiv-logo.png?1539002452);
    width: 260px;
    height: 216px;
  }
  .top-banner__title {
    padding-top: 12px;
    padding-top: 0.70588rem;
    font-size: 25px;
    line-height: 24px;
    font-size: 1.47059rem;
    line-height: 1.41176rem;
  }
  .top-banner__subtitle {
    font-size: 25px;
    line-height: 24px;
    font-size: 1.47059rem;
    line-height: 1.41176rem;
    font-family: Averta-Regular;
    font-style: normal;
  }
}
.top-banner--short {
  min-height: 360px;
}
.top-banner--no-title {
  .top-banner__title {
    display: none;
  }
  .top-banner__subtitle {
    display: none;
  }
}
.watermark {
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: $color_gallery_approx;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, Arial Narrow, Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: 0 0 1px $black_10;
  word-wrap: break-word;
}
.front .center-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 40px;
  padding-bottom: 0;
  padding-bottom: 144px;
  padding-bottom: 8.47059rem;
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
.page-node:not(.node-type-speaker):not(.node-type-press-article) .main-content {
  padding-top: 80px;
  //padding-top: 11.41176rem;
  background-color: #f2f2f2; //$color_carnation_approx;
  padding-bottom: 80px;
  //padding-bottom: 8.47059rem;
  > article {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px;
  }
  > form {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px;
  }
  > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    //edit if breaks the shit out of everything back to 1110
    padding-left: 15px;
    padding-right: 15px;
  }
}
.page-user .main-content {
  padding-top: 194px;
  padding-top: 11.41176rem;
  background-color: $color_carnation_approx;
  padding-bottom: 144px;
  padding-bottom: 8.47059rem;
  > article {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px;
  }
  > form {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px;
  }
  > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.l-three-column .view-content {
  margin: 0 -15px;
}
.l-four-column .view-content {
  margin: 0 -15px;
}
.view-content h3 {
  display: none;
}
.l-two-column .view-content {
  margin: 0 -15px;
}
#admin-toolbar ul {
  &.menu li {
    &.leaf {
      height: 31px;
    }
    a {
      height: 30px;
    }
  }
  &.drilldown-active-menu li {
    height: 31px;
  }
}
.page-contact .footer-negative-margin {
  margin-top: -300px;
}
.page-register div.panel-pane div {
  &.footer-padding {
    padding-bottom: 0;
  }
  &.form {
    margin-bottom: 40px;
  }
}
.editable-page--image-blue-gray .editable-page__image-wrapper {
  background-color: $color_cadet_blue_approx;
}
.page-home .editable-page {
  margin-top: 95px;
  margin-bottom: 70px;
}
.is-sticky.sticky-header {
  visibility: visible;
}
form table {
  th {
    border-bottom: 3px solid $color_celeste_approx;
    padding-right: 1em;
    text-align: left;
    &.form-table__narrow-column {
      text-align: center;
    }
  }
  tbody {
    border-top: 1px solid $color_celeste_approx;
    tr {
      padding: .1em .6em;
      border-bottom: 1px solid $color_celeste_approx;
      background-color: $color_gallery_approx;
      &:nth-child(even) {
        background-color: $white;
      }
    }
  }
  ul {
    margin: 0;
  }
  [dir="rtl"] th {
    text-align: right;
    padding-left: 1em;
    padding-right: 0;
  }
}
.logged-in .hidden-for-anonymous {
  display: block;
}
.inline.links {
  padding: 0;
  li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0;
  }
  [dir="rtl"] li {
    display: inline-block;
    padding: 0 0 0 1em;
  }
}
span.field-label {
  display: inline;
  margin-right: 10px;
  * {
    display: inline;
  }
  + * {
    display: inline;
    > *:first-child {
      display: inline;
    }
  }
}
.is-expanded.menu__item {
  list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHAQMAAAD+nMWQAAAABlBMVEX///8AAABVwtN+AAAAAXRSTlMAQObYZgAAABJJREFUeJxj+MdQw2DBIMAABgAUsAHD3c3BpwAAAABJRU5ErkJggg==");
  list-style-type: circle;
}
.nav-menu__link.is-active {
  color: $color_rangoon_green_approx;
}
.menu a.active {
  color: $color_rangoon_green_approx;
}
.pswp__button:not(.pswp__button--arrow--right):not(.pswp__button--arrow--left) {
  background-color: $black;
}
.pswp__button--arrow--right:after {
  background-color: $black;
}
.pswp__button--arrow--left:after {
  background-color: $black;
}
.sidr-open #sidr-0-button:before {
  background-image: url(../sass/components/sidr/menu-close.png?1539002462);
}
.sidr-inner a:hover {
  text-decoration: underline;
}
.tabledrag-handle-hover .handle {
  background-position: 6px -11px;
}
.top-banner--front .top-banner__title {
  font-size: 55px;
  line-height: 66px;
}
.panel-pane:first-child div.contextual-links-wrapper {
  top: 136px;
}








// ---------------------------------------------------------------------

// Mixins //

/* links mixin set the display rules of all a tags in a block */
@mixin links ($link, $visit, $hover, $active) {
  a {
    transition: 0.3s;
    color: $link;
    &:visited {
      color: $visit;
    }
    &:hover {
      color: $hover;   
      cursor: pointer;
    }
    &:active {
      color: $active;
    }
  }
}
@mixin button(){
  background: $light-blue;
  color: $white;
  border: 1px solid $dark-blue;
  border-radius: 0;
  padding: 10px 25px;
  &:hover {
    background: $lgreen;
    border: 1px solid $green;
  }
}
@mixin shadow(){
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.05), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.02);
}
//

// Quiz Block related Styles
$n-gray: #f2f2f2;
$beige: #e3e2d5;
$blueish: #c5dede;
$green: #1b8e5d;
$dark-blue: #004176;
$light-blue: #0f75bc;
$purple: #862175;
$text-black: #231f20;
$brown: #996d66;
$transparent_white: rgba($white,0.5);
$red: #f15b55;
$lgreen: #1ea069;
$strong-black: #1a1a1a;
$gold: #ffc716;

$radius: 0px;

// Typography
$smaller: 14px;
$small: 15px;
$large: 22px;
$larger: 26px;
//

// General //

.issuu-btn {
  cursor: pointer;
}

.issuu-container {
  margin: 40px 0;
}

@keyframes date {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    100% {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
    }
}

.messages--status {
  padding: 10px 50px !important;
}

select {
  &:hover {
    cursor: pointer;
  }
}

.header-top {
  background: rgba($strong-black,0.6);
  display: block;
  width: 100%;
  height: 30px;
  position: relative;
  z-index: 99;

  .region-header-top {
    max-width: 1080px;
    margin: 0 auto;
    text-align: right;
    height: 100%;
    &:after {
      content: "";
      display: table;
      clear: both;

    }
  }

  .block-locale {
    float: left;
    position: relative;
    z-index: 102;
    li {
      @include links($white,$white,$green,$white);
    }
  }
  .user-actions {
    float: right;
  }
  .block-locale,
  .user-actions {
    height: 100%;
    font-size: $smaller;
    color: $white;
    @include links($white,$white,$green,$white);
    display: inline-block;

    ul,
    .menu {
      margin: 0;
      padding: 0;
    }
    li,
    .menu__item {
      list-style-image: none;
      list-style-type: none;
      display: inline-block;
      vertical-align: middle;
      line-height: 2.2;
      margin-left: 10px;
      padding-right: 10px;
      border-right: 1px solid rgba(255,255,255,0.1);
    }
  }

  // .user-reserve {
  //   margin-right: 10px;
  // }
  // .user-reserve,
  // .user-login {
  //   display: inline-block;
  //   vertical-align: middle;
  //   line-height: 2.2;
  //   @include links($white,$white,$green,$white);
  // }
}

.messages__icon {
  display: none;
}
.main {
  padding-bottom: 60px;
  background: #f2f2f2;
}


.front {




  .event-top-banner {
    // width: 150px;
    // height: 150px;
    // border-radius: 50%;
    // bottom: 220px;
    // display:none;
    // animation: date 2s ease-in-out infinite alternate;
    // -webkit-animation: date 2s ease-in-out infinite alternate;
        width: 320px;
    /* height: 150px; */
    /* border-radius: 50%; */
    bottom: 70px;
    /* left: 50%; */
    /* display: none; */
    position: absolute;
    right: 0;
    background: #1ea069;
    padding: 10px 30px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    font-family: "Averta-Bold";
    text-align: center;
    bottom: 56px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    right: 30px;
  }
}
.event-top-banner {
  position: absolute;
  //top: 50%;
  right: 0;
  background: $green;
  padding: 10px 30px;
  transform: translateY(-50%);
  color: $white;
  font-family: "Averta-Bold";
  text-align: center;
  bottom: -93px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  p {
    margin: 0;
    padding: 5px 0;
    &.event-title {
      border-bottom: 1px solid rgba(255,255,255,0.2);
    }
  }
}

.grippie {
  display: none !important;
}

.not-front {
  .top-banner {
    height: 400px;
    min-height: auto;
  }
  .top-banner__image {
    display: none;
  }


  .link-button {
    
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.05), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.02);
    // background: #0f75bc;
    // color: #fff;
    // border: 1px solid #004176;
    @include button;
    margin-right: 0;
    font-size: 16px;
    &.link-button--dl {
      background: #4d4d4d;
      &:hover {
        background: $green;
        border: 1px solid $lgreen;
      }
    }
  }

}

//

.page-node {
  &.node-type-speech {
    .page {
      
        background-color: $n-gray;
      
    }
  }
}

.node-type-speech {

  .field-label {
    font-family: Averta-Bold;
  }
  .node {
    &.node-speech {
      &.view-mode-full {
        padding-top: 0 !important;
      }
    }
  }
  .view-mode-full {
    .field {
      margin-bottom: 40px;
    }  
    .field-name-body {
      margin-top: 60px;
    }
  }
  

  .field-name {
    &-field-start-time {
      display: inline-block;
      font-style: italic;
      margin-bottom: 15px;
      font-size: $small;
    }
  
    &-title-field {
      font-size: $larger;
      margin-bottom: 20px;
    }

    &-field-speaker {
      @include links($light-blue,$light-blue,$green,$light-blue);
    }

    &-field-downloads {
      @include links($light-blue,$light-blue,$green,$light-blue);
      .field-item {
        margin: 5px 0;
        font-size: $small;
      }
    }
  }
}

// ** speakers ** //
// these pages are used to set the node-top-banner only to default //
.page-about,
.node-type-webform,
.page-node-1693,
.node-type-speaker,
.page-organization,
.page-location,
.page-agenda,
.page-streaming,
.page-speakers {


  .pane-page-messages {
    display: none;
  }
  .node-top-banner {
    background-image: url(/sites/all/themes/delfi/images/bg-purple-wide.jpg) !important;
    //background-position-y: -300px;
    //background: $light-blue;
  }
  .panel-1col {
    background: $n-gray;
  }
}



.node-type-speaker,
.page-agenda,
.page-speakers,
.node-type-speaker {

  .node-speaker {
    &:hover {
      .speakers__image {
        box-shadow: 0 2px 8px -2px rgba($black,0.55);
      }
    }
  }

  .speakers__data,
  .speakers {
    background: $n-gray;
  }

  .speakers__title {
    color: $black;
    line-height: 35px;
  }

  .speakers__subtitle {
    color: $dark-blue;
  }
  .image-overlayed__image {
    img {
      border-radius: 0;
    }
  }
  .speakers__image {
    border-radius: 0;
    &:before {
      background-color: rgba($dark-blue,0.55);
    }
  }

  .callout {
    bottom: -60px;
  }
  .callout__wrapper {
    height: 200px;
  }

  .title__wrapper {
    position: relative;
    display: block;
    width: auto;
    height: auto;
  }
}
//

.node-speaker {
  &.view-mode-full {

    background: white;
    padding: 30px !important;
    margin-top: 60px;
    @include shadow;

    .image-overlayed__subtitle {
      color: $dark-blue;
    }
    .group-speaker-info {
      text-align: left;
      margin-bottom: 80px;
      @include links($light-blue,$light-blue,$green,$light-blue);
      a {
        display: block;
      }
      .label-above {
        margin: 10px 0;
      }
    }
    .footer-padding {
      padding-bottom: 50px;
    }
    
  }
}


.inner-region {
  //padding: 20px 0;
  margin: 0 -30px;
  padding: 30px;
  border-bottom: 1px solid rgba($black,0.3);
}

.toggle-area {
  cursor: pointer;
  display: inline-block;
  span {
    display: inline-block;
    color: $white;
    background: $light-blue;
    border: 1px solid rgba($dark-blue,0.3);
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
  }  

  &:hover {
    span {
      cursor: pointer;
      background: $white;
      color: $color_carnation_approx;   
    }
  }
  
}

.questions-container {
  // margin: 30px;
  // background: white;
  // padding: 30px;
  // box-shadow: 0 2px 3px rgba(0,0,0,0.1);
  // box-shadow: 0 2px 2px 0 rgba(0,0,0,.05), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.02);
  background: #f6f6f6;
  margin: 30px -30px;
  padding: 30px;
  display: none;
  
}

.question-wrapper {
  margin-bottom: 30px;
}

.question-title {
  font-family: Averta-Bold;
  width: 90%;
  margin-bottom: 10px;
}

.eval-region {
  fieldset {
    &.speaker {
      border: 1px solid rgba($black,.2);
      margin-bottom: 30px;
      padding: 30px;
    }
    .name {
      font-weight: 600;
    }
  }
}

.eval,
.debate,
.quiz {
   &-title{
      h2 {
         display: inline-block;
         font-family: Averta-Bold;
         margin-right: 10px;
      }
   }

   &-radio {
    margin-right: 10px;
   }

   i {
    font-size: 14px;
   }

  &-subtitle {
    display: block;
  }
}

.correct {
  color: green;
}
.wrong {
  color: red;
}


// Evaluation

.eval-results {
  &:after {
    display: table;
    clear: both;
  }
}

.to-left {
  float: left;
  margin-right: 20px;
}

.to-right {
  float: left;
  span {
    display: block;
    font-size: 15px;
    &.eval-number {
      display: inline-block;
    }
  }
}

.eval-current-results {
  margin-top: 20px;
}

.eval-results-number {
  font-size: 50px;
  font-family: Averta-Bold;
}

.answer-wrapper {
  display: block;
}
.debate-answer {
  display: inline-block;
  border: 1px solid rgba($light-blue,0.25);
  border-radius: 20px;
  padding: 10px 20px;
  margin: 5px 0;
  &:not(.disabled) {
    &:hover {
      cursor: pointer;
      background: $light-blue;
      border: 1px solid $dark-blue;
      color: $white;
    }
  }
  &.selected-deb {
    background: $light-blue;
    color: $white;
    border: 1px solid $dark-blue;
  }
}

.start-quiz {
  display: block;
  border: 1px solid rgba(0,0,0,0.1);
  padding: 8px 20px;
  
  border: 1px solid #aaa;
  color: #231f20;
  border-radius: 0;
  padding: 8px 10px;
  transition: 0.4s;
  margin-right: 15px;
  margin-top: 20px;
  max-width: 180px;
  @include links($black,$black,$black,$black);
  &:hover {
    cursor: pointer;
    background: $light-blue;
    border: 1px solid $dark-blue;
    a {
      color: $white !important; 
    }
  }
}

//end of Quiz Styles



// Evaluation styles //
.eval-region {
$unchecked-star: '\2606';
$unchecked-color: #888;
$checked-star: '\2605';
$checked-color: #eee222;
.star-cb-group {

  input {
    display: none;
  }


  label {
    display: inline-block;
    overflow: hidden;
    text-indent: 9999px;
    width: 25px;
    white-space: nowrap;
    font-size: 22px;
    &.selected {
              &:before {
          content: $checked-star;
          color: $checked-color;
        
      }
    }
    &:before {
      display: inline-block;
      text-indent: -9999px;
      content: $checked-star;
      color: $unchecked-color;
      text-shadow: 0 0 1px #333;
    }
    &:not(.disabled) {
      cursor: pointer;
      &:hover {
        &:before {
          content: $checked-star;
          color: $checked-color;
        }
      }      
    }
  }
}
  fieldset {
    border: 0;
  }
}


//front page 
.front {

  .center-wrapper {
    padding-bottom: 20px;
  }

  .page {
    background: #f7f7f7;  
  }

  .panel-pane {
    .top-banner {
      background-image: url(/sites/all/themes/delfi/images/bg-purple-wide.jpg) !important;
      background: $dark-blue;
      //background-position-y: -110px;
      //background: $dark-blue;
    }
  }

  .top-banner {
    min-height: 705px;
  }

  .top-banner__image {
    display: none;
  }
  //ti einai to sxoleio
  &.page-home {
    .editable-page {
      padding: 10px;
      background: $white;
      margin-left: auto;
      margin-right: auto;
      max-width: 1080px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, .08);
      //border-radius: 20px;
      margin-top: -150px;
      position: relative;
      z-index: 1;
    }
  }
  .editable-page__image-wrapper {
    background: $blueish;
    img {
      height: 350px;
      width: auto;
    }
  }
  
  .editable-page__text,
  .editable-page__title {
    background-color: $white;
    color: $text-black;
  }

  .editable-page__title {
    padding: 0 32px;
    padding-top: 40px;
    color: $dark-blue; 
  }

  //front cards
  .panel-2col-stacked {
    .center-wrapper {
      .inside {
        // box-shadow: 0 2px 3px rgba(0, 0, 0, .08);
        // border-radius: 2px;
        // //padding: 0 10px;
        // border-radius: $radius;
        margin: 0;
      }  
    }
  }
  .link-tile {
    //padding: 10px;
    height: 400px;
    border-radius: $radius;
    position: relative;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
    background-size: 30% !important;
    background-position: center 90px !important;
    // border-radius: 2px;
    // border-radius: 20px;
    // margin: 30px 0;
    margin: 0;
    margin: 0 1px 2px 1px;
  }
  .link-tile__title {
    text-shadow: 0px 2px 6px rgba(0,0,0,0.35);  
    height: auto;
    width: auto;
    padding-top: 0;
    z-index: 999;
    //background: rgba($dark-blue,0.70);
    padding: 20px 20px 24px 20px;
    text-align: left;
    font-size: 26px;
  }

  .link-tile__link {
    margin-top: -50px;
    top: 0;
    right: 15px;
    text-align: right;
    font-size: 26px;
    text-shadow: 0 2px 4px rgba(0,0,0,0.35);
    font-family: "Averta-Regular";
  }

  .link-title__link-to-content {
    position: absolute;
    bottom: 10px;
    top: 0;
    right: 0;
    left: 0;
  }


  .link {

    $light-blue: #86d1e9;
    $green: #ffd272;
    $dark-blue: #eb2570;
    &-7 {
      transition: all .3s; 
      background-color: $dark-blue;

      &:hover {
        background-color: darken($dark-blue,2%);
        transform: scale(1.025);
        z-index: 99;
        .link-title__link-to-content {
          //box-shadow: 0 2px 35px 15px rgba(0,0,0,0.2);
        }
      }
    }
    &-12 {
      transition: all .3s; 
      background-color: $dark-blue;
      background-image: url(https://uroschool.gr/sites/default/files/images/links/oly.png) !important;
      background-repeat: no-repeat !important;
      background-attachment: scroll !important;
      &:hover {
        background-color: darken($dark-blue,2%);
        transform: scale(1.025);
        z-index: 99;
        .link-title__link-to-content {
          //box-shadow: 0 2px 35px 15px rgba(0,0,0,0.2);
        }
      }
    }
    &-1699 {
      transition: all .3s; 
      background-color: $light-blue;
      &:hover {
        background-color: darken($light-blue,2%);
        transform: scale(1.025);
        z-index: 99;
        .link-title__link-to-content {
          //box-shadow: 0 2px 35px 15px rgba(0,0,0,0.2);
        }
      }
    }
    &-14 {
      transition: all .3s; 
      background-color: $dark-blue;
      background-image: url(https://uroschool.gr/sites/default/files/images/links/oly.png) !important;
      background-repeat: no-repeat !important;
      background-attachment: scroll !important;
     
      &:hover {
        background-color: darken($dark-blue,2%);
        transform: scale(1.025);
        z-index: 99;
        .link-title__link-to-content {
          //box-shadow: 0 2px 35px 15px rgba(0,0,0,0.2);
        }
      }
    }
    &-9,
    &-1700 {
      transition: all .2s; 
      background-color: $green;
      &:hover {
        background-color: darken($green,5%);
        transform: scale(1.025);
        z-index: 99;
        .link-title__link-to-content {
          //box-shadow: 0 2px 35px 15px rgba(0,0,0,0.2);
        }
      }
    }
    &-1688 {
      transition: all .2s; 
      background-color: #523091;
      &:hover {
        background-color: darken(#523091,2%);
        transform: scale(1.025);
        z-index: 99;
        .link-title__link-to-content {
          //box-shadow: 0 2px 35px 15px rgba(0,0,0,0.2);
        }
      }
    }
    &-1689 {
      transition: all .2s; 
      background-color: #523091;
      &:hover {
        background-color: darken(#523091,2%);
        transform: scale(1.025);
        z-index: 99;
        .link-title__link-to-content {
          //box-shadow: 0 2px 35px 15px rgba(0,0,0,0.2);
        }
      }
    }
  }

}

.callout {
  top: 80px;
}
.footer__wrapper {
  background: $text-black;
}


// Program Styling

.page-agenda {

  .field-name-field-session {
    margin: 30px 0;
    //padding: 30px;
    //padding-top: 0;
    border-bottom: 1px solid rgba($black,0.1);
    background: $white;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.1);
  }

  .node-speech {
    @include links($black,$black,$light-blue,$black);  
    .field-name-body {
      margin: 20px 0;
    }
  }
  
  .panel-panel {
    background: $n-gray;
  }
  .node-session {
    .group-toggle-area {

      .field-name-field-duration {
        float: left;
        min-width: 120px;
      }

      .field-name-title-field {
        font-family: "Averta-Bold";
        font-size: 20px;
        // margin-bottom: 20px;
        @include links($black,$black,$light-blue,$black);
        a {
          &:hover {
            text-decoration: underline;
          }  
        }
      }  
    }
      

    .field-name-field-videos {
      // margin-top: 20px;
      // display: inline-block;
    }

    .read-more {
      //display: none;
    }

    .field-name-body {
      p {
        margin: 0;
      }
    }
  
    &>.field-name-title-field {
      font-family: "Averta-Bold";
    }
    &>header {
      display: none;
    }
  
    .field-name-field-session-slots {
      position: relative;
      //padding-left: 50px;
      //margin: 20px 0;
      margin: 30px;
      margin-bottom: 60px;
      //display: none;

      &:after {
        content: "";
        height: 100%;
        width: 1px;
        background: #eaeaea;
        position: absolute;
        left: 20px;
        top: 0;
        
      }
    }
    .group-session-data {
      margin-top: 20px;
      .field-name-body {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .group-toggle-area {
      &.open {
        background: #f6f6f6;
        border-bottom: 1px solid rgba($light-blue,0.15);
      }
      &:hover {
        // cursor: pointer;
        // background: #f6f6f6;
      }
    }

    .group-inner-info {
      // display: inline-block;
      // width: calc(100% - 35px);
      // width: calc(100% - 155px);
      // padding-left: 20px;
      .field-name-title-field {
        display: inline-block;
        width: calc(100% - 155px);
      }
    }
    .group-toggle-area-inner {
      display: inline-block;
      vertical-align: top;
      .toggle-area-btn {
        padding: 2px 8px;
        min-width: 29px;
        text-align: center;
        //border: 1px solid $black;
        background: $light-blue;
        border: 1px solid $dark-blue;
        color: $white;
        transition: .3s;
        &:hover {
          cursor: pointer;
          background: $lgreen;
          border: 1px solid $green;          
        }
      }
    }
  }

  .session-coordinators {
      @include links($light-blue,$light-blue,$green,$light-blue);
      .field-items,
      .field-label {
        display: inline-block;
      }
      .field-items {
        .field-item {
          display: inline-block;
          &:after {
            content: ",";
            display: inline-block;
            padding-right: 5px;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }

  .group-toggle-area {
    //margin: 0 -30px;
    padding: 30px;
    border-top: 1px solid rgba(0,0,0,0.1);
  }

}

.node-speech {
  &.node-teaser {
    .links {
      &.inline {
        display: none;
      }
    }

    .field-name-title-field {
      @include links($black,$black,$light-blue,$black);
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .field-name-field-session-slots {
      padding: 20px 40px 30px 40px;
    }

    // .field-name-body {
    //   .field-items {
    //     p {
    //       margin-top: 0;
    //     }
    //   }
    // }

    .group-general-info {
      width: 100%;
      width: calc(100% - 190px);
      max-width: 750px;
      float: left;
    }

    .group-teaser-attachments {
      float: right;
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid rgba($black,0.1);
      width: auto;
      max-width: 130px;
    }
    
    .field-name-title-field {
      font-family: "Averta-Bold";
      padding-bottom: 10px;
      font-size: 20px;
      
      a {
        &:hover {
          text-decoration: underline;
        }  
      }
    }

    .field-name-field-start-time {
      font-family: "Averta-Bold";
      background: $white;
      position: relative;
      z-index: 99;
      min-width: 120px;
      color: $light-blue;//#f15b55;
    }

    .slot-secondary-info {
      padding: 20px 40px 30px 60px;

      &:after {
        content: "";
        display: table;
        clear: both;
        width: 100%;
      }
    } 

    .field-name-field-start-time {
      display: inline-block;
      vertical-align: top;
      padding: 10px 0;
    }
  }
}  

// selida diamoni & organwsi
.page-gdpr,
.page-oroi-xrhshs,
.page-about,
.page-location,
.page-streaming,
.page-organization { 

  .panel-1col {
    background: $n-gray;
  }
  .editable-page {
    padding: 10px;
    background: $white;
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .08);
    //border-radius: 20px;
    position: relative;
    z-index: 1;
  }

  .editable-page__text,
  .editable-page__title {
    background-color: $white;
    color: $text-black;
  }

  .editable-page__image-wrapper {
    background: $blueish;
  }

  .editable-page {
    img {
      width: auto;
    }
  }

  .editable-page__title {
    padding: 0 32px;
    padding-top: 40px;
    color: $dark-blue; 
  }

  .editable-page__supertitle {
    color: $dark-blue;
  }

}




// webforms 
.page-contact {
  .form {
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    padding-left: 15px;
    padding-right: 15px;
  }

}
.page-contact,
.node-type-webform {

  //general styling
  .view-mode-full {
    padding: 30px;
    form {
      margin-top: 30px;
    }
  }

  .form {
    background-color: $white;
    color: $black;
    @include shadow;
    label {
      color: $black;
    }
    select,input,textarea {
      &:focus {
        outline: none;
        border: 1px solid rgba($light-blue,0.5);
      }
    }
    input[type="text"],
    input[type="email"],
    textarea,
    select {
      //border: 1px solid rbga($light-blue,0.3);
      border: 1px solid #aaa;
      color: $black; 
      border-radius: 0;
    }
    

    .form-submit {
      display: block;
      margin: 0 auto;
      @include button;
      &.form-button-disabled {
        background: #999;
        border: 1px solid $black;
        opacity: 0.3;
        &:hover {
          border: 1px solid $black;
          background:#999;
          cursor: default;
        }
      }
    }

    .description {
      color: #4c4c4c;
    }


  }
  .form__text {
    color: $black;
    @include links($dark-blue,$dark-blue,$green,$dark-blue);
  }


  // evaluation form specific styling
  #webform-client-form-1693 {
    .webform-component {
      border-bottom: 1px solid rgba(0,0,0,0.2);
      padding: 30px;
    }

    fieldset {
      border: none;
      legend {
        font-family: "Averta-Bold";
        color: $black;
        padding-top: 30px;
      }
    }

    .form-item {
      width: 100%;
      padding: 30px;

      label {
        padding-bottom: 30px;
        font-family: "Averta-Bold";
        line-height: 1.5;
      }

      .form-item {
        display: inline-block;
        width: 20%;
        padding: 0;
        
        label {
          font-family: "Averta-Regular";
          position: relative;
          top: 2px;
          padding-left: 5px;

        }

      }      
    }

    .form-actions {
      padding: 70px 15px 72px;
    }
    
  }
}




// Speech-Session Full Mode 


.node-type-speech,
.node-type-session {
  .node-session,
  .node-speech {
    &.view-mode-full {
      margin-top: 80px;
      background: $white;
      padding: 30px !important;
      @include shadow;

      .field-label {
        padding-bottom: 20px;
      }
      .field-name-body {
        .field-items {
          //padding: 15px 30px;  
          //check
        }
      }
      .field-name-title-field,
      .field-name-field-speaker,
      .field-name-field-start-time {
        display: none;
      }

      .slot-secondary-info {
        .field-name-title-field {
          display: block;
        }
      }

      .field-name-field-videos {
        .field-items {
          width: 100%;
          padding: 15px 30px;  
          .field-item {
            display: inline-block;
            width: 100%;
            max-width: 305px;
            margin-right: 20px;
            margin-bottom: 20px;
            height: 170px;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,.05), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.02);
            iframe {
              width: 100%;
              height: 170px;
            }
          }
        }
      }

      .teaser-video {
          a {
            width: 50px;
            height: 50px;
          }
        } 

      .slot-secondary-info {
        .teaser-video {
          a {
            width: 35px;
            height: 35px;
          }
        }  
      }

      
      .field-name-field-downloads {
        .field-items {
          width: 100%;
          //padding: 15px 30px;  
          .field-item {
            display: inline-block;
            margin-right: 20px;

          }
        }
      }
    }  
  }
  
}


.node-type-session {
  .node-session {
    &.view-mode-full {
      .group-general-info {
        .field-name-field-speaker {
          display: block;
          @include links($light-blue,$light-blue,$green,$light-blue);
        }
      }
      //.group-teaser-attachments {
        .field-label {
          padding-bottom: 0;
        }
      //}
    }
  }
}

// Session Page
.node-session {
  &.view-mode-full {
    margin-top: 80px;
    padding: 30px !important;
    background: $white;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);

    .field-name-field-duration {
      margin-top: 60px;
    }

    .field-name-field-videos {
        .field-items {
          padding: 0 !important;  
        }
      }
      .field-name-field-downloads {
        .field-items {
          padding: 0 !important;  
        }
      }

    .field-name-field-session-slots {
      position: relative;
      //padding-left: 50px;
      border-top: 1px solid rgba($black,0.2);
      margin: 20px 0;

      &:after {
        content: "";
        height: 100%;
        width: 1px;
        background: #eaeaea;
        position: absolute;
        left: 20px;
        top: 0;
        
      }

      .field-name-field-start-time {
        font-family: "Averta-Bold";
        position: relative;
        z-index: 99;
        min-width: 120px;
      }

      //.slot-secondary-info,    
      .field-name-field-start-time {
        display: inline-block;
        vertical-align: top;
        padding: 10px 0;
      }
    }
  }
}

.node-type-speaker {
  .node-title {
    display: none;
  }
  .image-overlayed__image {
    img {
      margin-top: -180px;
      margin-bottom: 40px;
      transform: none;
    }
  }
}

.base-node-top-banner {
    background-image: url(/sites/all/themes/delfi/images/bg-purple-wide.jpg) !important;
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    //background-attachment: fixed;
    //background-position-y: -300px;
    position: relative;
    background-color: #523091;
}

.node-title {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  max-width: 1080px;
  text-align: left;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;

  h1 {
    color: $white;
    font-family: "Averta-Bold";
    font-size: 38px;
    line-height: 1.2;
    padding-bottom: 15px;
    text-align: left;
  }
}

.start-time,
.node-speakers {
  color: $white;
  font-size: $large;
  padding: 5px;
}

.front {
  .base-node-top-banner {
    height: 600px;
  }
}


.page-node.node-type-speech .main-content {
    padding-top: 0 !important;
}



.pre-footer {

  background: #eee;
  padding: 20px 0;

  .pre-footer-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 15px;
    padding-right: 15px;
    #block-views-sponsors-block-1 {
      margin: 60px 0;
    }
    .block__title {
      font-family: "Averta-Bold";
      font-size: $large;
    }
    .view-sponsors {
      .row-container {
        padding: 30px 0;
        text-align: left;
      }
      .views-row {
        margin: 5px 3px;
        background: $white;
        position: relative;
        @include shadow();
        &:nth-child(6n+1) {
          margin-left: 0;
        }
        &:nth-child(6n) {
          margin-right: 0;
        }
        .is-normal {
          .field-name-field-gold-sponsor {
            display: none;
          }
        }
        .is-gold {
          .field-name-field-gold-sponsor {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            right: 0;
            text-align: center;
            background: gold;
            color: #0549a2;
            padding: 6px 0;
            .field-label {
              float: none;
            }
          }  
        }        
      }
    }
  }
}


.footer-padding {
  padding: 0;
}

.group-speaker-main-info {
  text-align: center;
}





// Commerce Related Styling

.page-checkout,
.page-cart,
.section-cart {


  .accept_terms {
    label {
      //display: none;
    }
    .description {
      display: inline-block;
      margin-left: 5px;
    }
  }

  .label-mobile {
    display: none;
  }

  .main-content {
    max-width: 1150px;
    padding: 60px 0;
    padding: 60px 40px;
    margin: 0 auto;  
    .fieldset-wrapper,
    .view {
      @include shadow;
      padding: 0 30px;
      background: $white;
    }
  }
  //.view-commerce-cart-form {
  
    form {
      margin: 0 -30px;
      table {
        th {
          border-bottom: none;
          padding: 30px;
        }
        td {
          padding: 30px;
          background: $white;
          @media screen and (max-width: 750px) {
            padding: 15px 30px;
            &:before {
                width: 190px;
              text-align: left;
            }         
          }
        }
        tbody {
          .views-field-edit-delete {
            border-left: 1px solid rgba(0,0,0,0.2);
            text-align: center;
          }
          .views-field-commerce-total {
            text-align: center;
            background: #f6f6f6;
            border-left: 1px solid rgba(0,0,0,0.2);
          }
        }
        input[type='text'] {
          text-align: center;
          &:focus {
            outline: none;
            border: 1px solid rgba($black,0.5);
          }
        }
        input[type='submit'] {
          background: $red;
          color: $white;
          border: darken($red,0.2);
          padding: 5px 10px;
        }
      }
    }

    .views-field-commerce-total {
      text-align: center;        
    }
  //}

  .form-actions {
    clear: right;
    padding: 20px;
    #edit-checkout {
      @include button;
      margin: 0 10px;
      background: $lgreen;
      border: 1px solid $green;
      &:hover {
        background: lighten($lgreen,2%);
        cursor: pointer;
      }
    }
    #edit-submit {
      background: none;
      border: none;
      color: $light-blue;
      font-size: 15px;
      &:focus {
        outline: none;
      }
    }
  }

  .line-item-summary {
    float: right;
    max-width: 260px;
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }

  // checkout styling
  fieldset {
    margin: 30px 0;
  }
  select {
    border: 1px solid #aaa;
    color: $black; 
    border-radius: 0;
  }

}


.page-checkout {


  //we dont need this margin anymore
  form {
    margin: 0;
  }


  fieldset {
    margin: 30px 0;
    padding: 0;
    padding: 40px 0;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.2);
  }
  .fieldset-wrapper {
    padding: 30px;
  }
  .main-content {
    .fieldset-wrapper {
      padding: 0;
    }
    .view {
      padding: 0;
      box-shadow: none;
    }
  }
  .field-name-commerce-order-total {
    form {
      table {
        tbody {
          border-top: 0;
          tr {
            border: none;
          }
        }
      }
    }
  }

  #edit-customer-profile-billing {
    .outer-fieldset-wrapper {
      background: $white;
      @include shadow;
      padding: 60px 0;
      margin-top: 30px;
    }
    .fieldset-wrapper {
      box-shadow: none;
      margin: 0 auto;
      width: 100%;
      max-width: 600px;
      padding: 0 20px;
    }
  
    label {
    color: #231f20;
    font-family: "Averta-Regular";
    font-weight: 300;
    padding-bottom: 5px;
    }
    select,
    input {
      width: 100%;
      border: 1px solid #aaa;
      color: #231f20;
      border-radius: 0;
      height: 40px;
      padding: 8px 10px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:focus {
        outline: none;
      }
    }
    .addressfield-container-inline {
      &> .form-item {
        float: none;
        width: 100%;
        margin-right: 0;
      }
    }
  }
  .commerce-order-handler-area-order-total {
    .commerce-price-formatted-components {
      border-collapse: separate;
    }
  }
  .checkout-buttons {
    border: none;
    .fieldset-wrapper {
      background: none;
      box-shadow: none;
      text-align: center;
      #edit-continue {
        @include button;
        margin-right: 5px;
      }
    }
  }

  //payment method 
  #payment-details {
    padding: 30px;
    p {
      margin: 0;
    }
  }
  #edit-commerce-payment-payment-method {
    padding: 30px;
    margin: 0;
    margin-top: 30px;

    .form-item {
      margin: 10px 0;
    }
    .accepted-cards-payment {
      padding-left: 0;
    }
    li {
      display: inline-block;
      margin-right: 5px;
    }
    .description {
      margin-left: 0;
    }
  }


  //cart summary 
  .view-commerce-cart-summary {
    .views-field-commerce-total {
      background: $white;
    }
  }

}


.page-checkout-review {

  .ticket-profiles {
    padding: 20px 0;
  }

  #edit-commerce-payment {
    .fieldset-wrapper {
      padding: 30px;
      margin-top: 10px;
    }
  }

  form {
    table {
      th {
        background: $white;
      }
    }
  }
  table {
    &.checkout-review {
      tr {
        &.pane-title {
          border-bottom: 0;
          td {
            padding-bottom: 0;
          }
        }
        &:first-child {
          border-bottom: 1px solid rgba($black,0.2);
          td {
            padding-bottom: 30px;
          }
        }
        &:nth-child(2) {
          .pane-data-full {
            padding: 0;
          }  
        }
        &:not(:nth-child(2)) {
          .pane-data-full {
            label,
            .field-label {
              float: left;
              margin-right: 10px;
              font-weight: 300;
              text-decoration: underline;
            }
          }
        }
      }      
    }

    .field-name-commerce-customer-address {
      .field-item {
        div {
          float: left;
          margin-right: 10px;

          &:not(:last-child) {
            &:after {
              content: ",";
              display: inline-block;

            }  
          }
        }
      }
    }

  }
  .view-commerce-cart-summary {
    table {
      margin-top: 0;
    }
  }

  //fix labels etc


}
.page-checkout-accommodation,
.page-checkout-tickets {

  

  legend {
    font-weight: 600;
  }

  #edit-tickets {
    border: none;
    input {
    height: 35px;
    margin: 5px 0;
    padding: 5px 10px;
    line-height: 35px;
    &:focus {
      outline: none;
      border: 1px solid #1a1a1a;
    }
  }
  }

  .field-type-addressfield,
  .field-type-email,
  .field-name-field-customer-phone,
  .field-name-field-customer-profession {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
  }

  .locality-block {
    .form-item {
      width: 45%;
      margin: 0;
      &:first-child {
        margin-right: 10%;
      }
    }
  }
  input {
    width: 100%;
  }
  .street-block,
  .name-block {
     // display: inline-block;
     // vertical-align: middle;
    .form-item {
      width: 100%;
    }    
  }

  .main-content {
    .fieldset-wrapper {
      padding: 30px;
    }
    .fieldset-wrapper {
      background: none;
      box-shadow: none;
      padding: 0;
    }
    .customer_profile_accommodation,
    .esx-commerce-tickets--ticket {
      .fieldset-wrapper {
        background: $white;
        padding: 30px;
        @include shadow;
      }
    }

    .field-name-commerce-customer-address {
      fieldset {
        border: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
        .fieldset-wrapper {
          padding: 0;
          margin: 0;
          box-shadow: none;
        }
      }
    }

  }

  

  
  fieldset {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

}

#commerce-checkout-form-complete {
  padding: 30px;
  background: $white;

}
.page-checkout-complete {
  .main-content {
    .checkout-completion-message {
      text-align: center;  
      @include links($light-blue,$light-blue,$lgreen,$light-blue);
    }
  }
}

.page-user-orders {
  .field-name-field-order-balance-check ,
  .field-name-field-customer-ticket {
    padding: 30px;
  }
  .main-content {
    background-color: $n-gray;
    padding: 0;
    table {
        th {
          border-bottom: none;
          padding: 30px;
          text-align: left;
        }
        tr {
            border-bottom: 1px solid rgba(0,0,0,.2);
          }
        td {
          padding: 30px;
          background: $white;
        }
        tbody {
          .views-field-edit-delete {
            border-right: 1px solid rgba(0,0,0,0.2);
          }
          .views-field-commerce-total {
            text-align: center;
            background: #f6f6f6;
          }

        }
        input[type='text'] {
          text-align: center;
          &:focus {
            outline: none;
            border: 1px solid rgba($black,0.5);
          }
        }
        input[type='submit'] {
          background: $red;
          color: $white;
          border: darken($red,0.2);
          padding: 5px 10px;
        }
      }
  }

  .entity-commerce-order {
    @include shadow;
    background: $white;
    padding: 0 !important;

    .field-name-commerce-customer-billing {
      .field-label,
      .field-items {
        float: none;
        padding: 10px 0;
      }   
    }

    .field-name-commerce-order-total {
      .commerce-price-formatted-components {
        border-collapse: separate;
      }
    }

    .order-status-custom-field,
    .field-name-commerce-customer-billing {
      padding: 15px 30px;
      border-top: 1px solid rgba(0,0,0,.2);
    }

  }
}
.view-commerce-cart-summary {
  table {
    margin-bottom: 0;
  }
}
.commerce-order-handler-area-order-total {
  .commerce-price-formatted-components {
    width: 100%;
    padding-top: 0;
    margin-top: 0;
    text-align: left;
    background: #f6f6f6;
    
    .component-type-commerce-price-formatted-amount {
      background: #f6f6f6;
    }
    .component-title {
      //border-right: 1px solid rgba(0,0,0,0.2);
      //width: calc(100% - 371px);
      // width: percentage(781.8 / 1070);
      // width: 73.08542%;
      // width: calc(100% - 329px); //253px
      // float: left;
      background: #f6f6f6;
      display: inline-block;
    }
    .component-total {
      display: inline-block;
      // width: percentage(329 / 1070);
      // float: right;
      // text-align: center;
      background: #f6f6f6;
    }
  }  
}

.checkout-buttons {
  padding: 20px 0;
  .fieldset-wrapper {
    width: 100%;
    max-width: 230px;
    margin: 0 auto;
  }
  .checkout-back {
    padding: 12px 6px;
    float: none;
    display: block;
    margin: 0 auto;
    &:focus {
      outline: none;
    }
  }  
}

.node-ticket {
  background: $white;
  @include shadow;
  padding: 30px !important;
  .group-left {
    height: 380px;
    //background-size: 50% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 70% !important;
    background-color: #512f8e !important;
    margin: -30px;
    //background-color: $beige !important;
    max-width: 350px;
    max-width: 40%;
    width: 100%;
    margin-right: 60px;
    margin-right: 5%;
  }

  .commerce-product-field {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-top: 20px;

    .field-label {
      float: none;
      display: block;
    }
  }
  .group-right {
    position: relative;
    // float: none;
    margin: 30px;
    width: 50%;
    text-align: center;
    label {
      margin: 20px 0;
    }
    p {
      font-size: 15px;
      margin: 0;
    }
    input,
    select {
      //max-width: 35%;
      max-width: 55%;
    }
    // &:after {
    //   content: "";
    //   height: 300px;
    //   width: 1px;
    //   background: rgba(0,0,0,0.2);
    //   position: absolute;
    //   right: 155px;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }
  }
  .group-product-general-info {
    // position: absolute;
    // right: -120px;
    // right: -20%;
    // top: 25px;
    // width: 240px;
    &>div {
      span {
        //margin-bottom: 20px;
        font-weight: 600;
      }
    }
    .field-label {
      display: none;
    }
  }
  select,
  input {
    width: 100%;
    max-width: 315px;
    border: 1px solid #aaa;
    color: #231f20;
    border-radius: 0;
    height: 40px;
    padding: 8px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus {
      outline: none;
    }
  }
    .form-submit {
    // position: absolute;
    // right: 0;
    // right: -120px;
    // right: -16%;
    // bottom: 24px;
    width: 315px;
    transition: 0.3s;
    &:hover {
      background: $light-blue;
      border: 1px solid $dark-blue;
      color: $white;
    }
  }
}




//user pages 
.page-user,
.page-user-login,
.page-user-register,
.page-user-password {

  .form-type-password {
    width: 100%;
  }

  .main-content {
    max-width: 1080px;
    padding: 60px 0;
    padding: 60px 0px;
    margin: 0 auto; 
    background-color: #f2f2f2;

    .profile {
      @include shadow;
      padding: 30px;
      background: $white;   
      @include links($light-blue,$light-blue,$green,$light-blue);
      h3 {
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
    form {
      @include shadow;
      padding: 30px;
      background: $white;   
      input[type='text'],
      input[type='password'] {
        height: 45px;
        margin: 5px 0;
        padding: 5px 10px;
        &:focus {
          outline: none;
          border: 1px solid #1a1a1a;
        }
      }
      h1 {
        font-weight: 600;
        padding-bottom: 10px;
      }
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }  
  }
  .create-account {
    float: right;
    width: 50%;
    text-align: center;
    h2 {
      font-weight: 600;
      padding-bottom: 10px;
    } 
    @include links($light-blue,$light-blue,$green,$light-blue);
  }
  #user-register-form,
  #user-pass {
    @include links($light-blue,$light-blue,$green,$light-blue);
    .form-submit {
      @include button;
      margin: 20px 0;
      padding: 8px 35px;
    }   
  }
  #user_login_form {
    float: left;
    width: 50%;
    padding-right: 30px;
    border-right: 1px solid rgba($black, 0.2);
    margin: -30px;
    padding: 30px;
    background: #f6f6f6;

    @include links($light-blue,$light-blue,$green,$light-blue);
    .form-submit {
      width: 100%;
      @include button;
      margin: 20px 0;
      padding: 8px 35px;
    }      
  }
}


.read-more {
  //padding: 4px 12px;
  display: inline-block;
  //border: 1px solid #1a1a1a;
  transition: .3s;
  font-size: 15px;
  vertical-align: middle;
  @include links($black,$black,$light-blue,$black);
  margin-top: 20px;

}

//program videos and icons
.group-teaser-attachments {
  display: block;
  width: 100%;
  width: calc(100% - 140px);
}
.video-link,
.teaser-video {
  display: block;
  margin-bottom: 10px;
  // .field-items {
  //   padding: 20px 0;
  // }
  &.session {
    margin-bottom: 0;
    margin-top: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  a {
    text-indent: -99999px;
    display: inline-block;
    width: 35px;
    height: 35px;
    background: url(../images/icons/youtube.svg);
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 8px;
    @include shadow;
  }
}

.session-downloads,
.speech-downloads {
  display: inline-block;
  vertical-align: middle;
  img {
    display: none;
  }

  &.full {
    a{
      padding: 30px;  
    }    
  }
  // .field-items {
  //   padding: 20px 0;
  // }
  .field-item {
    display: inline-block;
  }
  a {
    width: 40px;
    height: 40px;   
    display: block;
    text-indent: -99999px;
    margin-right: 10px;
    margin-top: 10px;
    transition: .4s;
    &:hover {
      cursor: pointer;
      //transform: scale(1.1);
    }
  }
  a[type*="application/pdf"] {background: url(../images/icons/pdf.svg) no-repeat; }
  a[type*="application/ppt"] {background: url(../images/icons/ppt.svg) no-repeat; }
  a[type*="application/pptx"] {background: url(../images/icons/ppt.svg) no-repeat; }
  a[type*='application/vnd.openxmlformats-officedocument.wordprocessingml.document'] {background: url(../images/icons/doc.svg) no-repeat; }
  a[type*='application/msword'] {background: url(../images/icons/doc.svg) no-repeat; }
  a[type*='application/vnd.ms-excel'] {background: url(../images/icons/xls.svg) no-repeat; }
}

.session-downloads {
  margin-top: 20px;
  margin-left: 20px;
}

.main-navigation {
  .menu {
    max-width: 1080px;
    margin: 0 auto;
  }
}


.page-user-orders {
  .main-content {
    .view-commerce-user-orders {
      background: $white;
      padding: 30px;

      table {
        @include links($black,$black,$light-blue,$black);
        th {
          padding-top: 0;          
        }
      }
    }
  }
}


.node-special-page {
  background: $white;
  padding: 30px 60px !important;
  @include shadow;
  @include links($light-blue,$light-blue,$green,$light-blue);

  .field-name-field-account-image {
    display: inline-block;
    width: 70px;
    margin-left: 20px;
  }

  .student,
  .speaker {
    max-width: 340px;
    max-width: 55%;
    width: 100%;
    display: inline-block;
    padding-left: 40px;
  }
  .score {
    font-weight: 600;
  }

  .esx-top-speakers {
    li {
      padding: 15px 0;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      position: relative;
    }

    .top-speakers-image {
      .image-overlayed__image {
        
        display: inline-block;
        margin-left: 15px;
        img {
          border: none;
          border-radius: 0;
          width: 70px;
          transform: none;
        }
      }
    }
  }
  .esx-top-students {
    li {
    padding: 15px 0;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    position: relative;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      &:after {
        content: "";
        background: url(https://www.andrologyupdate.gr/sites/all/themes/delfi/images/icons/trophy.svg);
        width: 45px;
        height: 45px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 20px;
        bottom: 0;
      }
    }
  }  
  }
  
}

.page-node {
  &.node-type-session {
    .main-content {
      padding-top: 0 !important;
    }
  }
}

.sidr {
  background: $black;
  .sidr-inner {
    padding-bottom: 0;
  }
  ul {
    padding: 10px 0px;
  }
  .sidr-class-block__title,
  .sidr-class-contextual-links {
    display: none;
  }
  ul {
    border-bottom: 1px solid rgba($white,0.2);
  }
}


.page-node-1712 {
  .form {
    .form-item {
      width: 100%;
      text-align: center;
    }
  }
}


.page-user {

  .messages-status {
    position: relative;
    z-index: 99999999;
  }

  .node-title {
    display: none;
  }
  .profile {
    .field-name-field-account-bio {
      .field-label {
        padding: 20px 0;
      }
    }
    .field-name-field-account-lastname,
    .field-name-field-account-firstname {
      display: inline-block;
      margin-right: 5px;
    }
    .field-name-field-account-image {
      img {
        margin-top: -180px;
        margin-bottom: 40px;
        border: 7px solid #f2f2f2;
        position: relative;
      }
    }
    dl,
    h3 {
      display: none;
    }
  }  
}



// .views-field-field-image-files img.file-icon {display:none;}
// .views-field-field-image-files a {padding-left: 100px; height: 80px; display: block; margin-bottom: 20px;}
// .views-field-field-image-files 
// .views-field-field-image-files a[type*="application/zip"] {background: url(../img/icons/file-icon-zip.gif) no-repeat; }
// .views-field-field-image-files a[type*="application/ppt"] {background: url(../img/icons/file-icon-ppt.gif) no-repeat; }
// .views-field-field-image-files a[type*="application/pptx"] {background: url(../img/icons/file-icon-ppt.gif) no-repeat; }

// .messages--error {
//   display: none;
// }
.webform-client-form-1712 {
  label {
    line-height: 30px;
  }
}

#webform-notification {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}

.eval-link {
  font-size: 14px;
  margin-left: 5px;
}


.node-speech {
  &.node-teaser {
    .field-name-field-speaker {
      margin-bottom: 20px;  
    }    
  }
}




// Fixes 
body[class*=" page-user-orders-"] {
  .field-name-commerce-order-total {
    .commerce-price-formatted-components {
      margin-left: 0;
    }
  }
  .field-type-addressfield {
    .field-item {
      div {
        display: inline;
      }
    }
  }
  .field-name-field-related-users  {
    padding: 0 30px 30px 30px;
  }
}

.form {
  .form-required {
    color: red;
  }
}




.i18n-en {

  .imop-logo {
    top: 47px;
  }
  .node-speaker {
    &.view-mode-full {
      .image-overlayed__text {
        &.footer-padding {
         // display: none;
        }
      }  
    }  
  }
}



.footer__wrapper {
  //credit cards bottom
  .accepted-cards-payment {
    padding-left: 0;
    li {
      display: inline-block;
    }
  }
}




.page-node-2 {
  .node-webform {
    .form__text {
      max-width: 100%;
    }
  }
}
.page-contact {
  .form {
    .form_text {
      max-width: 100%;
    }
  }
}


// Extras
.imop-logo {
  display: inline-block;
  position: relative;
  z-index: 99;
  max-width: 90px;
  top: 27px;
}

.header__logo {
  display: inline-block;
  max-width: 150px;
  padding-top: 63px;
}

.main-navigation {
  .menu {
    li {
      padding: 0 8px 0 0;
    }
  }
}


.page-agenda .node-session.no-title-link .group-toggle-area.open {
  background: $white;
}

.sun-pr {
  &:hover {
    cursor: pointer;
  }
}
.sun-pr-container {
  text-align: center;
  background: $white;
  padding: 30px;
  margin: 80px 0;
}

.page-sponsors {
  .view-sponsors {
    .views-row {
      position: relative;
      a {
        position: absolute;
        text-indent: -99999999px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}


.pane-page-messages {
      margin: 0 auto;
    max-width: 1090px;
    background: none;
}


#backtotop {
  background: none !important;
  &:after {
    background: url(../images/totop.png);
    background-size: contain;
  }
}


.form-type-password-confirm {
  overflow: scroll;
}


#user-register-form {
  .field-name-field-account-mobile {
    input {
      padding-left: 60px;
      max-width: 300px;
      width: 100%;
    }
  }
}


#block-views-food-ticket-block {
  padding: 0 !important;
  margin-top: 20px;
}

.node-ticket {
  .node-food-ticket {
    .commerce-product-field {
      width: 50%;
    }
    .group-left {
      height: 495px;
      background: url(../images/coffee.jpg) !important;
      background-size: cover !important;
      background-color: none;
    }
  }
}

@import "components/_cookies.scss";
@import "responsive/_general.scss";


.node-uro-olympics {
    padding: 30px !important;
    background: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
    position: relative;
}


.node-type-speaker {
  .panel-panel {
    .contextual-links-region {
      display: none;
    }
  }
}


// HOTELS RELATED STYLING
.view-esx-hotel-products-table {
  border: 1px solid rgba(0,0,0,0.15);
  padding: 10px;
  margin-top: 20px;
  margin: 0 -30px;
  margin-top: 20px;
  background: #fafafa;
  border: none;
  padding: 60px 30px;
  margin-bottom: -30px;
  .views-table {
    margin: 0;
  }
  thead {
    text-align: left;
  }
  th {
    border-bottom: 1px solid rgba(0,0,0,0.15);
    padding-bottom: 20px;
    font-family: "Averta-Bold";
  }
  td {
    padding-top: 20px; 
    max-width: 250px;
    padding-right: 20px;

    @media screen and (max-width: 750px) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  #edit-line-item-fields {
    display: inline-block;
  }

}
.field-name-field-hotel-image {
  img {
    width: 100%;
  }
}



.node-type-hotel {
  .pane__title {
    display: none;
  }
  .panel-display {
    padding: 30px !important;
    background: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
    position: relative;
  }
  .field-label {
    font-family: "Averta-Bold";
  }
  .thoroughfare {
    float: left;
    margin-right: 8px;
    &:after {
      content: ":";
      display: inline-block;
    }
  }
  .field-name-field-hotel-facilities {
    ul {
      padding-left: 0;
    }
    li {
      list-style-type: none;
      display: inline-block;
      width: 33%;
      text-align: left;
      border-bottom: 1px solid rgba(0,0,0,0.15);
      padding: 12px;
      &:before {
        content: "✔";
        color: green;
        padding-right: 10px;
      }
    }
  }

  input[type="submit"] {
    border: 1px solid #aaa;
    color: #231f20;
    border-radius: 0;
    height: 40px;
    padding: 8px 10px;
    -webkit-appearance: none;
    width: auto;
    margin: 0 auto;
    display: block;
    font-size: 15px;
    float: right;

    &:hover {
      cursor: pointer;
      background: #0f75bc;
      border: 1px solid #004176;
      color: #fff;
    }
  }

  .in-stock {
    .form-wrapper {
      display: inline-block;
      // text-align: center;
      // width: 70%;
      @media screen and (max-width: 750px) {
        display: block;
      }
    }
  }

  input[disabled="disabled"] {
    background: $white;
    border: 1px solid #efefef;
    color: #d2d2d2;
    &:hover {
      cursor: default;
      background: $white;
      border: 1px solid #efefef;
      color: #d2d2d2;
    }
  }


    @media screen and (max-width: 750px) {
      table.bt tfoot th::before, table.bt tfoot td::before, table.bt tbody td::before {
        width: 190px;
      }
    }

}
.field-name-field-accommodation-days {
  label {
    display: inline-block;
    vertical-align: middle;
    display: none;
  }
}

.view-facilities-hotels {
  display: table;
  clear: both;
  width: 100%;

  @media screen and (max-width: 550px) {
    .view-content {
      text-align: center;
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }    
  }

  .l-two-column--column {
    width: percentage(354 / 1100);
    width: 32%;
    margin-right: 22px;
    margin-bottom: 30px;
    background: white;

    &:nth-child(3n) {
      margin-right: 0;
    }
    @media screen and (max-width: 550px) { 
      width: 70% !important;
      margin-right: 0 !important;
      display: inline-block;
      float: none;
    }

    @media screen and (max-width: 350px) { 
      width: 90% !important;
      
    }

    @media screen and (max-width: 1100px) {
     width: 45%;
     margin-bottom: 70px;
     &:nth-child(2n){
      margin-right: 0;
     }
     &:nth-child(3n){
      margin-right: 22px;
      clear: none;
     }
     &:nth-child(2n+1){
      //margin-right: 22px;
      clear: left;
     }
    }
  }

  


  .field-name-field-hotel-image {
    margin: 0 -15px;
    margin-top: -17px;
    margin-bottom: 20px;
  }

  .links {
    margin: 0 -15px;
    margin-bottom: -17px;
    padding: 15px;
    background: #fafafa;
    @include links(#0f75bc,#0f75bc,#0f75bc,#0f75bc);
    font-size: 15px;
  }

  .field-name-body {
    min-height: 120px;
  }


}

.hotel-title {
  font-size: 14px;
  color: grey;
}


.section-speakers {
  .messages--error {
    display: none;
  }
}

.section-hotels {
  .view-header {
        padding: 30px;
      background: white;
      margin: -15px;
      margin-bottom: 50px;
  }
}

.urolympics-action {
  text-align: center;
  margin-bottom: 0;
  a {
    
    background: #1ea03a;
    color: white;
    display: inline-block;
    margin: 0 auto;
    padding: 12px 30px;
    border-radius: 20px;
  }
}


.group-hotel-image-wrapper {
  position: relative;
  .field-name-field-hotel-website {
      position: absolute;
      bottom: 7px;
      background: linear-gradient(to left,#2d0b6b,transparent);
      left: 0;
      right: 0;
      padding: 8px;
      text-align: right;
      a {
        color: white;
      }
  }
}


.node-type-speaker {
  .panel-display {
    .pane-node {
      text-indent: -99999px;
      &.main-bg {
        text-indent: 0;
      }
    }
  }
}

.component-type-commerce-price-formatted-amount,
.component-type-base-price,
.component-type-nights {
  background: #f6f6f6 !important;
  .component-total,
  .component-title {
    width: 50%;
    border-bottom: 1px solid rgba(0,0,0,0.15);
  }
  .component-total {
    text-align: right;
    padding-right: 67px;
  }
}





.view-commerce-user-orders {
  td {
    a {
      padding: 10px 20px;
      border-radius: 20px;
      background: #0f75bc;
      border: 1px solid #004176;
      color: #fff !important;
    }  
  }  
}


.olympics {
  .l-two-column-thirds__left {
    height: 285px;
  }
}




.i18n-en {
  //.sun-pr-container,
  .link-button--dl {
    display: none;
  }
}


.day-button {
  &:last-child {
    display: none;
  }
}

a[href="#node-1876"]{
  display: none;
}


// temp fix
.not-logged-in {
  #node-1876 {
    display: none;
  }
}



// .admin-menu {
//   #quiz {
//     display: block !important;

//   }
// }
// #quiz {
//   display: none !important;
// }

.eval-results {
  margin-top: 40px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

#block-system-main-menu {
  .leaf {
    &.last {
      a {
        color: red !important;
      }
    }
  }
}

